import React, { useRef, useMemo, useState, useEffect } from "react";
import "./style.scss";
import { Button, Tabs } from "antd";
import DetailBlock from "../../../../components/commonBlock";
import { Editor } from "@tinymce/tinymce-react";
import { API_LINK, TOKEN } from "../../../../utils/constants";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../components/modal/modalSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteRecruitmentInformationMutation,
  useGetRecruitmentInformationDetailQuery,
} from "../../recruitmentInfoApi";
import { kitToastMessage } from "../../../../components/toastMessage";
import "./style.scss";

function RCDetail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const editorRef = useRef<any>(null);
  const [name, setName] = useState("John");
  const [balance, setBalance] = useState("500");
  const dispatch = useDispatch();
  // const textTemplate: any = `
  //                             <p class="title">
  //                             서울시 강북구 강남로 24길서울시 강북구 강남로 24길서울시 강북구 강남로 24길서울시 강북구 강남로
  //                             24길서울시 강북구 강남로 24길서울시 강북구 강남로 2
  //                         </p>
  //                         <p>OOO는 2012년에 설립되었으며, 사원수 00명 규모의 중소기업입니다.
  //                             어디에 위치해있으며, 어떤 서비스사업을 하고있습니다.
  //                         </p>
  //                         <p>포지션 및 자격요건</p>
  //                         <table style="border-collapse: collapse; width: 100%;" border="1">
  //                             <colgroup>
  //                               <col style="width: 50%;">
  //                               <col style="width: 50%;">
  //                             </colgroup>
  //                             <tbody>
  //                               <tr>
  //                                   <td>PD•방송연출   ${name}  ${balance} </td>
  //                                   <td>
  //                                     <p>담당업무</p>
  //                                     <p>자격요건</p>
  //                                     내용을 입력해주세요
  //                                     <p>우대조건</p>
  //                                     내용을 입력해주세요
  //                                     내용을 입력해주세요

  //                                   </td>
  //                               </tr>
  //                               <tr>
  //                                   <td>FD•연출보조</td>
  //                                   <td>
  //                                     <p>담당업무</p>
  //                                     내용을 입력해주세요
  //                                     <p>담당업무</p>
  //                                     내용을 입력해주세요
  //                                     <p>우대조건</p>
  //                                     내용을 입력해주세요
  //                                     <p>근무지</p>
  //                                     내용을 입력해주세요
  //                                   </td>
  //                               </tr>
  //                             </tbody>
  //                         </table>
  //                         <h2 class="h2--bubble">지원자격</h2>
  //                         <ul>
  //                            <li>키워드, 키워드, 키워드</li>
  //                         </ul>
  //                         <h2 class="h2--bubble">제출서류</h2>
  //                         <ul>
  //                         <li>키워드, 키워드, 키워드</li>
  //                      </ul>
  //                         <h2 class="h2--bubble">근무조건</h2>
  //                         <ul>
  //                         <li>키워드, 키워드, 키워드</li>
  //                      </ul>
  //                         <h2 class="h2--bubble">급여</h2>
  //                         <ul>
  //                         <li>키워드, 키워드, 키워드</li>
  //                      </ul>
  //                         <h2 class="h2--bubble">전형절차</h2>
  //                         <ul>
  //                         <li>키워드, 키워드, 키워드</li>
  //                      </ul>
  //                         <h2 class="h2--bubble"> 접수 기간 및 방법</h2>
  //                         <ul>
  //                         <li>키워드, 키워드, 키워드</li>
  //                      </ul>
  //                         <h2 class="h2--bubble">문의처</h2>
  //                         <ul>
  //                         <li>키워드, 키워드, 키워드</li>
  //                      </ul>
  //                         <h2 class="h2--bubble">유의사항</h2>
  //   `;

  // const handleEditorChange = () => {
  //   editorRef?.current?.setContent(textTemplate);
  // };

  // useEffect(() => {
  //   handleEditorChange();
  // }, [name, balance]);
  const { data, isSuccess, refetch } = useGetRecruitmentInformationDetailQuery(
    {
      innovationId: searchParams.get("id") as any,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const block1 = useMemo(() => {
    if (isSuccess) {
      return [
        {
          title: "기업명",
          desc: data?.data?.innovationPlatform?.companyName,
        },
      ];
    }
    return [];
  }, [data?.data, isSuccess]);
  const block2 = useMemo(() => {
    if (isSuccess) {
      const {
        jobTitle,
        participantProgram,
        preferredAbility,
        placeOfWork,
        keyword,
      } = data?.data;
      return [
        {
          title: "제목",
          desc: jobTitle,
        },
        {
          title: "참여프로그램",
          desc: participantProgram,
        },
        {
          title: "우대능력",
          desc: preferredAbility,
        },
        {
          title: "근무지",
          desc: placeOfWork,
        },
        {
          title: "키워드",
          desc: keyword,
        },
      ];
    }
    return [];
  }, [data?.data, isSuccess]);
  const block3 = useMemo(() => {
    if (isSuccess) {
      const {
        education,
        programCareer,
        typeOfEmployment,
        benefit,
        otherBenefit,
        salary,
      } = data?.data;

      return [
        {
          title: "학력",
          desc: education,
        },
        {
          title: "경력",
          desc: programCareer,
        },
        {
          title: "고용형태",
          desc: typeOfEmployment,
        },
        {
          title: "복리후생",
          desc: benefit?.concat(otherBenefit && `|${otherBenefit}`),
        },
        {
          title: "급여",
          desc: salary,
        },
      ];
    }
    return [];
  }, [data?.data, isSuccess]);

  const uploadImageHandler = (file: any, progress: any) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("file", file.blob());
        const res = await fetch(API_LINK + "files", {
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
          },
          body: formData,
        })
          .then((res: any) => res.json())
          .catch((error) => error.json());
        if (res.statusCode > 299) {
          return reject(res);
        }
        return resolve(res?.data?.files[0]?.url);
      } catch (error) {
        return reject("Something went wrong!");
      }
    });

  const recruitmentDetail = [
    {
      title: "디자인",
      detail: [
        {
          title: "담당업무",
          content: ["웹/앱서비스 디자인", "서비스 프로모션 디자인"],
        },
        {
          title: "자격요건",
          content: ["디자인 툴 사용가능자", "서비스 디자인 가능자"],
        },
        {
          title: "우대조건",
          content: ["디자인 관련학과 전공자"],
        },
        {
          title: "모집인원",
          content: ["n명"],
        },
        {
          title: "근무지",
          content: ["서울"],
        },
      ],
    },
    {
      title: "그래픽 디자인",
      detail: [
        {
          title: "담당업무",
          content: [
            "그래픽 아트웍 제작",
            "서비스 컨셉과 브랜딩에 대한 이해를 기반으로 한 비주얼 제작",
          ],
        },
        {
          title: "자격요건",
          content: [
            "그래픽 디자인 관련 경험자 ",
            "그래픽 디자인 툴 사용 가능자",
          ],
        },
        {
          title: "우대조건",
          content: ["디자인 관련학과 전공자"],
        },
        {
          title: "모집인원",
          content: ["n명"],
        },
        {
          title: "근무지",
          content: ["서울"],
        },
      ],
    },
  ];
  return (
    <div className="rc-detail">
      <div className="rc-detail__body">
        <div className="content-wrapper">
          <div className="block-one">
            <DetailBlock
              className={{ width: "50%" }}
              title="기업정보"
              content={block1}
            />
            <div style={{ width: "50%" }}></div>
          </div>

          <div className="block-two">
            <DetailBlock title="채용공고" content={block2} />
            <DetailBlock title="" content={block3} />
          </div>
          <div className="block-three">
            <div className="title">상세 내용</div>
            <div className="separate" />
            <div className="block-three-desc">
              {/* <div className="block-three-desc-content">
             <div className="title">
               <span>{data?.data?.jobTitle}</span>
             </div>
             <p className="note">
               OOO는 2012년에 설립되었으며, 사원수 00명 규모의
               중소기업입니다. 어디에 위치해있으며, 어떤 서비스사업을
               하고있습니다.
             </p>
             <h2 className="h2--bubble">지원자격</h2>
             {recruitmentDetail.map((item) => {
               return (
                 <div className="content-block__one">
                   <div className="content-block__one-left">
                     {item?.title}
                   </div>
                   <div className="content-block__one-right">
                     {item?.detail?.map((child) => {
                       return (
                         <div className="block__one-item">
                           <span className="block__one-item__title">
                             {child.title}
                           </span>
                           <ul className="block__one-item-list">
                             {child.content?.map((subChild) => (
                               <li>{subChild}</li>
                             ))}
                           </ul>
                         </div>
                       );
                     })}
                   </div>
                 </div>
               );
             })}
             <div className="detail-wrapper">
               <div className="content-block-detail">
                 <h2 className="h2--bubble">지원자격</h2>
                 <ul className="detail-list">
                   <li>합격 즉시 풀타임 근무 가능자</li>
                 </ul>
               </div>
               <div className="content-block-detail">
                 <h2 className="h2--bubble">지원자격</h2>
                 <ul className="detail-list">
                   <li>이력서, 자기소개서</li>
                 </ul>
               </div>
               <div className="content-block-detail">
                 <h2 className="h2--bubble">근무조건</h2>
                 <ul className="detail-list">
                   <li>주5일 9-6시 근무</li>
                 </ul>
               </div>
               <div className="content-block-detail">
                 <h2 className="h2--bubble">급여</h2>
                 <ul className="detail-list">
                   <li>인턴 지원금 별도 안내</li>
                 </ul>
               </div>
               <div className="content-block-detail">
                 <h2 className="h2--bubble">전형 절차</h2>
                 <ul className="detail-list">
                   <li>DMC 500캠프 매칭 절차에 따름</li>
                 </ul>
               </div>
               <div className="content-block-detail">
                 <h2 className="h2--bubble">접수 기간 및 방법</h2>
                 <ul className="detail-list">
                   <li>DMC 500캠프 매칭 절차에 따름</li>
                 </ul>
               </div>
               <div className="content-block-detail">
                 <h2 className="h2--bubble">문의처</h2>
                 <ul className="detail-list">
                   <li>korea@gmail.com</li>
                 </ul>
               </div>
               <div className="content-block-detail">
                 <h2 className="h2--bubble">유의사항</h2>
                 <ul className="detail-list">
                   <li>허위사실 발각시 채용이 취소 될 수 있습니다</li>
                 </ul>
               </div>
             </div>
           </div> */}

              <div
                className="desc--set-html"
                dangerouslySetInnerHTML={{ __html: data?.data?.businessForm }}
              ></div>

              {/* <Editor
             scriptLoading={{
               async: true,
             }}
             onInit={(evt, editor) => (editorRef.current = editor)}
             apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
             plugins="advlist autolink lists link image charmap preview
                         searchreplace visualblocks code fullscreen 
                            paste code help table"
             init={{
               height: 500,
               menubar: false,
               branding: false,
               statusbar: false,
               content_style: `
               body {padding: 10px 50px} 
               .title{color: red}
               .h2--bubble{
                 position: relative
               }
               .h2--bubble::before {
                 position: absolute;
                 content: "";
                 width: 24px;
                 height: 24px;
                 border-radius: 50%;
               }
               `,
               toolbar:
                 "formatselect|bold italic backcolor forecolor|fontfamily fontsize alignleft aligncenter alignright alignjustify image|\
                 bullist numlist outdent indent table tablecellbackgroundcolor  tablecellbordercolor",
               images_upload_handler: uploadImageHandler,
               init_instance_callback: function (editor) {
                 editor.setContent(textTemplate);
               },
               language: "ko_KR",
             }}
           /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RCDetail;
