import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import KitButtonCustom from "../../../../components/KitButtonCustom";
import { IBasicFilter } from "../../@type";
import { PAGE_SIZE } from "../../../../utils/constants";
import { Button, Input, Modal } from "antd";
import KItTableCustom from "../../../../components/KitTableCustom";
import { ColumnsType } from "antd/lib/table";
import { useGetApplyListQuery } from "../../recruitmentInfoApi";
import { Divider } from "rc-menu";
import IconLocationOutlined from "../IconLocationOutlined";
import moment from "moment";
import CVDetail from "../../../TalentInfo/components/CV";

interface IAppReceivedProps {
  onCallback: (value: number) => void;
}

function CvPass({ onCallback }: IAppReceivedProps) {
  const initialFilter = {
    page: 1,
    order: "ASC",
    limit: PAGE_SIZE,
    sortColumn: "cvPassedAt",
    status: ["CV_PASSED"],
  };
  const submitBtnRef = useRef<any>(null);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<IBasicFilter>(initialFilter);
  const [isCVVisible, setIsCVVisible] = useState(false);
  const [selectedCvID, setselectedCvID] = useState(undefined);

  const onTableChange = (t, v) => {
    if (t === "click-row") {
      setselectedCvID(v?.id);
      setIsCVVisible(!isCVVisible);
    }
  };
  useEffect(() => {
    if (search.length === 0) {
      setFilter(initialFilter);
    }
  }, [search]);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      submitBtnRef?.current?.click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const columns: ColumnsType<any> | any = [
    {
      title: "이름",
      dataIndex: "name",
      key: "이름",
      render: (item: any, record: any) => {
        return (
          <div className="col-first">
            <span className="col-first-title">
              {record?.cvPdf?.originalName}
            </span>
            <span className="col-first-subtitle">
              {record?.applyUserInfo?.experienceYear}년{" "}
              {record?.applyUserInfo?.experienceMonth}개월
            </span>
          </div>
        );
      },
    },
    {
      title: "이력서 정보",
      dataIndex: "name",
      key: "이력서 정보",
      render: (item: any, record: any) => {
        return (
          <div className="col-second">
            <span className="col-second-title">
              열정 가득한 지원자 박길동입니다!{" "}
            </span>
            <span className="col-second-subtitle">
              엑셀-중급 함수 사용 가능, 데이터 분류 및 정리 가능
            </span>
            <div className="col-second-content">
              <span>
                {record?.applyUserInfo?.highestEducationLevel ===
                "HIGH_SCHOOL_GRADUATION"
                  ? "고등학교"
                  : record?.applyUserInfo?.highestEducationLevel ===
                    "UNIVERSITY_GRADUATED_2/3_YEARS"
                  ? "대학교 2/3년"
                  : record?.applyUserInfo?.highestEducationLevel ===
                    "UNIVERSITY_GRADUATED_4_YEARS"
                  ? "대학교 4년"
                  : record?.applyUserInfo?.highestEducationLevel ===
                    "NOT_APPLICABLE"
                  ? "해당없음"
                  : ""}
              </span>
              {" | "}
              <div className="col-second-location">
                <IconLocationOutlined />{" "}
                {record?.applyUserInfo?.desiredWorkingPlace}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "지원일/상태",
      dataIndex: "name",
      key: "",
      render: (item: any, record: any) => {
        return (
          <div className="col-third">
            <span className="col-third-time">
              {moment(record?.applyUserInfo?.applyCvDate)?.format("YYYY-MM-DD")}
            </span>
            <span className="col-third-btn">
              {record?.status === "APPLYING"
                ? "지원서 접수"
                : record?.status === "CV_PASSED"
                ? "서류 통과"
                : record?.status === "INTERVIEW_PASSED"
                ? "최종 합격"
                : record?.status === "INTERVIEW_FAILED"
                ? "불합격"
                : ""}
            </span>
          </div>
        );
      },
    },
  ];

  const { data, isSuccess, isLoading } = useGetApplyListQuery(
    { ...filter },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (data && !isLoading) {
      onCallback(data?.data?.metaData?.totalRecords);
    }
  }, [data, isLoading]);
  return (
    <div className="ar-wrapper">
      <div className="ar">
        <div className="ar-header">
          <div className="ar-header__sort">
            {[{}]?.length > 0 && (
              <>
                <KitButtonCustom
                  type="check"
                  btnType={filter.order === "ASC" ? "active" : "disabled"}
                  content="최신순"
                  onClick={(e) => {
                    setFilter({ ...filter, order: "ASC" });
                  }}
                />
                <KitButtonCustom
                  type="check"
                  btnType={filter.order === "DESC" ? "active" : "disabled"}
                  content="과거순"
                  onClick={() => setFilter({ ...filter, order: "DESC" })}
                />
              </>
            )}
          </div>
          <div className="ar-header__search">
            <Input
              className="ar-input"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="지원자 이름으로 검색"
            />
            <Button
              ref={submitBtnRef}
              className="ar-btn___search--submit"
              onClick={() => setFilter({ ...filter, keyword: search })}
            >
              검색
            </Button>
          </div>
        </div>
        {!isLoading && filter?.keyword && filter?.keyword?.length > 0 && (
          <div className="result-area">
            <div className="result-area__title">
              ‘{filter?.keyword}’&nbsp;
              <div className="result-area__sub-title">
                로 검색한 결과입니다.
              </div>
            </div>
            <div className="result-area__counter">
              총 {data?.data?.metaData?.totalRecords}건
            </div>
          </div>
        )}
        <div className="ar-body">
          <KItTableCustom
            columns={columns}
            dataSource={data?.data?.result}
            onTableChange={onTableChange}
          />
        </div>
      </div>
      {isCVVisible && (
        <Modal
          onCancel={() => setIsCVVisible(false)}
          title=""
          width={1200}
          wrapClassName="modal-accept-offer-wrapper"
          open={isCVVisible}
          footer={null}
        >
          <CVDetail cvID={selectedCvID} isFromProps={true} />
        </Modal>
      )}
    </div>
  );
}

export default CvPass;
