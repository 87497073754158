/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const residentCompanyApi = createApi({
  reducerPath: "residentCompany",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    },
  }),
  tagTypes: ["getResidentCompanyList", "getResidentCompanyDetail"],
  endpoints: (builder) => ({
    getIndustry: builder.mutation({
      query: (data) => ({
        url: `innovation/industryClassification`,
        method: "GET",
        params: data?.params,
      }),
    }),
    getResidentCompanyList: builder.query({
      query: (params) => ({
        url: `admins/v3/platform`,
        method: "GET",
        params: params,
      }),
      providesTags: ["getResidentCompanyList"],
    }),
    getResidentCompanyDetail: builder.query({
      query: (data) => ({
        url: `innovation/v3/platform/${data.id}`,
        method: "GET",
      }),
      providesTags: ["getResidentCompanyDetail"],
    }),
    createResidentCompany: builder.mutation({
      query: (data) => ({
        url: `innovation/v3/platform`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getResidentCompanyList"],
    }),
    updateResidentCompany: builder.mutation({
      query: (data) => ({
        url: `innovation/v3/platform/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["getResidentCompanyList"],
    }),
    deleteResidentCompany: builder.mutation({
      query: (data) => ({
        url: `innovation/v3/platform/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getResidentCompanyList"],
    }),
    getCompanyInnoByBusinessNum: builder.mutation({
      query: (data) => {
        return {
          url: `innovation/check`,
          method: "GET",
          params: data,
        };
      },
    }),
  }),
});

export const {
  useGetIndustryMutation,
  useCreateResidentCompanyMutation,
  useGetResidentCompanyDetailQuery,
  useGetResidentCompanyListQuery,
  useUpdateResidentCompanyMutation,
  useGetCompanyInnoByBusinessNumMutation,
  useDeleteResidentCompanyMutation,
} = residentCompanyApi;
