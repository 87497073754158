import { highlightKeywords } from "./commonFunc";

export const generateCustomLable = (title: string, subTitle: string) => {
  return (
    <span className="custom-label">
      {title} <span className="custom-label--child"> {subTitle}</span>
    </span>
  );
};

export const truncateText = (maxWidth: any, data: any) => {
  return (
    <div
      className="text-overflow--ellipsis"
      style={{ maxWidth }}
      dangerouslySetInnerHTML={{
        __html: highlightKeywords(data.item, data.value),
      }}
    />
  );
};
