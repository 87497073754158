import React, { useMemo } from "react";
import "./style.scss";
import {
  useDeleteTalentAgencyInfoMutation,
  useGetTalentAgencyInfoDetailQuery,
} from "../../talentAgencyInfoApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import DetailBlock from "../../../../components/commonBlock";
import { Button } from "antd";
import { openModal } from "../../../../components/modal/modalSlice";
import { useDispatch } from "react-redux";
import { formatPhoneNumber } from "../../../../utils/commonFunc";
import { kitToastMessage } from "../../../../components/toastMessage";

interface TalentAgencyInfoDetailProps {}

function TalentAgencyInfoDetail(props: TalentAgencyInfoDetailProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess } = useGetTalentAgencyInfoDetailQuery(
    { businessId: searchParams.get("id") },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [deleteTalentAgencyInfo] = useDeleteTalentAgencyInfoMutation();
  const blockInfo = useMemo(() => {
    const blockPic =
      !isLoading && data?.data
        ? [
            { title: "담당자명", desc: data.data?.picName },
            {
              title: "담당자 부서/직책",
              desc: data.data?.picPosition,
            },
            {
              title: "담당자 연락처",
              desc:
                data?.data?.picNumber?.length > 3
                  ? formatPhoneNumber(data?.data?.picNumber)
                  : data?.data?.picNumber,
            },
            {
              title: "담당자 이메일",
              desc: data.data?.picEmail,
            },
          ]
        : [];
    const blockBusiness =
      !isLoading && data?.data
        ? [
            { title: "기관명", desc: data.data?.business?.businessName },
            { title: "기관이미지", desc: "image.jpg" },
            { title: "역할", desc: data.data?.roles },
            { title: "기관 주소", desc: data.data?.business.address },
            {
              title: "대표전화",
              desc: formatPhoneNumber(data.data?.business?.user?.phoneNumber),
            },
            { title: "홈페이지", desc: data.data.business.homepage },
            {
              title: "기관 형태",
              desc:
                data.data.businessType === 1
                  ? "직업훈련기관"
                  : data.data.businessType === 2
                  ? "대학"
                  : data.data.businessType === 3
                  ? "특성화고"
                  : data.data.businessType === 4
                  ? "기타"
                  : data.data.businessType,
            },
            { title: "교육 훈련 목표", desc: data.data.trainingGoals },
            { title: "주요 훈련 과정", desc: data.data.trainingProcess },
          ]
        : [];
    const blockTraining =
      !isLoading && data?.data
        ? data?.data?.businessTraining?.map(
            ({
              title,
              description,
              trainingPeriod,
              trainingTime,
              trainingHuman,
            }: any) => {
              return [
                { title: "교육 제목", desc: title },
                { title: "교육 내용", desc: description },
                { title: "훈련 기간", desc: trainingPeriod },
                { title: "훈련 시간 ", desc: trainingTime },
                { title: "훈련 인원 ", desc: trainingHuman },
              ];
            }
          )
        : [];
    return { blockPic, blockBusiness, blockTraining };
  }, [isLoading, data]);

  const handleDeleteRecord = () => {
    try {
      const res: any = deleteTalentAgencyInfo({
        businessId: searchParams.get("id"),
      }).unwrap();
      if (res.statusCode === 200) {
        kitToastMessage("bottom", "삭제 완료되었습니다.", "success");
        navigate("/talent-agency-information");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="talent-agency-info-detail">
      <div
        className={`talent-agency-info-detail__header ${
          true ? "is-admin-page" : null
        }`}
      >
        <div className="title-container">
          <p className="title">인재 기관정보 상세 </p>
        </div>
      </div>

      <div className="talent-agency-info-detail__body">
        <div className="block-one">
          <div className="block-one-action">
            <Button
              className="btn-crud--primary"
              onClick={() =>
                navigate(
                  `/talent-agency-information/update?id=${searchParams.get(
                    "id"
                  )}`
                )
              }
            >
              수정하기
            </Button>
            <Button
              className="btn-crud--error"
              onClick={() => {
                dispatch(
                  openModal({
                    template: "show-confirm",
                    data: {
                      type: "delete",
                      confirmText: "정말로 삭제하시겠습니까?",
                    },
                    actionConfirm: handleDeleteRecord,
                  })
                );
              }}
            >
              삭제하기
            </Button>
          </div>
        </div>

        <div className="block-two">
          <DetailBlock
            title="담당자 정보"
            content={blockInfo.blockPic}
            className={{ flex: "1" }}
          />
          <DetailBlock
            title="기관 정보"
            content={blockInfo.blockBusiness}
            className={{ flex: "1" }}
          />
        </div>
        <div className="block-three">
          {blockInfo.blockTraining?.map((item: any, key: number) => {
            return (
              <DetailBlock
                title={key === 0 ? "교육 정보" : ""}
                content={item}
                className={{ flex: "1" }}
              />
            );
          })}
        </div>
        <div className="member-management-detail__footer">
          <Button
            className="btn--goback"
            onClick={() => navigate("/talent-agency-information")}
          >
            목록으로
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TalentAgencyInfoDetail;
