export const listLv1 = [
  {
    value: "방송/영상/매스컴",
    label: "방송/영상/매스컴",
    listLv2: [
      {
        value: "지상파방송",
        label: "지상파방송",
      },
      {
        value: "지상파계열",
        label: "지상파계열",
      },
      {
        value: "종합편성채널",
        label: "종합편성채널",
      },
      {
        value: "지역방송",
        label: "지역방송",
      },
      {
        value: "케이블 TV(PP)",
        label: "케이블 TV(PP)",
      },
      {
        value: "종합유선방송",
        label: "종합유선방송",
      },
      {
        value: "위성방송",
        label: "위성방송",
      },
      {
        value: "라디오방송",
        label: "라디오방송",
      },
      {
        value: "인터넷방송",
        label: "인터넷방송",
      },
      {
        value: "보도전문채널",
        label: "보도전문채널",
      },
    ],
  },
  {
    value: "프로덕션",
    label: "프로덕션",
    listLv2: [
      {
        value: "방송외주프로덕션",
        label: "방송외주프로덕션",
      },
      {
        value: "CF/광고 프로덕션",
        label: "CF/광고 프로덕션",
      },
      {
        value: "방송장비 협력사",
        label: "방송장비 협력사",
      },
      {
        value: "렌탈/임대",
        label: "렌탈/임대",
      },
      {
        value: "세트/무대",
        label: "세트/무대",
      },
      {
        value: "스튜디오",
        label: "스튜디오",
      },
      {
        value: "방송콘텐츠 제작사",
        label: "방송콘텐츠 제작사",
      },
    ],
  },
  {
    value: "광고/홍보/이벤트",
    label: "광고/홍보/이벤트",
    listLv2: [
      {
        value: "광고대행/기획사",
        label: "광고대행/기획사",
      },
      {
        value: "홍보대행사",
        label: "홍보대행사",
      },
      {
        value: "이벤트(공연)기획사",
        label: "이벤트(공연)기획사",
      },
      {
        value: "디자인기획사",
        label: "디자인기획사",
      },
    ],
  },
  {
    value: "영화/배급",
    label: "영화/배급",
    listLv2: [
      {
        value: "영화제작/기획사",
        label: "영화제작/기획사",
      },
      {
        value: "홍보대행/배급사",
        label: "홍보대행/배급사",
      },
    ],
  },
  {
    value: "연예/엔터테인먼트",
    label: "연예/엔터테인먼트",
    listLv2: [
      {
        value: "연예기획/엔터테인먼트",
        label: "연예기획/엔터테인먼트",
      },
      {
        value: "매니지먼트",
        label: "매니지먼트",
      },
      {
        value: "음반사",
        label: "음반사",
      },
    ],
  },
  {
    value: "신문/언론사",
    label: "신문/언론사",
    listLv2: [
      {
        value: "일간지",
        label: "일간지",
      },
      {
        value: "경제신문",
        label: "경제신문",
      },
      {
        value: "지역신문",
        label: "지역신문",
      },
      {
        value: "스포츠신문",
        label: "스포츠신문",
      },
      {
        value: "전문신문",
        label: "전문신문",
      },
      {
        value: "인터넷신문",
        label: "인터넷신문",
      },
    ],
  },
  {
    value: "출판/인쇄사진",
    label: "출판/인쇄사진",
    listLv2: [
      {
        value: "출판사",
        label: "출판사",
      },
      {
        value: "잡지사",
        label: "잡지사",
      },
      {
        value: "전자출판",
        label: "전자출판",
      },
      {
        value: "서점/인터넷서점",
        label: "서점/인터넷서점",
      },
      {
        value: "편집디자인",
        label: "편집디자인",
      },
    ],
  },
];

export const listPos = [
  {
    value: "방송연출•기획",
    label: "방송연출•기획",
    child: [
      {
        value: "PD•방송연출",
        label: "PD•방송연출",
        selected: false,
      },
      {
        value: "AD•조연출",
        label: "AD•조연출",
        selected: false,
      },
      {
        value: "FD•연출보조",
        label: "FD•연출보조",
        selected: false,
      },
      {
        value: "방송편성",
        label: "방송편성",
        selected: false,
      },
      {
        value: "방송제작",
        label: "방송제작",
        selected: false,
      },
      {
        value: "외화더빙•영상번역",
        label: "외화더빙•영상번역",
        selected: false,
      },
      {
        value: "비디오저널리스트",
        label: "비디오저널리스트",
        selected: false,
      },
      {
        value: "OAP•PD",
        label: "OAP•PD",
        selected: false,
      },
      {
        value: "콘텐츠 사업",
        label: "콘텐츠 사업",
        selected: false,
      },
      {
        value: "홈쇼핑•MD",
        label: "홈쇼핑•MD",
        selected: false,
      },
      {
        value: "유튜브 PD",
        label: "유튜브 PD",
        selected: false,
      },
    ],
  },
  {
    value: "방송기술",
    label: "방송기술",
    child: [
      {
        value: "방송기술",
        label: "방송기술",
        selected: false,
      },
      {
        value: "방송카메라",
        label: "방송카메라",
        selected: false,
      },
      {
        value: "방송송출",
        label: "방송송출",
        selected: false,
      },

      {
        value: "영상편집",
        label: "영상편집",
        selected: false,
      },
      {
        value: "인제스트/인코딩",
        label: "인제스트/인코딩",
        selected: false,
      },
      {
        value: "촬영보조/카메라보조",
        label: "촬영보조/카메라보조",
        selected: false,
      },
      {
        value: "동영상 촬영/편집",
        label: "동영상 촬영/편집",
        selected: false,
      },
      {
        value: "음향/오디오엔지니어",
        label: "음향/오디오엔지니어",
        selected: false,
      },
      {
        value: "MD/편성운행",
        label: "MD/편성운행",
        selected: false,
      },

      {
        value: "조명",
        label: "조명",
        selected: false,
      },
      {
        value: "드론 촬영기사",
        label: "드론 촬영기사",
        selected: false,
      },
    ],
  },
  {
    value: "방송미술•CG",
    label: "방송미술•CG",
    child: [
      {
        value: "자막/문자발생",
        label: "자막/문자발생",
        selected: false,
      },
      {
        value: "무대/세트디자인",
        label: "무대/세트디자인",
        selected: false,
      },
      {
        value: "모션그래픽",
        label: "모션그래픽",
        selected: false,
      },
      {
        value: "영상그래픽",
        label: "영상그래픽",
        selected: false,
      },
      {
        value: "OAP",
        label: "OAP",
        selected: false,
      },
      {
        value: "CG",
        label: "CG",
        selected: false,
      },
      {
        value: "2D/3D",
        label: "2D/3D",
        selected: false,
      },
    ],
  },
  {
    value: "방송진행",
    label: "방송진행",
    child: [
      {
        value: "아나운서",
        label: "아나운서",
        selected: false,
      },
      {
        value: "앵커",
        label: "앵커",
        selected: false,
      },
      {
        value: "리포터",
        label: "리포터",
        selected: false,
      },
      {
        value: "기상캐스터",
        label: "기상캐스터",
        selected: false,
      },
      {
        value: "MC/VJ",
        label: "MC/VJ",
        selected: false,
      },
      {
        value: "쇼(핑)호스트",
        label: "쇼(핑)호스트",
        selected: false,
      },
      {
        value: "성우",
        label: "성우",
        selected: false,
      },
      {
        value: "사내방송",
        label: "사내방송",
        selected: false,
      },
      {
        value: "콘텐츠 크리에이터",
        label: "콘텐츠 크리에이터",
        selected: false,
      },
    ],
  },
  {
    value: "영화",
    label: "영화",
    child: [
      {
        value: "영화(제작) 프로듀서",
        label: "영화(제작) 프로듀서",
        selected: false,
      },
      {
        value: "영화감독/조감독",
        label: "영화감독/조감독",
        selected: false,
      },
      {
        value: "영화홍보/마케팅",
        label: "영화홍보/마케팅",
        selected: false,
      },
      {
        value: "영화배급",
        label: "영화배급",
        selected: false,
      },
      {
        value: "영사기사",
        label: "영사기사",
        selected: false,
      },
      {
        value: "영화제작부/관리",
        label: "영화제작부/관리",
        selected: false,
      },
      {
        value: "영화미술/소품",
        label: "영화미술/소품",
        selected: false,
      },
      {
        value: "특수효과",
        label: "특수효과",
        selected: false,
      },
      {
        value: "영화관",
        label: "영화관",
        selected: false,
      },
      {
        value: "영화제행사 스텝",
        label: "영화제행사 스텝",
        selected: false,
      },
    ],
  },
  {
    value: "작가",
    label: "작가",
    child: [
      {
        value: "방송(구성)작가",
        label: "방송(구성)작가",
        selected: false,
      },
      {
        value: "드라마작가",
        label: "드라마작가",
        selected: false,
      },
      {
        value: "시나리오작가",
        label: "시나리오작가",
        selected: false,
      },
      {
        value: "영상번역작가",
        label: "영상번역작가",
        selected: false,
      },
      {
        value: "보조작가",
        label: "보조작가",
        selected: false,
      },
      {
        value: "자료조사/스크립터",
        label: "자료조사/스크립터",
        selected: false,
      },
      {
        value: "프리뷰어",
        label: "프리뷰어",
        selected: false,
      },
    ],
  },
  {
    value: "광고/이벤트",
    label: "광고/이벤트",
    child: [
      {
        value: "AE/광고기획",
        label: "AE/광고기획",
        selected: false,
      },
      {
        value: "이벤트기획/PD",
        label: "이벤트기획/PD",
        selected: false,
      },
      {
        value: "마케팅/프로모션",
        label: "마케팅/프로모션",
        selected: false,
      },
      {
        value: "홍보/언론홍보",
        label: "홍보/언론홍보",
        selected: false,
      },
      {
        value: "공연/전시기획",
        label: "공연/전시기획",
        selected: false,
      },
      {
        value: "CF 감독/조감독",
        label: "CF 감독/조감독",
        selected: false,
      },
      {
        value: "CM 플래너/광고PD",
        label: "CM 플래너/광고PD",
        selected: false,
      },
    ],
  },
];

export const listCarrierUpdate = [
  {
    value: "미디어&엔터테인먼트",
    label: "미디어&엔터테인먼트",
    child: [
      {
        value: "방송/영상/매스컴",
        label: "방송/영상/매스컴",
        secondChild: [
          {
            value: "지상파방송",
            label: "지상파방송",
          },
          {
            value: "지상파계열",
            label: "지상파계열",
          },
          {
            value: "종합편성채널",
            label: "종합편성채널",
          },
          {
            value: "지역방송",
            label: "지역방송",
          },
          {
            value: "케이블TV(PP)",
            label: "케이블TV(PP)",
          },
          {
            value: "종합유선방송",
            label: "종합유선방송",
          },
          {
            value: "위성방송",
            label: "위성방송",
          },
          {
            value: "라디오방송",
            label: "라디오방송",
          },
          {
            value: "인터넷방송",
            label: "인터넷방송",
          },
          {
            value: "보도전문채널",
            label: "보도전문채널",
          },
        ],
      },
      {
        value: "프로덕션",
        label: "프로덕션",
        secondChild: [
          {
            value: "방송외주프로덕션",
            label: "방송외주프로덕션",
          },
          {
            value: "CF/광고 프로덕션",
            label: "CF/광고 프로덕션",
          },
          {
            value: "방송장비 협력사",
            label: "방송장비 협력사",
          },
          {
            value: "렌탈/임대",
            label: "렌탈/임대",
          },
          {
            value: "세트/무대",
            label: "세트/무대",
          },
          {
            value: "스튜디오",
            label: "스튜디오",
          },
          {
            value: "방송콘텐츠 제작사",
            label: "방송콘텐츠 제작사",
          },
        ],
      },
      {
        value: "광고/홍보/이벤트",
        label: "광고/홍보/이벤트",
        secondChild: [
          {
            value: "광고대행/기획사",
            label: "광고대행/기획사",
          },
          {
            value: "홍보대행사",
            label: "홍보대행사",
          },
          {
            value: "이벤트(공연)기획사",
            label: "이벤트(공연)기획사",
          },
          {
            value: "디자인기획사",
            label: "디자인기획사",
          },
        ],
      },
      {
        value: "영화/배급",
        label: "영화/배급",
        secondChild: [
          {
            value: "영화제작/기획사",
            label: "영화제작/기획사",
          },
          {
            value: "홍보대행/배급사",
            label: "홍보대행/배급사",
          },
        ],
      },
      {
        value: "연예/엔터테인먼트",
        label: "연예/엔터테인먼트",
        secondChild: [
          {
            value: "연예기획/엔터테인먼트",
            label: "연예기획/엔터테인먼트",
          },
          {
            value: "매니지먼트",
            label: "매니지먼트",
          },
          {
            value: "음반사",
            label: "음반사",
          },
        ],
      },
      {
        value: "신문/언론사",
        label: "신문/언론사",
        secondChild: [
          {
            value: "일간지",
            label: "일간지",
          },
          {
            value: "경제신문",
            label: "경제신문",
          },
          {
            value: "지역신문",
            label: "지역신문",
          },
          {
            value: "스포츠신문",
            label: "스포츠신문",
          },
          {
            value: "전문신문",
            label: "전문신문",
          },
          {
            value: "인터넷신문",
            label: "인터넷신문",
          },
        ],
      },
      {
        value: "출판/인쇄/사진",
        label: "출판/인쇄/사진",
        secondChild: [
          {
            value: "출판사",
            label: "출판사",
          },
          {
            value: "잡지사",
            label: "잡지사",
          },
          {
            value: "전자출판",
            label: "전자출판",
          },
          {
            value: "서점/인터넷서점",
            label: "서점/인터넷서점",
          },
          {
            value: "편집디자인/인쇄출력소",
            label: "편집디자인/인쇄출력소",
          },
        ],
      },
    ],
  },
  {
    value: "IT정보통신업",
    label: "IT정보통신업",
    child: [
      {
        value: "소프트웨어 개발",
        label: "소프트웨어 개발",
        secondChild: [
          {
            value: "시스템 소프트웨어 개발",
            label: "시스템 소프트웨어 개발",
          },
          {
            value: "응용 소프트웨어 개발 ",
            label: "응용 소프트웨어 개발 ",
          },
          {
            value: "유선 온라인 게임 소프트웨어 개발",
            label: "유선 온라인 게임 소프트웨어 개발",
          },
          {
            value: "모바일 게임 소프트웨어 개발",
            label: "모바일 게임 소프트웨어 개발",
          },
          {
            value: "기타 게임 소프트웨어 개발",
            label: "기타 게임 소프트웨어 개발",
          },
        ],
      },
      {
        value: "컴퓨터 프로그래밍, 시스템 통합 및 관리업",
        label: "컴퓨터 프로그래밍, 시스템 통합 및 관리업",
        secondChild: [
          {
            value: "컴퓨터 프로그래밍 서비스업",
            label: "컴퓨터 프로그래밍 서비스업",
          },
          {
            value: "컴퓨터 시스템 통합 자문 및 구축 서비스업",
            label: "컴퓨터 시스템 통합 자문 및 구축 서비스업",
          },
          {
            value: "컴퓨터시설 관리업",
            label: "컴퓨터시설 관리업",
          },
          {
            value: "기타 정보 기술 및 컴퓨터 운영 관련 서비스업",
            label: "기타 정보 기술 및 컴퓨터 운영 관련 서비스업",
          },
        ],
      },
      {
        value: "자료 처리, 호스팅, 포털 및 기타 인터넷 정보 매개 서비스업",
        label: "자료 처리, 호스팅, 포털 및 기타 인터넷 정보 매개 서비스업",
        secondChild: [
          {
            value: "자료 처리업",
            label: "자료 처리업",
          },
          {
            value: "호스팅 및 관련 서비스업",
            label: "호스팅 및 관련 서비스업",
          },
          {
            value: "포털 및 기타 인터넷 정보 매개 서비스업",
            label: "포털 및 기타 인터넷 정보 매개 서비스업",
          },
        ],
      },
      {
        value: "기타 정보 서비스업",
        label: "기타 정보 서비스업",
        secondChild: [
          {
            value: "뉴스 제공업",
            label: "뉴스 제공업",
          },
          {
            value: "데이터베이스 및 온라인 정보 제공업",
            label: "데이터베이스 및 온라인 정보 제공업",
          },
          {
            value: "그 외 기타 정보 서비스업",
            label: "그 외 기타 정보 서비스업",
          },
        ],
      },
    ],
  },
  {
    value: "연구개발업",
    label: "연구개발업",
    child: [
      {
        value: "자연과학 및 공학 연구개발업",
        label: "자연과학 및 공학 연구개발업",
        secondChild: [
          {
            value: "물리, 화학 및 생물학 연구개발업",
            label: "물리, 화학 및 생물학 연구개발업",
          },
          {
            value: "농림수산학 및 수의학 연구개발업",
            label: "농림수산학 및 수의학 연구개발업",
          },
          {
            value: "의학 및 약학 연구개발업",
            label: "의학 및 약학 연구개발업",
          },
          {
            value: "기타 자연과학 연구개발업",
            label: "기타 자연과학 연구개발업",
          },
          {
            value: "전기ㆍ전자공학 연구개발업",
            label: "전기ㆍ전자공학 연구개발업",
          },
          {
            value: "기타 공학 연구개발업",
            label: "기타 공학 연구개발업",
          },
          {
            value: "자연과학 및 공학 융합 연구개발업",
            label: "자연과학 및 공학 융합 연구개발업",
          },
        ],
      },

      {
        value: "인문 및 사회과학 연구개발업",
        label: "인문 및 사회과학 연구개발업",
        secondChild: [
          {
            value: "경제 및 경영학 연구개발업",
            label: "경제 및 경영학 연구개발업",
          },
          {
            value: "기타 인문 및 사회과학 연구개발업",
            label: "기타 인문 및 사회과학 연구개발업",
          },
        ],
      },
    ],
  },
  {
    value: "전문서비스업",
    label: "전문서비스업",
    child: [
      {
        value: "시장 조사 및 여론 조사업",
        label: "시장 조사 및 여론 조사업",
        secondChild: [
          {
            value: "시장 조사 및 여론 조사업",
            label: "시장 조사 및 여론 조사업",
          },
        ],
      },
      {
        value: "컨설팅 서비스업",
        label: "컨설팅 서비스업",
        secondChild: [
          {
            value: "경영 컨설팅업",
            label: "경영 컨설팅업",
          },
          {
            value: "IT컨설팅",
            label: "IT컨설팅",
          },
          {
            value: "기타 컨설팅",
            label: "기타 컨설팅",
          },
        ],
      },
      {
        value: "기타 전문 서비스업",
        label: "기타 전문 서비스업",
        secondChild: [
          {
            value: "기타 전문 서비스업",
            label: "기타 전문 서비스업",
          },
        ],
      },
    ],
  },
  {
    value: "기관·협회·단체",
    label: "기관·협회·단체",
    child: [
      {
        value: "공기업·공공기관",
        label: "공기업·공공기관",
        secondChild: [
          {
            value: "공기업·공공기관",
            label: "공기업·공공기관",
          },
        ],
      },
      {
        value: "교육기관",
        label: "교육기관",
        secondChild: [
          {
            value: "중·교육기관 및 산하기관",
            label: "중·교육기관 및 산하기관",
          },
          {
            value: "직업훈련기관",
            label: "직업훈련기관",
          },
          {
            value: "기타 교육지원 서비스업",
            label: "기타 교육지원 서비스업",
          },
        ],
      },
      {
        value: "고용서비스제공기관",
        label: "고용서비스제공기관",
        secondChild: [
          {
            value: "고용서비스제공기관",
            label: "고용서비스제공기관",
          },
        ],
      },
      {
        value: "협회·단체",
        label: "협회·단체",
        secondChild: [
          {
            value: "협회·단체",
            label: "협회·단체",
          },
        ],
      },
    ],
  },
  {
    value: "기타",
    label: "기타",
    child: [
      {
        value: "애니메이션 제작 및 배급",
        label: "애니메이션 제작 및 배급",
        secondChild: [
          {
            value: "애니메이션 제작 및 배급",
            label: "애니메이션 제작 및 배급",
          },
        ],
      },
      {
        value: "전문 디자인업",
        label: "전문 디자인업",
        secondChild: [
          {
            value: "전문 디자인업",
            label: "전문 디자인업",
          },
        ],
      },
    ],
  },
  {
    value: "직접기재",
    label: "직접기재",
    child: [],
  },
];

export const opIntern = [
  {
    value: "PD/방송연출",
    label: "PD/방송연출",
  },
  {
    value: "AD/조연출",
    label: "AD/조연출",
  },
  {
    value: "FD/연출보조",
    label: "FD/연출보조",
  },
  {
    value: "방송편성",
    label: "방송편성",
  },
  {
    value: "방송제작",
    label: "방송제작",
  },
  {
    value: "외화더빙/영상번역",
    label: "외화더빙/영상번역",
  },
  {
    value: "비디오저널리스트",
    label: "비디오저널리스트",
  },
  {
    value: "OAP PD",
    label: "OAP PD",
  },
  {
    value: "콘텐츠 사업",
    label: "콘텐츠 사업",
  },
  {
    value: "홈쇼핑MD",
    label: "홈쇼핑MD",
  },
  {
    value: "유튜브 PD",
    label: "유튜브 PD",
  },
  {
    value: "방송기술",
    label: "방송기술",
  },
  {
    value: "방송카메라",
    label: "방송카메라",
  },
  {
    value: "방송송출",
    label: "방송송출",
  },
  {
    value: "영상편집",
    label: "영상편집",
  },
  {
    value: "인제스트/인코딩",
    label: "인제스트/인코딩",
  },
  {
    value: "아카이브",
    label: "아카이브",
  },
  {
    value: "촬영보조/카메라보조",
    label: "촬영보조/카메라보조",
  },
  {
    value: "동영상 촬영/편집",
    label: "동영상 촬영/편집",
  },
  {
    value: "음향/오디오엔지니어",
    label: "음향/오디오엔지니어",
  },
  {
    value: "MD/편성운행",
    label: "MD/편성운행",
  },
  {
    value: "조명",
    label: "조명",
  },
  {
    value: "드론 촬영기사",
    label: "드론 촬영기사",
  },
  {
    value: "자막/문자발생",
    label: "자막/문자발생",
  },
  {
    value: "무대/세트디자인",
    label: "무대/세트디자인",
  },
  {
    value: "모션그래픽",
    label: "모션그래픽",
  },
  {
    value: "영상그래픽",
    label: "영상그래픽",
  },
  {
    value: "OAP",
    label: "OAP",
  },
  {
    value: "CG",
    label: "CG",
  },
  {
    value: "2D/3D",
    label: "2D/3D",
  },
  {
    value: "아나운서",
    label: "아나운서",
  },
  {
    value: "앵커",
    label: "앵커",
  },
  {
    value: "리포터",
    label: "리포터",
  },
  {
    value: "기상캐스터",
    label: "기상캐스터",
  },
  {
    value: "MC/VJ",
    label: "MC/VJ",
  },
  {
    value: "쇼(핑)호스트",
    label: "쇼(핑)호스트",
  },
  {
    value: "성우",
    label: "성우",
  },
  {
    value: "사내방송",
    label: "사내방송",
  },
  {
    value: "콘텐츠 크리에이터",
    label: "콘텐츠 크리에이터",
  },
  {
    value: "영화(제작) 프로듀서",
    label: "영화(제작) 프로듀서",
  },
  {
    value: "영화감독/조감독",
    label: "영화감독/조감독",
  },
  {
    value: "영화홍보/마케팅",
    label: "영화홍보/마케팅",
  },
  {
    value: "영화배급",
    label: "영화배급",
  },
  {
    value: "영사기사",
    label: "영사기사",
  },
  {
    value: "영화제작부/관리",
    label: "영화제작부/관리",
  },
  {
    value: "영화미술/소품",
    label: "영화미술/소품",
  },
  {
    value: "특수효과",
    label: "특수효과",
  },
  {
    value: "영화관",
    label: "영화관",
  },
  {
    value: "영화제행사 스텝",
    label: "영화제행사 스텝",
  },
  {
    value: "방송(구성)작가",
    label: "방송(구성)작가",
  },
  {
    value: "드라마작가",
    label: "드라마작가",
  },
  {
    value: "시나리오작가",
    label: "시나리오작가",
  },
  {
    value: "영상번역작가",
    label: "영상번역작가",
  },
  {
    value: "보조작가",
    label: "보조작가",
  },
  {
    value: "자료조사/스크립터",
    label: "자료조사/스크립터",
  },
  {
    value: "프리뷰어",
    label: "프리뷰어",
  },
  {
    value: "AE/광고기획",
    label: "AE/광고기획",
  },
  {
    value: "이벤트기획/PD",
    label: "이벤트기획/PD",
  },
  {
    value: "카피라이터",
    label: "카피라이터",
  },
  {
    value: "마케팅/프로모션",
    label: "마케팅/프로모션",
  },
  {
    value: "홍보/언론홍보",
    label: "홍보/언론홍보",
  },
  {
    value: "공연/전시기획",
    label: "공연/전시기획",
  },
  {
    value: "CF 감독/조감독",
    label: "CF 감독/조감독",
  },
  {
    value: "CM 플래너/광고PD",
    label: "CM 플래너/광고PD",
  },
];

export const cYears = [
  {
    value: "2023년",
    label: "2023년",
  },
  {
    value: "2022년",
    label: "2022년",
  },
  {
    value: "2021년",
    label: "2021년",
  },
  {
    value: "2020년",
    label: "2020년",
  },
  {
    value: "2019년",
    label: "2019년",
  },
  {
    value: "2018년",
    label: "2018년",
  },
  {
    value: "2017년",
    label: "2017년",
  },
  {
    value: "2016년",
    label: "2016년",
  },
  {
    value: "2015년",
    label: "2015년",
  },
  {
    value: "2014년",
    label: "2014년",
  },
  {
    value: "2013년",
    label: "2013년",
  },
  {
    value: "2012년",
    label: "2012년",
  },
  {
    value: "2011년",
    label: "2011년",
  },
  {
    value: "2010년",
    label: "2010년",
  },
  {
    value: "2009년",
    label: "2009년",
  },
  {
    value: "2008년",
    label: "2008년",
  },
  {
    value: "2007년",
    label: "2007년",
  },
  {
    value: "2006년",
    label: "2006년",
  },
  {
    value: "2005년",
    label: "2005년",
  },
  {
    value: "2004년",
    label: "2004년",
  },
  {
    value: "2003년",
    label: "2003년",
  },
  {
    value: "2002년",
    label: "2002년",
  },
  {
    value: "2001년",
    label: "2001년",
  },
  {
    value: "2000년",
    label: "2000년",
  },
  {
    value: "1999년",
    label: "1999년",
  },
  {
    value: "1998년",
    label: "1998년",
  },
  {
    value: "1997년",
    label: "1997년",
  },
  {
    value: "1996년",
    label: "1996년",
  },
  {
    value: "1995년",
    label: "1995년",
  },
  {
    value: "1994년",
    label: "1994년",
  },
  {
    value: "1993년",
    label: "1993년",
  },
  {
    value: "1992년",
    label: "1992년",
  },
  {
    value: "1991년",
    label: "1991년",
  },
  {
    value: "1990년",
    label: "1990년",
  },
  {
    value: "1989년",
    label: "1989년",
  },
  {
    value: "1988년",
    label: "1988년",
  },
  {
    value: "1987년",
    label: "1987년",
  },
  {
    value: "1986년",
    label: "1986년",
  },
  {
    value: "1985년",
    label: "1985년",
  },
  {
    value: "1984년",
    label: "1984년",
  },
  {
    value: "1983년",
    label: "1983년",
  },
  {
    value: "1982년",
    label: "1982년",
  },
  {
    value: "1981년",
    label: "1981년",
  },
  {
    value: "1980년",
    label: "1980년",
  },
  {
    value: "1979년",
    label: "1979년",
  },
  {
    value: "1978년",
    label: "1978년",
  },
  {
    value: "1977년",
    label: "1977년",
  },
  {
    value: "1976년",
    label: "1976년",
  },
  {
    value: "1975년",
    label: "1975년",
  },
  {
    value: "1974년",
    label: "1974년",
  },
  {
    value: "1973년",
    label: "1973년",
  },
  {
    value: "1972년",
    label: "1972년",
  },
  {
    value: "1971년",
    label: "1971년",
  },
  {
    value: "1970년",
    label: "1970년",
  },
  {
    value: "1969년",
    label: "1969년",
  },
  {
    value: "1968년",
    label: "1968년",
  },
  {
    value: "1967년",
    label: "1967년",
  },
  {
    value: "1966년",
    label: "1966년",
  },
  {
    value: "1965년",
    label: "1965년",
  },
  {
    value: "1964년",
    label: "1964년",
  },
  {
    value: "1963년",
    label: "1963년",
  },
  {
    value: "1962년",
    label: "1962년",
  },
  {
    value: "1961년",
    label: "1961년",
  },
  {
    value: "1960년",
    label: "1960년",
  },
  {
    value: "1959년",
    label: "1959년",
  },
  {
    value: "1958년",
    label: "1958년",
  },
  {
    value: "1957년",
    label: "1957년",
  },
  {
    value: "1956년",
    label: "1956년",
  },
  {
    value: "1955년",
    label: "1955년",
  },
  {
    value: "1954년",
    label: "1954년",
  },
  {
    value: "1953년",
    label: "1953년",
  },
  {
    value: "1952년",
    label: "1952년",
  },
  {
    value: "1951년",
    label: "1951년",
  },
  {
    value: "1950년",
    label: "1950년",
  },
  {
    value: "1949년",
    label: "1949년",
  },
  {
    value: "1948년",
    label: "1948년",
  },
  {
    value: "1947년",
    label: "1947년",
  },
  {
    value: "1946년",
    label: "1946년",
  },
  {
    value: "1945년",
    label: "1945년",
  },
  {
    value: "1944년",
    label: "1944년",
  },
  {
    value: "1943년",
    label: "1943년",
  },
  {
    value: "1942년",
    label: "1942년",
  },
  {
    value: "1941년",
    label: "1941년",
  },
  {
    value: "1940년",
    label: "1940년",
  },
  {
    value: "1939년",
    label: "1939년",
  },
  {
    value: "1938년",
    label: "1938년",
  },
  {
    value: "1937년",
    label: "1937년",
  },
  {
    value: "1936년",
    label: "1936년",
  },
  {
    value: "1935년",
    label: "1935년",
  },
  {
    value: "1934년",
    label: "1934년",
  },
  {
    value: "1933년",
    label: "1933년",
  },
  {
    value: "1932년",
    label: "1932년",
  },
  {
    value: "1931년",
    label: "1931년",
  },
  {
    value: "1930년",
    label: "1930년",
  },
  {
    value: "1929년",
    label: "1929년",
  },
  {
    value: "1928년",
    label: "1928년",
  },
  {
    value: "1927년",
    label: "1927년",
  },
  {
    value: "1926년",
    label: "1926년",
  },
  {
    value: "1925년",
    label: "1925년",
  },
  {
    value: "1924년",
    label: "1924년",
  },
  {
    value: "1923년",
    label: "1923년",
  },
  {
    value: "1922년",
    label: "1922년",
  },
  {
    value: "1921년",
    label: "1921년",
  },
  {
    value: "1920년",
    label: "1920년",
  },
  {
    value: "1919년",
    label: "1919년",
  },
  {
    value: "1918년",
    label: "1918년",
  },
  {
    value: "1917년",
    label: "1917년",
  },
  {
    value: "1916년",
    label: "1916년",
  },
  {
    value: "1915년",
    label: "1915년",
  },
  {
    value: "1914년",
    label: "1914년",
  },
  {
    value: "1913년",
    label: "1913년",
  },
  {
    value: "1912년",
    label: "1912년",
  },
  {
    value: "1911년",
    label: "1911년",
  },
  {
    value: "1910년",
    label: "1910년",
  },
  {
    value: "1909년",
    label: "1909년",
  },
  {
    value: "1908년",
    label: "1908년",
  },
  {
    value: "1907년",
    label: "1907년",
  },
  {
    value: "1906년",
    label: "1906년",
  },
  {
    value: "1905년",
    label: "1905년",
  },
  {
    value: "1904년",
    label: "1904년",
  },
  {
    value: "1903년",
    label: "1903년",
  },
  {
    value: "1902년",
    label: "1902년",
  },
  {
    value: "1901년",
    label: "1901년",
  },
  {
    value: "1900년",
    label: "1900년",
  },
];

export const cMonths = [
  {
    value: "1월",
    label: "1월",
  },
  {
    value: "2월",
    label: "2월",
  },
  {
    value: "3월",
    label: "3월",
  },
  {
    value: "4월",
    label: "4월",
  },
  {
    value: "5월",
    label: "5월",
  },
  {
    value: "6월",
    label: "6월",
  },
  {
    value: "7월",
    label: "7월",
  },
  {
    value: "8월",
    label: "8월",
  },
  {
    value: "9월",
    label: "9월",
  },
  {
    value: "10월",
    label: "10월",
  },
  {
    value: "11월",
    label: "11월",
  },
  {
    value: "12월",
    label: "12월",
  },
];

export const cDays = [
  {
    value: "1일",
    label: "1일",
  },
  {
    value: "2일",
    label: "2일",
  },
  {
    value: "3일",
    label: "3일",
  },
  {
    value: "4일",
    label: "4일",
  },
  {
    value: "5일",
    label: "5일",
  },
  {
    value: "6일",
    label: "6일",
  },
  {
    value: "7일",
    label: "7일",
  },
  {
    value: "8일",
    label: "8일",
  },
  {
    value: "9일",
    label: "9일",
  },
  {
    value: "10일",
    label: "10일",
  },
  {
    value: "11일",
    label: "11일",
  },
  {
    value: "12일",
    label: "12일",
  },
  {
    value: "13일",
    label: "13일",
  },
  {
    value: "14일",
    label: "14일",
  },
  {
    value: "15일",
    label: "15일",
  },
  {
    value: "16일",
    label: "16일",
  },
  {
    value: "17일",
    label: "17일",
  },
  {
    value: "18일",
    label: "18일",
  },
  {
    value: "19일",
    label: "19일",
  },
  {
    value: "20일",
    label: "20일",
  },
  {
    value: "21일",
    label: "21일",
  },
  {
    value: "22일",
    label: "22일",
  },
  {
    value: "23일",
    label: "23일",
  },
  {
    value: "24일",
    label: "24일",
  },
  {
    value: "25일",
    label: "25일",
  },
  {
    value: "26일",
    label: "26일",
  },
  {
    value: "27일",
    label: "27일",
  },
  {
    value: "28일",
    label: "28일",
  },
  {
    value: "29일",
    label: "29일",
  },
  {
    value: "30일",
    label: "30일",
  },
  {
    value: "31일",
    label: "31일",
  },
];

export const field1 = [
  {
    value: "영업",
    label: "영업",
  },
];

export const field2 = [
  {
    value: "서비스",
    label: "서비스",
  },
];

export const cYearsReal = [
  {
    value: "2024",
    label: "2024",
  },
  {
    value: "2023",
    label: "2023",
  },
  {
    value: "2022",
    label: "2022",
  },
  {
    value: "2021",
    label: "2021",
  },
  {
    value: "2020",
    label: "2020",
  },
  {
    value: "2019",
    label: "2019",
  },
  {
    value: "2018",
    label: "2018",
  },
  {
    value: "2017",
    label: "2017",
  },
  {
    value: "2016",
    label: "2016",
  },
  {
    value: "2015",
    label: "2015",
  },
  {
    value: "2014",
    label: "2014",
  },
  {
    value: "2013",
    label: "2013",
  },
  {
    value: "2012",
    label: "2012",
  },
  {
    value: "2011",
    label: "2011",
  },
  {
    value: "2010",
    label: "2010",
  },
  {
    value: "2009",
    label: "2009",
  },
  {
    value: "2008",
    label: "2008",
  },
  {
    value: "2007",
    label: "2007",
  },
  {
    value: "2006",
    label: "2006",
  },
  {
    value: "2005",
    label: "2005",
  },
  {
    value: "2004",
    label: "2004",
  },
  {
    value: "2003",
    label: "2003",
  },
  {
    value: "2002",
    label: "2002",
  },
  {
    value: "2001",
    label: "2001",
  },
  {
    value: "2000",
    label: "2000",
  },
  {
    value: "1999",
    label: "1999",
  },
  {
    value: "1998",
    label: "1998",
  },
  {
    value: "1997",
    label: "1997",
  },
  {
    value: "1996",
    label: "1996",
  },
  {
    value: "1995",
    label: "1995",
  },
  {
    value: "1994",
    label: "1994",
  },
  {
    value: "1993",
    label: "1993",
  },
  {
    value: "1992",
    label: "1992",
  },
  {
    value: "1991",
    label: "1991",
  },
  {
    value: "1990",
    label: "1990",
  },
  {
    value: "1989",
    label: "1989",
  },
  {
    value: "1988",
    label: "1988",
  },
  {
    value: "1987",
    label: "1987",
  },
  {
    value: "1986",
    label: "1986",
  },
  {
    value: "1985",
    label: "1985",
  },
  {
    value: "1984",
    label: "1984",
  },
  {
    value: "1983",
    label: "1983",
  },
  {
    value: "1982",
    label: "1982",
  },
  {
    value: "1981",
    label: "1981",
  },
  {
    value: "1980",
    label: "1980",
  },
  {
    value: "1979",
    label: "1979",
  },
  {
    value: "1978",
    label: "1978",
  },
  {
    value: "1977",
    label: "1977",
  },
  {
    value: "1976",
    label: "1976",
  },
  {
    value: "1975",
    label: "1975",
  },
  {
    value: "1974",
    label: "1974",
  },
  {
    value: "1973",
    label: "1973",
  },
  {
    value: "1972",
    label: "1972",
  },
  {
    value: "1971",
    label: "1971",
  },
  {
    value: "1970",
    label: "1970",
  },
  {
    value: "1969",
    label: "1969",
  },
  {
    value: "1968",
    label: "1968",
  },
  {
    value: "1967",
    label: "1967",
  },
  {
    value: "1966",
    label: "1966",
  },
  {
    value: "1965",
    label: "1965",
  },
  {
    value: "1964",
    label: "1964",
  },
  {
    value: "1963",
    label: "1963",
  },
  {
    value: "1962",
    label: "1962",
  },
  {
    value: "1961",
    label: "1961",
  },
  {
    value: "1960",
    label: "1960",
  },
  {
    value: "1959",
    label: "1959",
  },
  {
    value: "1958",
    label: "1958",
  },
  {
    value: "1957",
    label: "1957",
  },
  {
    value: "1956",
    label: "1956",
  },
  {
    value: "1955",
    label: "1955",
  },
  {
    value: "1954",
    label: "1954",
  },
  {
    value: "1953",
    label: "1953",
  },
  {
    value: "1952",
    label: "1952",
  },
  {
    value: "1951",
    label: "1951",
  },
  {
    value: "1950",
    label: "1950",
  },
  {
    value: "1949",
    label: "1949",
  },
  {
    value: "1948",
    label: "1948",
  },
  {
    value: "1947",
    label: "1947",
  },
  {
    value: "1946",
    label: "1946",
  },
  {
    value: "1945",
    label: "1945",
  },
  {
    value: "1944",
    label: "1944",
  },
  {
    value: "1943",
    label: "1943",
  },
  {
    value: "1942",
    label: "1942",
  },
  {
    value: "1941",
    label: "1941",
  },
  {
    value: "1940",
    label: "1940",
  },
  {
    value: "1939",
    label: "1939",
  },
  {
    value: "1938",
    label: "1938",
  },
  {
    value: "1937",
    label: "1937",
  },
  {
    value: "1936",
    label: "1936",
  },
  {
    value: "1935",
    label: "1935",
  },
  {
    value: "1934",
    label: "1934",
  },
  {
    value: "1933",
    label: "1933",
  },
  {
    value: "1932",
    label: "1932",
  },
  {
    value: "1931",
    label: "1931",
  },
  {
    value: "1930",
    label: "1930",
  },
  {
    value: "1929",
    label: "1929",
  },
  {
    value: "1928",
    label: "1928",
  },
  {
    value: "1927",
    label: "1927",
  },
  {
    value: "1926",
    label: "1926",
  },
  {
    value: "1925",
    label: "1925",
  },
  {
    value: "1924",
    label: "1924",
  },
  {
    value: "1923",
    label: "1923",
  },
  {
    value: "1922",
    label: "1922",
  },
  {
    value: "1921",
    label: "1921",
  },
  {
    value: "1920",
    label: "1920",
  },
  {
    value: "1919",
    label: "1919",
  },
  {
    value: "1918",
    label: "1918",
  },
  {
    value: "1917",
    label: "1917",
  },
  {
    value: "1916",
    label: "1916",
  },
  {
    value: "1915",
    label: "1915",
  },
  {
    value: "1914",
    label: "1914",
  },
  {
    value: "1913",
    label: "1913",
  },
  {
    value: "1912",
    label: "1912",
  },
  {
    value: "1911",
    label: "1911",
  },
  {
    value: "1910",
    label: "1910",
  },
  {
    value: "1909",
    label: "1909",
  },
  {
    value: "1908",
    label: "1908",
  },
  {
    value: "1907",
    label: "1907",
  },
  {
    value: "1906",
    label: "1906",
  },
  {
    value: "1905",
    label: "1905",
  },
  {
    value: "1904",
    label: "1904",
  },
  {
    value: "1903",
    label: "1903",
  },
  {
    value: "1902",
    label: "1902",
  },
  {
    value: "1901",
    label: "1901",
  },
  {
    value: "1900",
    label: "1900",
  },
];

export const cMonthsReal = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "11",
    label: "11",
  },
  {
    value: "12",
    label: "12",
  },
];

export const cDaysReal = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "11",
    label: "11",
  },
  {
    value: "12",
    label: "12",
  },
  {
    value: "13",
    label: "13",
  },
  {
    value: "14",
    label: "14",
  },
  {
    value: "15",
    label: "15",
  },
  {
    value: "16",
    label: "16",
  },
  {
    value: "17",
    label: "17",
  },
  {
    value: "18",
    label: "18",
  },
  {
    value: "19",
    label: "19",
  },
  {
    value: "20",
    label: "20",
  },
  {
    value: "21",
    label: "21",
  },
  {
    value: "22",
    label: "22",
  },
  {
    value: "23",
    label: "23",
  },
  {
    value: "24",
    label: "24",
  },
  {
    value: "25",
    label: "25",
  },
  {
    value: "26",
    label: "26",
  },
  {
    value: "27",
    label: "27",
  },
  {
    value: "28",
    label: "28",
  },
  {
    value: "29",
    label: "29",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "31",
    label: "31",
  },
];

export const listPrograms = ["상시채용", "DMC 500캠프"];

export const listEducations = ["무관", "고졸", "대졸", "석사졸"];

export const listProgramCareers = ["인턴", "신입"];

export const listTypeOfEmployments = ["인턴십", "계약직", "정규직"];

export const listBenefits = [
  "건강보험",
  "국민연금",
  "고용보험",
  "산재보험",
  "월차제도",
  "연차제도",
  "기타",
];
