/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../../utils/constants";

export const noticeApi = createApi({
  reducerPath: "notice",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["getNoticeList"],
  endpoints: (builder) => ({
    getNoticeList: builder.query({
      query: (data) => ({
        url: `notifications-v2`,
        method: "GET",
        params: data,
      }),
      providesTags: ["getNoticeList"],
    }),
    getNoticeDetail: builder.query({
      query: (data) => ({
        url: `notifications-v2/${data.id}`,
        method: "GET",
      }),
      providesTags: ["getNoticeList"],
    }),
    uploadFileNotice: builder.mutation({
      query: ({ data, id }) => ({
        url: `notifications-v2/content-file`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getNoticeList"],
    }),
    deleteNotice: builder.mutation({
      query: ({ id }) => ({
        url: `notifications-v2/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getNoticeList"],
    }),
    updateNotice: builder.mutation({
      query: (data) => ({
        url: `notifications-v2/${data?.get("id")}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["getNoticeList"],
    }),
    createNotice: builder.mutation({
      query: (data) => ({
        url: `notifications-v2`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getNoticeList"],
    }),
  }),
});

export const {
  useLazyGetNoticeDetailQuery,
  useGetNoticeDetailQuery,
  useGetNoticeListQuery,
  useUploadFileNoticeMutation,
  useDeleteNoticeMutation,
  useUpdateNoticeMutation,
  useCreateNoticeMutation,
} = noticeApi;
