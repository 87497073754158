import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Input, Select, Table } from "antd";
import "./style.scss";
import { PAGE_SIZE, tableLocale } from "../../utils/constants";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ApprovedEnum, UserClassify, UserData } from "../../utils/types/user";
import {
  useApproveMemberMutation,
  useGetMemberManagementListQuery,
  useUnapproveMemberMutation,
} from "../MemberManagement/memberManagementApi";
import { openModal } from "../../components/modal/modalSlice";
import { useDispatch } from "react-redux";
import { highlightKeywords } from "../../utils/commonFunc";
import { kitToastMessage } from "../../components/toastMessage";
import { truncateText } from "../../utils/commonBlock";

function MemberApproved() {
  const navigate = useNavigate();
  const submitBtnRef = useRef<any>(null);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [optionValue, setOptionValue] = useState<string>("0");
  const defaultFilterOptions = {
    userClassify: [UserClassify.Organ],
    value: "",
    page: 1,
    limit: PAGE_SIZE,
  };
  const [filterOptions, setfilterOptions] = useState<any>(defaultFilterOptions);

  useEffect(() => {
    if (searchValue.length === 0) {
      setfilterOptions(defaultFilterOptions);
    }
  }, [searchValue]);

  const { data, isLoading } = useGetMemberManagementListQuery(filterOptions, {
    refetchOnMountOrArgChange: true,
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRow(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const [approveMember] = useApproveMemberMutation();
  const [unapproveMember] = useUnapproveMemberMutation();

  const mapStatus = (value: number | null) => {
    let status, className;
    switch (value) {
      case 1:
        status = ApprovedEnum.APPROVE;
        className = "approved";
        break;
      case 2:
        status = ApprovedEnum.UNAPPROVE;
        className = "unapproved";
        break;
      case 3:
        status = ApprovedEnum.WAIT;
        className = "idle";
        break;
      default:
        status = ApprovedEnum.UNAPPROVE;
        className = "unapproved";
        break;
    }
    return { status, className };
  };

  const mapUserSpecify = (value: number) => {
    let role;
    switch (value) {
      case 1:
        role = "개인";
        break;
      case 2:
        role = "기업";
        break;
      case 3:
        role = "기관";
        break;
      default:
        role = "";
        break;
    }
    return role;
  };
  const columns = useMemo(() => {
    return [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
        render: (_: any, record: any, index: number) => {
          return index + 1 + (filterOptions?.page - 1) * 10;
        },
      },
      {
        title: "분류",
        dataIndex: "userClassify",
        key: "userClassify",
        width: "15%",
        render: (item: number) => {
          return mapUserSpecify(item);
        },
      },
      {
        title: "ID",
        dataIndex: "email",
        key: "email",
        width: "35%",
        render: (item: any) => {
          return truncateText("400px", { item, value: filterOptions.value });
        },
      },
      {
        title: "연락처",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        width: "30%",
        render: (item: string) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: highlightKeywords(item, searchValue),
              }}
            ></div>
          );
        },
      },
      {
        title: "상태",
        dataIndex: "approved",
        key: "approved",
        render: (item: any, record: any) => {
          return (
            <span
              className={`approve-status-area--${mapStatus(item).className}`}
            >
              {mapStatus(item).status}
            </span>
          );
        },
      },
      {
        title: "상세정보",
        width: 150,
        render: (_: any, record: any) => {
          return (
            <Button
              style={{ borderRadius: "5px" }}
              onClick={() =>
                navigate({
                  pathname: "view",
                  search: createSearchParams({
                    id: record.id,
                  }).toString(),
                })
              }
            >
              조회
            </Button>
          );
        },
      },
    ];
  }, [data, filterOptions]);

  const deactivedUser = selectedRow
    .filter((user: UserData) => user.approved === 2 || user.approved === null)
    .map((filterUser: UserData) => filterUser.id.toString());

  const idleUser = selectedRow
    .filter((user: UserData) => user.approved === 3)
    .map((filterUser: UserData) => filterUser.id.toString());

  const activedUser = selectedRow
    .filter((user: UserData) => user.approved === 1)
    .map((filterUser: UserData) => filterUser.id.toString());

  const handleAprroveConfirm = (type: string = "") => {
    if (type === "approve") {
      approveMember({
        users: selectedRow?.map((item: any) => item.id),
      })
        .unwrap()
        .then((res: any) => {
          const status = res.statusCode;
          if (status === 200 || status === 201) {
            setSelectedRow([]);
            setSelectedRowKeys([]);
            kitToastMessage("bottom", "승인 완료되었습니다.", "success");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (type === "unapprove") {
      unapproveMember({
        users: selectedRow?.map((item: any) => item.id),
      })
        .unwrap()
        .then((res: any) => {
          const status = res.statusCode;
          if (status === 200 || status === 201) {
            setSelectedRow([]);
            setSelectedRowKeys([]);
            kitToastMessage("bottom", "미승인 완료되었습니다. ", "success");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChangeOption = (value: string) => {
    setOptionValue(value);
    if (value === "0") {
      const { approved, ...rest } = filterOptions;
      setfilterOptions(rest);
    } else {
      setfilterOptions({ ...filterOptions, approved: +value });
    }
  };

  const handleUserApprove = () => {
    if (
      (activedUser.length > 0 && deactivedUser.length > 0) ||
      (activedUser.length > 0 && idleUser.length > 0) ||
      activedUser.length > 0
    ) {
      dispatch(
        openModal({
          template: "show-info",
          data: {
            info: "승인 완료된 항목이 포함되어 있습니다.",
            subInfo: "승인대기, 미승인 상태만 선택해 주세요.",
            type: "approved-page",
          },
        })
      );
    } else if (idleUser.length > 0 || deactivedUser.length > 0) {
      dispatch(
        openModal({
          template: "show-member-approved-confirm",
          data: {
            confirmText: "가입 승인 하시겠습니까?",
            isApproved: true,
          },
          actionConfirm: () => handleAprroveConfirm("approve"),
        })
      );
    } else {
      dispatch(
        openModal({
          template: "show-member-approved-confirm",
          data: {
            confirmText: "가입 승인 하시겠습니까?",
            isApproved: true,
          },
          actionConfirm: () => handleAprroveConfirm("approve"),
        })
      );
    }
  };

  const handleUserUnapprove = () => {
    if (
      idleUser.length > 0 &&
      activedUser.length === 0 &&
      deactivedUser.length === 0
    ) {
      dispatch(
        openModal({
          template: "show-member-approved-confirm",
          data: {
            confirmText: "가입 미승인하시겠습니까?",
            isApproved: false,
          },
          actionConfirm: () => handleAprroveConfirm("unapprove"),
        })
      );
    } else if (
      ((deactivedUser.length > 0 || activedUser.length > 0) &&
        idleUser.length > 0) ||
      (deactivedUser.length > 0 &&
        activedUser.length > 0 &&
        idleUser.length === 0)
    ) {
      dispatch(
        openModal({
          template: "show-info",
          data: {
            info: "승인 완료된 항목이 포함되어 있습니다.",
            subInfo: "승인대기 상태만 선택해 주세요.",
            type: "approved-page",
          },
        })
      );
    } else {
      dispatch(
        openModal({
          template: "show-member-approved-confirm",
          data: {
            confirmText: "가입 미승인하시겠습니까?",
            isApproved: false,
          },
          actionConfirm: () => handleAprroveConfirm("unapprove"),
        })
      );
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      submitBtnRef?.current?.click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="member-approved">
      <div className="member-approved__header">
        <div className="search-area">
          <p className="search-area--title">회원승인</p>
          <div className="search-input">
            <Select
              className="select-area"
              defaultValue={"0"}
              value={optionValue}
              options={[
                { value: "0", label: "전체" },
                { value: "1", label: "승인" },
                { value: "3", label: "승인대기" },
                { value: "2", label: "미승인" },
              ]}
              onChange={handleChangeOption}
            />
            <Input
              placeholder="검색어를 입력해 주세요."
              className="input-area"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button
              ref={submitBtnRef}
              className="btn-crud--primary btn-search"
              onClick={() =>
                setfilterOptions({
                  ...filterOptions,
                  value: searchValue.trim(),
                  page: 1,
                  limit: 10,
                })
              }
            >
              검색
            </Button>
          </div>
        </div>
        <div className="action-area">
          <Button
            className="btn-crud--primary btn-create"
            disabled={
              selectedRow.length < 1 ||
              (activedUser.length >= 1 &&
                deactivedUser.length === 0 &&
                idleUser.length === 0)
            }
            onClick={handleUserApprove}
          >
            승인
          </Button>
          <Button
            className="btn-crud--unapproved btn-create"
            disabled={selectedRow.length < 1 || idleUser.length === 0}
            onClick={handleUserUnapprove}
          >
            미승인
          </Button>
        </div>
      </div>
      <div className="member-approved__body">
        {!isLoading && filterOptions.value.length > 0 && (
          <div className="result-area">
            <div className="result-area__title">
              ‘{filterOptions.value}’&nbsp;
              <div className="result-area__sub-title">
                로 검색한 결과입니다.
              </div>
            </div>
            <div className="result-area__counter">
              총 {data?.data?.metaData?.totalRecords}건
            </div>
          </div>
        )}
        <Table
          locale={tableLocale}
          dataSource={data?.data?.result}
          pagination={
            data?.data?.metaData?.totalRecords > 10 && {
              position: ["bottomCenter"],
              defaultPageSize: PAGE_SIZE,
              total: data?.data?.metaData.totalRecords || 0,
            }
          }
          loading={isLoading}
          columns={columns}
          rowKey={(record: any) => record.id}
          rowSelection={rowSelection}
          onChange={(page: any, pageSize: any) => {
            setfilterOptions({
              ...filterOptions,
              value: searchValue,
              page: page?.current,
              limit: PAGE_SIZE,
            });
          }}
        />
      </div>
    </div>
  );
}

export default MemberApproved;
