export const getUserLogin = (state: any) => state.auth.isUserLogin;
export const getUserInfo = (state: any) => state.auth.userInfo;
// custom modal
export const modalStatus = (state: any) => state.modal.isOpen;
export const modalTemplate = (state: any) => state.modal.template;
export const modalData = (state: any) => state.modal.data;
export const modalWidth = (state: any) => state.modal.width;
export const modalHeight = (state: any) => state.modal.height;
export const modalConfirm = (state: any) => state.modal.actionConfirm;
export const modalCancel = (state: any) => state.modal.actionCancel;
