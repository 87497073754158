import React from "react";

export default function IconLocationOutlined() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5 10.0219C5 14.9431 9.2445 19.0127 11.1232 20.573C11.3921 20.7963 11.5281 20.9093 11.7287 20.9665C11.8849 21.0112 12.1148 21.0112 12.271 20.9665C12.472 20.9092 12.6071 20.7973 12.877 20.5731C14.7557 19.0128 19 14.9435 19 10.0223C19 8.15993 18.2625 6.37362 16.9498 5.05672C15.637 3.73982 13.8566 3 12.0001 3C10.1436 3 8.36303 3.73994 7.05026 5.05683C5.7375 6.37373 5 8.15948 5 10.0219Z"
        fill="#3E73F7"
      />
      <path
        d="M10 9.0858C10 10.2062 10.8955 11.1144 12 11.1144C13.1046 11.1144 14.0001 10.2062 14.0001 9.0858C14.0001 7.96544 13.1046 7.0572 12 7.0572C10.8955 7.0572 10 7.96544 10 9.0858Z"
        fill="#3E73F7"
      />
      <circle cx="12" cy="8" r="2" fill="white" />
    </svg>
  );
}
