import { useEffect, ChangeEvent, useState, useRef, useId } from "react";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "./style.scss";

interface AvatarUploadProps {
  initialValue: any;
  onChange: (file: any) => void;
  disabled?: boolean;
  className?: any;
  type?: string;
}

const AvatarUpload = ({
  initialValue,
  onChange,
  disabled,
  className,
  type,
}: AvatarUploadProps) => {
  const uid = useId();
  const inputRef = useRef<any>(null);
  const avatarUploader = document.getElementById("avatarUploader") as any;

  const [selectedFile, setSelectedFile] = useState(initialValue);
  const [previewUrl, setPreviewUrl] = useState(initialValue);
  const prefix = "kit-thumnail";
  const handleFileChange = (event: ChangeEvent<HTMLInputElement> | any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    onChange(file);
    if (file) {
      const reader: any = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setSelectedFile(initialValue);
    setPreviewUrl(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!selectedFile) {
      setPreviewUrl(null);
    }
  }, [selectedFile, selectedFile]);

  return (
    <div
      id={uid}
      className={`${prefix} ${className} ${
        type === "circle" && prefix.concat("--circle")
      }`}
      onClick={() => !disabled && inputRef.current.click()}
    >
      <input
        id="avatarUploader"
        ref={inputRef}
        className={`${prefix}-input`}
        type="file"
        accept="image/png,image/jpg"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {previewUrl && (
        <img
          className={`${prefix}-preview`}
          src={previewUrl}
          alt="Avatar Preview"
        />
      )}
      {!selectedFile && (
        <div
          className={
            type === "circle"
              ? `${prefix}__before-upload--circle`
              : `${prefix}__before-upload`
          }
        >
          <span>파일 첨부하기</span>{" "}
          <PlusCircleOutlined className="icon-plus" />
        </div>
      )}
      {selectedFile && (
        <div className={`${prefix}__after-upload`}>
          <img
            src={"/assets/images/icon-file-remove.svg"}
            onClick={(event) => {
              if (!disabled) {
                event.stopPropagation();
                setSelectedFile(null);
                setPreviewUrl(null);
                avatarUploader.value = "";
                onChange(null);
              }
            }}
            className="icon--remove"
          />
        </div>
      )}
    </div>
  );
};

export default AvatarUpload;
