import { useEffect, useState, useMemo, useRef } from "react";
import { Button, Input, Select, Table } from "antd";
import "./style.scss";
import { PAGE_SIZE, tableLocale } from "../../utils/constants";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useGetRecruitmentInformationListQuery } from "./recruitmentInfoApi";
import { highlightKeywords } from "../../utils/commonFunc";

function RecruitmentInfo() {
  const navigate = useNavigate();
  const [pageNum, setpageNum] = useState(1);
  const submitBtnRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [optionValue, setOptionValue] = useState<any>("0");
  const defaultFilterOptions = {
    value: searchValue,
    page: 1,
    limit: PAGE_SIZE,
  };
  const [filterOptions, setfilterOptions] = useState<any>(defaultFilterOptions);

  const { data, isLoading } = useGetRecruitmentInformationListQuery(
    filterOptions,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (searchValue.length === 0) {
      setfilterOptions(defaultFilterOptions);
    }
  }, [searchValue]);

  const mapUserSpecify = (value: string) => {
    let role;
    switch (value) {
      case "1":
        role = "개인";
        break;
      case "2":
        role = "기업";
        break;
      case "3":
        role = "기관";
        break;
      default:
        role = "";
        break;
    }
    return role;
  };

  const columns = useMemo(() => {
    return [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
        width: "10%",
        render: (item: any, record: any, index: number) => {
          return index + 1 + (filterOptions?.page - 1) * 10;
        },
      },
      {
        title: "체용 제목",
        dataIndex: "jobTitle",
        key: "jobTitle",
        width: "30%",
        render: (item: string) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: highlightKeywords(item, searchValue),
              }}
            ></div>
          );
        },
      },
      {
        title: "채용 기업",
        dataIndex: "companyName",
        key: "companyName",
        width: "30%",
        render: (item: any, record: any) => {
          return record?.innovationPlatform?.companyName;
        },
      },
      {
        title: "참여 프로그램",
        dataIndex: "participantProgram",
        key: "participantProgram",
        width: "30%",
      },
      {
        title: "상세정보",
        width: 200,
        render: (item: any, record: any) => {
          return (
            <Button
              className="btn-lookup"
              onClick={() =>
                navigate({
                  pathname: "view",
                  search: createSearchParams({
                    id: record.id,
                  }).toString(),
                })
              }
            >
              조회
            </Button>
          );
        },
      },
    ];
  }, [data, filterOptions]);

  const handleChangeOption = (value: string) => {
    setOptionValue(value);
    if (value === "0") {
      const { participantProgram, ...rest } = filterOptions;
      setfilterOptions(rest);
    } else {
      setfilterOptions({ ...filterOptions, participantProgram: [value] });
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      submitBtnRef?.current?.click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="recruitment-information">
      <div className="recruitment-information__header">
        <div className="search-area">
          <p className="search-area-title">채용정보</p>
          <div className="search-input">
            <Select
              className="select-area"
              defaultValue={"0"}
              value={optionValue}
              options={[
                { value: "0", label: "전체" },
                { value: "dmc500camp", label: "500camp" },
                { value: "innovationPlatform", label: "혁신플랫폼" },
              ]}
              onChange={handleChangeOption}
            />
            <Input
              className="input-area"
              placeholder="검색어를 입력해 주세요."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button
              ref={submitBtnRef}
              disabled={!searchValue}
              className="btn-crud--primary btn-search"
              onClick={() =>
                setfilterOptions({
                  ...filterOptions,
                  value: searchValue,
                  page: 1,
                  limit: 10,
                })
              }
            >
              검색
            </Button>
          </div>
        </div>
      </div>
      <div className="recruitment-information__body">
        {!isLoading && filterOptions.value.length > 0 && (
          <div className="result-area">
            <div className="result-area__title">
              ‘{filterOptions.value}’&nbsp;
              <div className="result-area__sub-title">
                로 검색한 결과입니다.
              </div>
            </div>
            <div className="result-area__counter">
              총 {data?.data?.metaData?.totalRecords}건
            </div>
          </div>
        )}
        <Table
          locale={tableLocale}
          dataSource={data?.data?.result}
          pagination={
            data?.data?.metaData?.totalRecords > 10 && {
              position: ["bottomCenter"],
              showSizeChanger: false,
              defaultPageSize: PAGE_SIZE,
              total: data?.data?.metaData.totalRecords || 0,
            }
          }
          loading={isLoading}
          columns={columns}
          rowKey={(record: any) => record.id}
          onChange={(page: any) => {
            if (page?.current !== pageNum) {
              setpageNum(page.current);
              setfilterOptions({
                ...filterOptions,
                value: searchValue,
                page: page?.current,
                limit: PAGE_SIZE,
              });
            }
          }}
        />
      </div>
    </div>
  );
}

export default RecruitmentInfo;
