import { Button, Tabs } from "antd";
import RCDetail from "../../rcDetail";
import "./style.scss";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { openModal } from "../../../../../components/modal/modalSlice";
import { kitToastMessage } from "../../../../../components/toastMessage";
import { useDeleteRecruitmentInformationMutation } from "../../../recruitmentInfoApi";
import { useMemo, useState } from "react";
import RCNotify from "../../rcNotify";

function RecruitmentInfoDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [deleteRecruitmentInformation] =
    useDeleteRecruitmentInformationMutation();
  const [currentActiveTabCount, setcurrentActiveTabCount] = useState(0);
  const handleDeletePost = async () => {
    try {
      const res: any = await deleteRecruitmentInformation({
        innovationId: searchParams.get("id"),
      }).unwrap();

      if (res.statusCode === 200 || res.statusCode === 201) {
        kitToastMessage("bottom", "삭제 완료되었습니다.", "success");
        navigate("/recruitment-information");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const operations = (
    <Button
      className="btn-crud--error"
      onClick={() => {
        dispatch(
          openModal({
            template: "show-confirm",
            data: {
              type: "delete",
              confirmText: "정말로 삭제하시겠습니까?",
            },
            actionConfirm: handleDeletePost,
          })
        );
      }}
    >
      삭제하기
    </Button>
  );

  const onCallback = (v) => {
    setcurrentActiveTabCount(v);
  };

  const tabTitleMemo = useMemo(() => {
    return (
      <div className="tab-title">
        지원자 현황{" "}
        <div className="tab-title--count">{currentActiveTabCount}</div>
      </div>
    );
  }, [currentActiveTabCount]);
  const parentTabOption = [
    {
      label: "채용공고",
      key: "채용공고",
      forceRender: true,
      children: <RCDetail />,
    },
    {
      label: tabTitleMemo,
      key: "지원자 현황",
      forceRender: true,
      children: <RCNotify onCallback={onCallback} />,
    },
  ];

  return (
    <div className="recruitment-agency-info-detail">
      <div className="recruitment-info__header">
        <div className="title-container">
          <p className="title">채용정보 상세</p>
        </div>
      </div>
      <div className="raid-tab">
        <Tabs tabBarExtraContent={operations} items={parentTabOption} />
      </div>
      <div className="rc-detail__footer">
        <Button className="btn--goback" onClick={() => navigate(-1)}>
          목록으로
        </Button>
      </div>
    </div>
  );
}

export default RecruitmentInfoDetail;
