export interface recruitmentInformation {}

export enum ERDetailTab {
  APPLICATION_RECEIVED = "지원서 접수",
  CV_PASS = "서류 통과",
  INTERVIEW_PASS = "최종 합격",
  FAILED = "불합격",
}

export interface IBasicFilter {
  page: number;
  limit?: number;
  order?: string;
  pageSize?: string;
  keyword?: string;
}
