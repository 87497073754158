import { Button } from "antd";
import "./style.scss";

export default function ShowInfo(props: any) {
  const { data, actionConfirm } = props;
  return (
    <div
      className={`show-info-wrapper ${
        data.type === "approved-page" && "show-info-wrapper-approved"
      } ${data.type === "popup--success" && "popup--success"}`}
    >
      <div className="title">{data.info}</div>
      {data.subInfo && <div className="sub-title">{data.subInfo}</div>}
      <Button
        className={`bt-confirm ${
          data.type === "popup--success" && "btn-confirm--success"
        }`}
        onClick={actionConfirm}
      >
        확인
      </Button>
    </div>
  );
}
