import React from "react";
import "./style.scss";

interface ICVTableProps {
  className?: string;
  columns?: { title: string; width: string; key: string }[];
  data?: any[];
}

function CVTable({ className, columns, data }: ICVTableProps) {
  return (
    <table className={`cv-table ${className && className}`}>
      <thead>
        <tr>
          {columns?.map((item) => {
            return <th className={item.width}>{item.title}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data?.map((item) => {
          return (
            <tr>
              {columns?.map((u, index) => {
                return <td className={item.width}>{item[u.key]}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default CVTable;
