import { Outlet } from "react-router-dom";
import Footer from "../footer";
import { useLocation } from "react-router-dom";
import LNB from "../header/LNB";
import { useGetCurrenUserInfoQuery } from "../../pages/Login/authApi";
import { TOKEN } from "../../utils/constants";
import { useEffect } from "react";
import Header from "../header";

function Layout() {
  const location = useLocation();
  const { data, isSuccess } = useGetCurrenUserInfoQuery(
    {},
    {
      skip: !localStorage.getItem(TOKEN),
    }
  );

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <div
        style={{
          background: location.pathname === "/auth" ? "#F7FAFD" : "#fff",
          height: "100vh",
          minWidth: 1440,
        }}
      >
        <Header />
        <div
          style={{
            display: "flex",
          }}
        >
          <LNB />
          <main className="main-content">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}

export default Layout;
