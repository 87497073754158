import React, { useEffect, useMemo, useRef, useState } from "react";
import "./style.scss";
import { Button, Form, Input, Radio, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { InnovationObj, ResidentForm } from "../@type";
import {
  cDaysReal,
  cMonthsReal,
  cYearsReal,
  listCarrierUpdate,
} from "../../../utils/selectOption";
import AvatarUpload from "../../../components/imageUpload";
import {
  useCreateResidentCompanyMutation,
  useGetCompanyInnoByBusinessNumMutation,
  useGetIndustryMutation,
  useGetResidentCompanyDetailQuery,
  useUpdateResidentCompanyMutation,
} from "../residentCompanyApi";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../../../components/modal/modalSlice";
import {
  useGetFileMutateMutation,
  useUploadFileMutation,
} from "../../../components/imageUpload/fileUploadApi";
import moment from "moment";
import { generateYearsInRange } from "../../../utils/commonFunc";

interface ResidentCompanyUpdateProps {
  type: "update" | "create" | "view";
}

function ResidentCompanyUpdate({ type }: ResidentCompanyUpdateProps) {
  const [form] = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const companyInputRef = useRef<any>(null);
  const companyRegistrationNumberInputRef = useRef<any>(null);
  const companyInfoRef = useRef<any>(null);

  const [listCarrierSubOne, setListCarrierSubOne] = useState([]);
  const [listCarrierSubTwo, setListCarrierSubTwo] = useState([]);
  const [businessCodeStatus, setBusinessCodeStatus] = useState({
    errors: "",
    success: "",
  });
  const isUpdate = type === "update";
  const isView = type === "view";
  const { TextArea } = Input;
  const [isSelectDisabled, setIsSelectDisabled] = useState(false);
  const [getIndustry] = useGetIndustryMutation();
  const [updateResidentCompany, { isLoading: updating }] =
    useUpdateResidentCompanyMutation();
  const [getCompanyInnoByBusinessNum] =
    useGetCompanyInnoByBusinessNumMutation();
  const [createResidentCompany, { isLoading: creating }] =
    useCreateResidentCompanyMutation();

  const [industryClassification2Option, setindustryClassification2Option] =
    useState([]);
  const [industryClassification3Option, setindustryClassification3Option] =
    useState([]);
  const [getFileMutate] = useGetFileMutateMutation();

  const [residentFiles, setResidentFiles] = useState<any>({
    logoFile: null,
    businessFile: null,
    infoFile: null,
    thumbnailFileOne: null,
    thumbnailFileTwo: null,
  });

  const initialValues: any = {
    address: "",
    detailAddress: "",
    zipcode: "",
    companyName: "",
    representativeName: "",
    representativeCode: "",
    labourNumber: "",
    career: "",
    detailCareer: "",
    foundationDate: "",
    homepage: "",
    businessCode: "",
    detailCareer2: "",
    enterpriseSize: "대기업",
    logoFiles: "",
    revenue2022: "",
    businessForm: "",
    keyword: "",
    description: "",
    services: "",
    detailService: "",
    // companyInfo: "",
    included: "입주기업",
    dmcType: "",
    product: "",
    picName: "",
    picPosition: "",
    phoneNumber: "",
    year: moment().year(),
    products: [
      {
        product: "",
        detailProduct: "",
        thumbnailId: undefined,
      },
      {
        product: "",
        detailProduct: "",
        thumbnailId: undefined,
      },
    ],
  };

  const [uploadFile] = useUploadFileMutation();

  const { data, isLoading, isSuccess } = useGetResidentCompanyDetailQuery(
    {
      id: searchParams.get("id"),
    },
    {
      skip: type === "create" || !searchParams.get("id"),
    }
  );

  const filesUpload = async (file: any) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append("files", file);
        const data = await uploadFile(formData).unwrap();
        return data?.data?.files[0]?.id;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onFinish = async (values: any) => {
    const files: any = {};
    const {
      year,
      month,
      day,
      included,
      enterpriseSize,
      products,
      revenue2022,
      ...rest
    } = values;

    const uploadFile = async (key: string, value: any) => {
      if (value) {
        const id = await filesUpload(value);
        files[key.concat("Id")] = id;
      }
    };

    await Promise.all(
      Object.entries(residentFiles)?.map(async ([key, value]) =>
        uploadFile(key, value)
      )
    );

    const mapProduct = (item: any, index: any) => {
      const { companyInfoFiles, thumbnail, ...rest } = item;
      return {
        thumbnailId:
          index < 2 ? files[`thumbnailFile${index + 1}Id`] : undefined,
        ...rest,
        product: products && products[index]?.product,
        detailProduct: products && products[index]?.detailProduct,
        id: data?.data?.products[index]?.id,
      };
    };

    const formatedProducts = data?.data?.products?.map(mapProduct);

    const createProduct = (item: any, index: any) => {
      const { companyInfoFiles, foundationDate, ...rest } = item;
      return {
        ...rest,
        thumbnailId:
          index < 2 ? files[`thumbnailFile${index + 1}Id`] : undefined,
      };
    };

    const createdProducts = products?.map(createProduct);

    const companySize = enterpriseSize;

    const includedStatus = {
      입주기업: "Included",
      비입주기업: "Not_Included",
    };

    const includedValue = includedStatus[included] || included;

    const updatedBody = {
      id: searchParams.get("id"),
      ...rest,
      businessCodeFiles: [files.businessFileId],
      logoFiles: [files.logoFileId],
      companyInfo: [files.infoFileId],
      companySize,
      products: type === "update" ? formatedProducts : createdProducts,
      included: includedValue,
      revenue2022: revenue2022 && revenue2022?.replaceAll(",", ""),
    };
    if (year && month && day) {
      updatedBody["foundationDate"] = `${year}-${month}-${day}`;
    }

    const handleUpdate = async () => {
      try {
        const res = await updateResidentCompany(updatedBody).unwrap();
        dispatch(
          openModal({
            template: "show-info",
            data: {
              info: "수정완료 되었습니다.",
            },
            actionConfirm: () =>
              navigate({
                pathname: "/resident-company/view",
                search: createSearchParams({
                  id: searchParams.get("id") as any,
                  type: "view",
                }).toString(),
              }),
          })
        );
      } catch (error) {}
    };

    const handleCreate = async () => {
      try {
        const res = await createResidentCompany(updatedBody).unwrap();
        navigate("/resident-company");
      } catch (error) {}
    };

    if (type === "update") {
      handleUpdate();
    }

    if (type === "create" && businessCodeStatus.success) {
      handleCreate();
    }
  };

  const handleFileUploader = (e: any, type?: string) => {
    const file = e.target.files;
    if (type === "UPLOAD_LOGO") {
      setResidentFiles({ ...residentFiles, logoFile: file[0] });
    } else if (type === "UPLOAD_BUSINESS") {
      setResidentFiles({ ...residentFiles, businessFile: file[0] });
    } else if (type === "UPLOAD_INFO") {
      setResidentFiles({ ...residentFiles, infoFile: file[0] });
    }
  };

  const handleSingleFileGetter = async (file: any) => {
    try {
      if (file?.id) {
        const res = await getFileMutate({ id: file?.id });
        const binaryDataBuffer = new Uint8Array(res.data.data.Body.data);
        const formatedFile = new File([binaryDataBuffer], file.originalName, {
          type: res.data.data.ContentType,
        });
        setResidentFiles({ ...residentFiles, businessFile: formatedFile });
      }
    } catch (error) {}
  };

  const handleTranformFile = async (files: any[]) => {
    try {
      let obj: any = {};
      await Promise.all(
        files?.map(async (item) => {
          const fileValue: any = Object.values(item)[0];
          const fileKey: any = Object.keys(item)[0];
          if (fileValue?.id) {
            const res = await getFileMutate({ id: fileValue?.id });
            const binaryDataBuffer = new Uint8Array(res.data.data.Body.data);
            const formatedFile = new File(
              [binaryDataBuffer],
              fileValue.originalName,
              {
                type: res.data.data.ContentType,
              }
            );
            obj = { ...obj, [fileKey]: formatedFile };
            setResidentFiles({ ...residentFiles, ...obj });
          }
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (isSuccess) {
      const {
        foundationDate,
        included,
        companySize,
        companyInfoFiles,
        businessCodeFiles,
        products,
        business,
        logoFiles,
        revenue2022,
        ...rest
      } = data?.data;

      const beforeTranformArr = [
        { infoFile: companyInfoFiles && companyInfoFiles[0] },
        { businessFile: businessCodeFiles && businessCodeFiles[0] },
        { logoFile: logoFiles && logoFiles[0] },
        {
          thumbnailFileOne: products?.length >= 1 && {
            id: products[0]?.thumbnail?.id,
            originalName: products[0]?.thumbnail?.originalName,
          },
        },
        {
          thumbnailFileTwo: products?.length >= 1 && {
            id: products[1]?.thumbnail?.id,
            originalName: products[1]?.thumbnail?.originalName,
          },
        },
      ];

      handleTranformFile(beforeTranformArr);

      // setResidentFiles({
      //   ...residentFiles,
      //   infoFile: companyInfoFiles && companyInfoFiles[0],
      //   businessFile: businessCodeFiles && businessCodeFiles[0],
      //   logoFile: logoFiles && logoFiles[0],
      // });

      const date = foundationDate?.split("-");
      form.setFieldsValue({
        ...rest,
        [ResidentForm.revenue2022]: revenue2022
          ? revenue2022?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0,
        year: date?.length > 0 && date[0],
        month: date?.length > 1 && date[1],
        day: date?.length > 2 && date[2],
        enterpriseSize: companySize,
        products: products,
        businessCode: business?.registrationNumber,
        included:
          included === "Included"
            ? "입주기업"
            : included === "Not_Included"
            ? "비입주기업"
            : included,
      });
    }
  }, [JSON.stringify(data), isSuccess]);

  const productFiles = useMemo(() => {
    if (data?.data) {
      return data?.data?.products?.map((item: any) => ({
        url: item?.thumbnail?.url,
      }));
    }
  }, [JSON.stringify(data), isSuccess]);

  function execDaumPostcode() {
    const wd: any = window;
    if (wd?.daum)
      new wd.daum.Postcode({
        oncomplete: function (data: any) {
          if (data?.zonecode) {
            form.setFieldValue(ResidentForm.zipcode, data.zonecode);
          }
          if (data?.address) {
            form.setFieldValue(ResidentForm.address, data.address);
          }
        },
      }).open();
  }

  const handleBusinessCodeCheck = async () => {
    const isFilled = form.getFieldValue(ResidentForm.businessCode)?.length > 0;
    try {
      const res: any = await getCompanyInnoByBusinessNum({
        businessCode: form.getFieldValue(ResidentForm.businessCode),
      }).unwrap();

      if (res?.statusCode === 200 && isFilled) {
        if (res?.data?.businessName) {
          const {
            businessName,
            career,
            firstCareer,
            secondCareer,
            industryClassification1,
            industryClassification2,
            industryClassification3,
            representativeName,
            foundationDate,
            included,
            phoneNumber,
            revenue2022,
            files,
            ...rest
          } = res?.data;

          const foundationDateConvert = foundationDate
            ? moment(foundationDate).format("YYYY-MM-DD")?.split("-")
            : "";

          await handleSingleFileGetter(files[0]);

          form.setFieldsValue({
            ...rest,
            [ResidentForm.companyName]: businessName,
            [ResidentForm.career]: career,
            [ResidentForm.detailCareer]: firstCareer,
            [ResidentForm.detailCareer2]: secondCareer,
            [ResidentForm.industryClassification1]: industryClassification1,
            [ResidentForm.industryClassification2]: industryClassification2,
            [ResidentForm.industryClassification3]: industryClassification3,
            [ResidentForm.representativeName]: representativeName,
            [ResidentForm.phoneNumber]: phoneNumber,
            [ResidentForm.revenue2022]: revenue2022
              ? revenue2022?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0,
            [ResidentForm.year]: foundationDateConvert[0]
              ? foundationDateConvert[0]
              : undefined,
            [ResidentForm.month]: foundationDateConvert[1]
              ? foundationDateConvert[1]
              : undefined,
            [ResidentForm.day]: foundationDateConvert[2]
              ? foundationDateConvert[2]
              : undefined,
            [ResidentForm.included]:
              included === "Included"
                ? "입주기업"
                : included === "Not_Included"
                ? "비입주기업"
                : included,
          });
        }

        setBusinessCodeStatus({
          errors: "",
          success: "사용가능한 사업자등록번호입니다.",
        });
      } else {
        setBusinessCodeStatus({
          errors: "이미 가입된 사업자등록번호입니다.",
          success: "",
        });
      }
    } catch (error: any) {
      setBusinessCodeStatus({
        errors: "이미 가입된 사업자등록번호입니다.",
        success: "",
      });
    }
  };

  const handleIndustryGetter = async (params: any) => {
    try {
      const res = await getIndustry(params).unwrap();
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="resident-company-update">
      <div
        className={`resident-company-update__header ${
          true ? "is-admin-page" : null
        }`}
      >
        <div className="title-container">
          <p className="title">
            {type === "create" ? "입주기업 등록" : "입주기업 정보 수정"}
          </p>
        </div>
      </div>
      <div className="resident-company-update__body">
        <div className="resident-company-update-wrapper">
          <Form
            name="create"
            className="resident-company-update__form"
            initialValues={initialValues}
            onFinish={onFinish}
            layout={"vertical"}
            onValuesChange={(values) => {
              if (values.included === "비입주기업") {
                setIsSelectDisabled(true);
              } else {
                setIsSelectDisabled(false);
              }
              if (Object.keys(values)[0] === ResidentForm.career) {
                const data: any = listCarrierUpdate.find(
                  (it: any) => it.value === Object.values(values)[0]
                );
                setListCarrierSubOne(data?.child);
              }
              if (Object.keys(values)[0] === ResidentForm.detailCareer) {
                const data: any = listCarrierSubOne.find(
                  (it: any) => it.value === Object.values(values)[0]
                );
                setListCarrierSubTwo(data?.secondChild);
              }

              if (
                Object.keys(values)[0] === ResidentForm.industryClassification1
              ) {
                handleIndustryGetter({
                  params: {
                    classifyA: form.getFieldValue(
                      ResidentForm.industryClassification1
                    ),
                  },
                }).then((res) => {
                  const code = res?.statusCode;
                  if (code === 200 || code === 201) {
                    const option = res.data?.map((item: any) => ({
                      value: item,
                      label: item,
                    }));
                    setindustryClassification2Option(option);
                  }
                });
              }
              if (
                Object.keys(values)[0] === ResidentForm.industryClassification2
              ) {
                handleIndustryGetter({
                  params: {
                    classifyA: form.getFieldValue(
                      ResidentForm.industryClassification1
                    ),
                    classifyB: form.getFieldValue(
                      ResidentForm.industryClassification2
                    ),
                  },
                }).then((res) => {
                  const code = res?.statusCode;
                  if (code === 200 || code === 201) {
                    const option = res.data?.map((item: any) => ({
                      value: item,
                      label: item,
                    }));
                    setindustryClassification3Option(option);
                  }
                });
              }
            }}
            form={form}
          >
            <div className="form__header">
              <Form.Item className="form-item form-item__btn">
                <Button
                  className={`btn--submit`}
                  type="primary"
                  htmlType="submit"
                >
                  수정완료
                </Button>
              </Form.Item>
            </div>
            <div className="form__content">
              <div className="form__content-title">기업 정보</div>
              <div className="form__content-info">
                <div className="form__content-info__left">
                  <Form.Item
                    className="form-item form-item-checkBusiness"
                    label="사업자등록번호"
                    name={ResidentForm.businessCode}
                    rules={[{ required: true, message: "Required field." }]}
                  >
                    <div className="form-item__uploader">
                      <Form.Item name={ResidentForm.businessCode}>
                        <Input
                          disabled={isUpdate}
                          className="ip"
                          placeholder="-없이 입력해주세요."
                        />
                      </Form.Item>
                      {type !== "update" && (
                        <Button
                          disabled={isUpdate}
                          className="form-item__upload-btn"
                          onClick={handleBusinessCodeCheck}
                        >
                          파일 첨부
                        </Button>
                      )}
                    </div>
                    <div className="form-item-area--status">
                      {businessCodeStatus?.success && (
                        <div className="block--success">
                          {businessCodeStatus?.success}
                        </div>
                      )}
                      {businessCodeStatus?.errors && (
                        <div className="block--errors">
                          {businessCodeStatus?.errors}
                        </div>
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item
                    className="form-item"
                    label="기업명"
                    name={ResidentForm.companyName}
                    rules={[{ required: true, message: "Required field." }]}
                  >
                    <Input
                      disabled={isUpdate}
                      placeholder="참여대상을 입력해 주세요."
                    />
                  </Form.Item>
                  <Form.Item
                    className="form-item form-item__upload"
                    label="회사 로고 첨부"
                    name={ResidentForm.logoFiles}
                  >
                    <div className="form-item__uploader">
                      <Input
                        className="ip"
                        placeholder="회사 로고를 업로드하세요."
                        value={
                          residentFiles?.logoFile?.name ||
                          residentFiles?.logoFile?.originalName
                        }
                      />
                      <input
                        ref={companyInputRef}
                        type="file"
                        multiple
                        id="actual-btn"
                        accept=".png,.gif,.jpg,.jpeg"
                        onChange={(e) => handleFileUploader(e, "UPLOAD_LOGO")}
                        style={{
                          display: "none",
                        }}
                      />
                      <Button
                        className="form-item__upload-btn"
                        onClick={() => companyInputRef?.current?.click()}
                      >
                        파일 첨부
                      </Button>
                    </div>
                  </Form.Item>
                  <Form.Item
                    className="form-item"
                    label="대표자명"
                    name={ResidentForm.representativeName}
                    rules={[{ required: true, message: "Required field." }]}
                  >
                    <Input
                      // disabled={isUpdate}
                      placeholder="참여대상을 입력해 주세요."
                    />
                  </Form.Item>
                  <div className="form-group__select--horizontal">
                    <span className="form-group__select-title--required">
                      설립일
                    </span>
                    <div className="select--horizontal-wrapper">
                      <Form.Item
                        className="form-item"
                        name={ResidentForm.year}
                        // rules={[{ required: true, message: "Required field." }]}
                      >
                        <Select
                          // disabled={isUpdate}
                          placeholder="년도"
                          className="form-item__select"
                          options={generateYearsInRange("1900")}
                        />
                      </Form.Item>
                      <Form.Item
                        className="form-item"
                        name={ResidentForm.month}
                        // rules={[{ required: true, message: "Required field." }]}
                      >
                        <Select
                          // disabled={isUpdate}
                          placeholder="월"
                          className="form-item__select"
                          options={cMonthsReal}
                        />
                      </Form.Item>
                      <Form.Item
                        className="form-item"
                        name={ResidentForm.day}
                        // rules={[{ required: true, message: "Required field." }]}
                      >
                        <Select
                          // disabled={isUpdate}
                          placeholder="일"
                          className="form-item__select"
                          options={cDaysReal}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item
                    className="form-item"
                    label="대표 번호"
                    name={ResidentForm.phoneNumber}
                    rules={[{ required: true, message: "Required field." }]}
                  >
                    <Input
                      // disabled={isUpdate}
                      placeholder="참여대상을 입력해 주세요."
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    className="form-item"
                    label="사업자등록증 첨부"
                    name={ResidentForm.businessCodeFiles}
                  >
                    <div className="form-item__uploader">
                      <Input
                        disabled={isUpdate}
                        className="ip"
                        placeholder="회사 로고를 업로드하세요."
                        value={
                          residentFiles?.businessFile?.name ||
                          residentFiles?.businessFile?.originalName
                        }
                      />
                      <input
                        ref={companyRegistrationNumberInputRef}
                        type="file"
                        multiple
                        id="actual-btn"
                        // accept=".png,.gif,.jpg,.jpeg"
                        onChange={(e) =>
                          handleFileUploader(e, "UPLOAD_BUSINESS")
                        }
                        style={{
                          display: "none",
                        }}
                      />
                      {type !== "update" && (
                        <Button
                          className="form-item__upload-btn"
                          onClick={() =>
                            !isUpdate &&
                            companyRegistrationNumberInputRef?.current?.click()
                          }
                        >
                          파일 첨부
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                  <div className="form-group__zipcode">
                    <Form.Item
                      className="form-item  form-item-zipcode-wrapper"
                      label="기관 주소"
                    >
                      <div className="form-item__uploader">
                        <Form.Item
                          className="form-item form-item-zipcode"
                          name={ResidentForm.zipcode}
                          rules={[
                            { required: true, message: "필드는 필수입니다!" },
                          ]}
                        >
                          <Input
                            // disabled
                            className="ip"
                            placeholder="우편번호"
                          />
                        </Form.Item>
                        {/* {type !== "update" && ( */}
                        <Button
                          className="form-item__upload-btn"
                          onClick={execDaumPostcode}
                        >
                          주소 검색
                        </Button>
                        {/* )} */}
                      </div>
                    </Form.Item>
                    <Form.Item
                      name={ResidentForm.address}
                      className="form-item"
                      rules={[
                        { required: true, message: "필드는 필수입니다!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={ResidentForm.detailAddress}
                      className="form-item"
                      rules={[
                        { required: true, message: "필드는 필수입니다!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className="form__content-info__right">
                  <div className="form-group__select--vertical">
                    <span className="form-group__select-title--required">
                      업종
                    </span>
                    <Form.Item className="form-item" name={ResidentForm.career}>
                      <Select
                        placeholder="년도"
                        className="form-item__select"
                        onChange={() => {
                          form.setFieldsValue({
                            [ResidentForm.detailCareer]: "",
                            [ResidentForm.detailCareer2]: "",
                          });
                        }}
                        options={listCarrierUpdate}
                      />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name={ResidentForm.detailCareer}
                    >
                      <Select
                        placeholder="월"
                        className="form-item__select"
                        onChange={() => {
                          form.setFieldsValue({
                            [ResidentForm.detailCareer2]: "",
                          });
                        }}
                        options={listCarrierSubOne}
                      />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name={ResidentForm.detailCareer2}
                    >
                      <Select
                        placeholder="일"
                        className="form-item__select"
                        options={listCarrierSubTwo}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    className="form-item"
                    label="2022년도 매출액(천원)"
                    name={ResidentForm.revenue2022}
                    rules={[{ required: true, message: "필드는 필수입니다!" }]}
                  >
                    <Input
                      onKeyDown={(event) => {
                        if (
                          !/[0-9]/.test(event.key) &&
                          event.key !== "Backspace"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="전년도 매출액을 입력하세요"
                      onChange={(e) => {
                        if (e?.target?.value?.length > 3) {
                          const { value } = e.target;
                          const formattedValue = value
                            .replace(/\D/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          form.setFieldValue(
                            ResidentForm.revenue2022,
                            formattedValue
                          );
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="form-item"
                    label="근로자 수"
                    name={ResidentForm.labourNumber}
                    rules={[{ required: true, message: "필드는 필수입니다!" }]}
                  >
                    <Input
                      // disabled={isUpdate}
                      placeholder="근로자 수를 입력하세요"
                    />
                  </Form.Item>
                  <Form.Item
                    className="form-item"
                    label="기업 규모"
                    name={ResidentForm.enterpriseSize}
                  >
                    <Radio.Group defaultValue="대기업">
                      <Radio value="대기업">대기업</Radio>
                      <Radio value="중견기업">중견기업</Radio>
                      <Radio value="중소기업">중소기업</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    className="form-item"
                    label="회사 홈페이지"
                    name={ResidentForm.homepage}
                  >
                    <Input placeholder="url을 입력해주세요." />
                  </Form.Item>
                  <Form.Item
                    className="form-item"
                    label="DMC 입주기업 여부"
                    name={ResidentForm.included}
                  >
                    <Radio.Group defaultValue="입주기업">
                      <Radio value="입주기업">입주기업</Radio>
                      <Radio value="비입주기업">비입주기업</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <div className="form-group__select--vertical">
                    <span className="form-group__select-title--required">
                      산업분류
                    </span>
                    <Form.Item
                      className="form-item"
                      name={ResidentForm.industryClassification1}
                    >
                      <Select
                        placeholder="년도"
                        className="form-item__select"
                        onChange={() => {
                          form.setFieldsValue({
                            [ResidentForm.industryClassification2]: "",
                            [ResidentForm.industryClassification3]: "",
                          });
                        }}
                        disabled={isSelectDisabled}
                        options={[
                          {
                            value: "M&E",
                            label: "M&E",
                          },
                          {
                            value: "IT&SW",
                            label: "IT&SW",
                          },
                          {
                            value: "기타",
                            label: "기타",
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name={ResidentForm.industryClassification2}
                    >
                      <Select
                        disabled={isSelectDisabled}
                        placeholder="월"
                        className="form-item__select"
                        onChange={() => {
                          form.setFieldsValue({
                            [ResidentForm.industryClassification3]: "",
                          });
                        }}
                        options={industryClassification2Option}
                      />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name={ResidentForm.industryClassification3}
                    >
                      <Select
                        disabled={isSelectDisabled}
                        placeholder="일"
                        className="form-item__select"
                        options={industryClassification3Option}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="form__content-additional-info">
                <div className="form__content-additional-info-title">
                  추가정보
                </div>
                <div className="form__content-additional-info-content">
                  <Form.Item
                    className="form-item"
                    name={ResidentForm.keyword}
                    label="주요키워드"
                  >
                    <Input placeholder="참여대상을 입력해 주세요." />
                  </Form.Item>
                  <Form.Item
                    className="form-item form-item__upload"
                    label="회사 소개 자료 첨부"
                  >
                    <div className="form-item__uploader">
                      <Input
                        className="ip"
                        placeholder="회사 로고를 업로드하세요."
                        value={
                          residentFiles?.infoFile?.name ||
                          residentFiles?.infoFile?.originalName
                        }
                      />
                      <input
                        ref={companyInfoRef}
                        type="file"
                        multiple
                        id="actual-btn"
                        accept=".png,.gif,.jpg,.jpeg"
                        onChange={(e) => handleFileUploader(e, "UPLOAD_INFO")}
                        style={{
                          display: "none",
                        }}
                      />
                      <Button
                        className="form-item__upload-btn"
                        onClick={() => companyInfoRef?.current?.click()}
                      >
                        파일 첨부
                      </Button>
                    </div>
                  </Form.Item>
                  <Form.Item
                    className="form-item"
                    label="회사 소개"
                    name={ResidentForm.description}
                  >
                    <TextArea placeholder="참여대상을 입력해 주세요." />
                  </Form.Item>
                  <div className="form-group__main-product">
                    <div className="form-group__main-product-title">
                      주요제품/서비스
                    </div>
                    <Form.List name={ResidentForm.products}>
                      {(fields) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => {
                            return (
                              <>
                                <div
                                  className="form-group__main-product-content"
                                  key={key}
                                >
                                  <div className="company-desc">
                                    <Form.Item
                                      {...restField}
                                      className="form-item form-item__main-product"
                                      label="사업분야명"
                                      name={[name, ResidentForm.product]}
                                    >
                                      <Input
                                        className="main__product-input"
                                        placeholder="사업분야명을 입력해주세요"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="company-info">
                                    <Form.Item
                                      {...restField}
                                      name={[
                                        name,
                                        ResidentForm.companyInfoFiles,
                                      ]}
                                      label="아이콘 썸네일"
                                      className="date form-input"
                                    >
                                      <AvatarUpload
                                        type="circle"
                                        disabled={false}
                                        initialValue={
                                          key === 0
                                            ? productFiles &&
                                              productFiles[0]?.url
                                            : productFiles &&
                                              productFiles[1]?.url
                                        }
                                        onChange={(files) => {
                                          setResidentFiles({
                                            ...residentFiles,
                                            [key === 0
                                              ? "thumbnailFileOne"
                                              : "thumbnailFileTwo"]: files,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      className="form-item"
                                      name={[name, ResidentForm.detailProduct]}
                                    >
                                      <Input placeholder="주요제품/서비스, 브랜드 또는 사업 분야에 대한 설명을 기재해주세요.(200자 이내)" />
                                    </Form.Item>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    </Form.List>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ResidentCompanyUpdate;
