import { Button, Input, Table } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { DATE_TIME, PAGE_SIZE, tableLocale } from "../../../utils/constants";
import { IDMCType, MainProps } from "../@type";
import {
  useGetNoticeListQuery,
  useLazyGetNoticeDetailQuery,
} from "./annoucementApi";
import "./style.scss";

function NoticeMain({ dmcType }: MainProps) {
  const navigate = useNavigate();
  const submitBtnRef = useRef<any>(null);
  const [pageNum, setpageNum] = useState(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [listNoticeState, setListNoticeState] = useState<any>([]);
  const defaultFilterOptions = {
    searchKey: "",
    dmcType: dmcType,
    page: 1,
    take: PAGE_SIZE,
  };
  const [filterOptions, setfilterOptions] = useState(defaultFilterOptions);

  const { data, isLoading, isSuccess } = useGetNoticeListQuery(filterOptions, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (searchValue.length === 0) {
      setfilterOptions(defaultFilterOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setfilterOptions({ ...defaultFilterOptions, dmcType: dmcType });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dmcType]);

  const columns = [
    {
      title: "번호",
      dataIndex: "id",
      key: "id",
      width: "20%",
      render: (item: any, record: any, index: number) => {
        return index + 1 + (filterOptions?.page - 1) * PAGE_SIZE;
      },
    },
    {
      title: "제목",
      dataIndex: "title",
      key: "title",
      width: "45%",
      // render: (item: any) => {
      //   return truncateText("400px", { item, value: filterOptions.value });
      // },
    },
    {
      title: "등록일",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "25%",
      render: (item: any) => {
        return moment(item).format(DATE_TIME);
      },
    },
    {
      title: "상세정보",
      width: "15%",
      render: (item: any, record: any) => {
        return (
          <Button
            className="btn_action"
            onClick={() =>
              navigate({
                pathname: "action",
                search: createSearchParams({
                  id: record.id,
                  type: "view",
                }).toString(),
              })
            }
          >
            조회
          </Button>
        );
      },
    },
  ];

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      submitBtnRef?.current?.click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    setListNoticeState(data);
  }, [data]);

  return (
    <div className="annoucement">
      <div className="annoucement__header">
        <div className="search-area">
          <div className="header">
            {dmcType === IDMCType.V2 ? (
              <>
                <p className="title">DMC 500캠프</p> -{" "}
                <p className="sub-title">공지사항</p>
              </>
            ) : (
              <>
                <p className="title">일자리 매칭데이</p> -{" "}
                <p className="sub-title">공지사항</p>
              </>
            )}
          </div>
          <div className="search-input">
            <Input
              className="input-area"
              placeholder="검색어를 입력해 주세요."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button
              ref={submitBtnRef}
              className="btn-crud--primary btn-search"
              onClick={() =>
                setfilterOptions({
                  ...filterOptions,
                  searchKey: searchValue?.trim(),
                  page: 1,
                  take: 10,
                })
              }
            >
              검색
            </Button>
          </div>
        </div>
        <div className="action-area">
          <Button
            className="btn-crud--primary btn-create"
            onClick={() =>
              navigate({
                pathname: "action",
                search: createSearchParams({
                  type: "create",
                }).toString(),
              })
            }
          >
            작성하기
          </Button>
        </div>
      </div>
      {!isLoading && filterOptions.searchKey.length > 0 && (
        <div className="result-area">
          <div className="result-area__title">
            ‘{filterOptions.searchKey}’&nbsp;
            <div className="result-area__sub-title">로 검색한 결과입니다.</div>
          </div>
          <div className="result-area__counter">
            총 {listNoticeState?.data?.totalCount}건
          </div>
        </div>
      )}
      <div className="annoucement__body">
        <Table
          locale={tableLocale}
          dataSource={
            isSuccess
              ? listNoticeState?.data?.items?.map(
                  (item: any, keyId: number) => ({
                    ...item,
                    title: !!searchValue.length ? (
                      <>
                        {item.title}
                        <span className="hightlight_searchkey">
                          &nbsp;{searchValue}
                        </span>
                      </>
                    ) : (
                      item.title
                    ),
                    currentId: keyId + 1 + (pageNum - 1) * 10,
                  })
                )
              : []
          }
          pagination={
            listNoticeState?.data?.totalCount > 10 && {
              position: ["bottomCenter"],
              defaultPageSize: PAGE_SIZE,
              total: listNoticeState?.data?.totalCount || 0,
            }
          }
          loading={isLoading}
          columns={columns}
          rowKey={(record: any) => record.id}
          onChange={(page: any, pageSize: any) => {
            if (page?.current !== pageNum) {
              setpageNum(page.current);
              setfilterOptions({
                ...filterOptions,
                searchKey: searchValue,
                page: page?.current,
                take: PAGE_SIZE,
              });
            }
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                navigate({
                  pathname: "action",
                  search: createSearchParams({
                    type: "view",
                    id: record.id,
                  }).toString(),
                });
              },
            };
          }}
        />
      </div>
    </div>
  );
}

export default NoticeMain;
