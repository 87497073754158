/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const innovationV3PlatformApi = createApi({
  reducerPath: "InnovationV3Platform",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;
      console.log(getState());

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["InnovationV3Platform", "LIST_NOTICE"],
  endpoints: (builder) => ({
    getInnovationV3Platform: builder.query({
      query: (data) => ({
        url: `innovation/v3/platform`,
        method: "GET",
        params: data,
      }),
      providesTags: ["InnovationV3Platform"],
    }),
    updateStatusInnov: builder.mutation({
      query: (data) => ({
        url: `innovation/v3/platform/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["InnovationV3Platform"],
    }),
  }),
});

export const {
  useGetInnovationV3PlatformQuery,
  useUpdateStatusInnovMutation,
} = innovationV3PlatformApi;
