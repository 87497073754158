import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState, useRef, useMemo, useEffect } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { API_LINK, KEY_EDITOR, TOKEN } from "../../../../utils/constants";
import "./style.scss";
import MultipleUpload from "../../../../components/multipleUpload";
import {
  useGetFileMutateMutation,
  useUploadFileMutation,
} from "../../../../components/imageUpload/fileUploadApi";

import { useDispatch } from "react-redux";
import { openModal } from "../../../../components/modal/modalSlice";
import { kitToastMessage } from "../../../../components/toastMessage";
import { CommunicationType } from "../../../../utils/types/table";
import {
  useCreateEventMutation,
  useDeleteEventMutation,
  useGetEventDetailQuery,
  useUpdateEventMutation,
} from "../../eventApi";

function EventAction() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorRef = useRef<any>(null);
  const [fileUrl, setFileUrl] = useState<any>([]);
  const [form] = useForm();
  const initialValues = { title: "", file: "", description: "" };
  const [uploadFile] = useUploadFileMutation();
  const [filesArr, setFilesArr] = useState<any>([]);
  const isUpdatePage = searchParams.get("type") === "update";
  const isCreatePage = searchParams.get("type") === "create";
  const isViewPage = searchParams.get("type") === "view";

  const { data, isLoading, isSuccess } = useGetEventDetailQuery(
    {
      id: searchParams.get("id"),
    },
    { skip: !searchParams.get("id") || isCreatePage }
  );

  const [createEvent] = useCreateEventMutation();
  const [updateEvent] = useUpdateEventMutation();
  const [deleteEvent] = useDeleteEventMutation();
  const [getFileMutate] = useGetFileMutateMutation();
  const handleFilUploadChange = (files: any[]) => {
    setFileUrl(Array.from(files));
  };
  const handleExistFilesList = async (data: any) => {
    try {
      let arr: any = [];
      await Promise.all(
        data.map(async (file: any) => {
          const res = await getFileMutate({ id: file.id });
          const binaryDataBuffer = new Uint8Array(res.data.data.Body.data);
          const formatedFile = new File([binaryDataBuffer], file.originalName, {
            type: res.data.data.ContentType,
          });
          arr.push(formatedFile);
        })
      );
      setFilesArr(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const {
        updatedAt,
        id,
        deletedAt,
        createdAt,
        recruitmentCalendar,
        ...rest
      } = data.data;
      if (isViewPage || isUpdatePage) {
        handleExistFilesList(data.data.files);
      }
      form.setFieldsValue({ ...rest });
    }
  }, [data?.data, isSuccess]);

  const handleUploadFile = async () => {
    try {
      const formData = new FormData();
      fileUrl?.map((file: any) => {
        formData.append("files", file);
      });
      const data = await uploadFile(formData).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleAnnoucementCreate = async (body: any) => {
    try {
      const data = await createEvent(body).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleModalClose = () => {
    navigate({
      pathname: "",
      search: createSearchParams({
        id: searchParams.get("id") as any,
        type: "view",
      }).toString(),
    });
  };

  const handleAnnoucementEdit = async (body: any) => {
    try {
      const data = await updateEvent(body).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    const uploadFileRes = await handleUploadFile();
    const attachFileIdArray = uploadFileRes?.data?.files.map(
      (item: any) => item.id
    );

    const { thumbnail, file, recruitmentCalendar, ...rest } = values;
    if (isUpdatePage) {
      const body = {
        ...rest,
        files: attachFileIdArray || [],
        content: editorRef.current.getContent(),
        id: searchParams.get("id"),
      };
      const response = await handleAnnoucementEdit(body);
      if (response.statusCode === 200 || response.statusCode === 201) {
        dispatch(
          openModal({
            template: "show-info",
            data: {
              info: "작성완료 되었습니다.",
            },
            actionConfirm: () =>
              navigate({
                pathname: "",
                search: createSearchParams({
                  id: response?.data?.id,
                  type: "view",
                }).toString(),
              }),
          })
        );
      }
    }
    if (isCreatePage) {
      const body = {
        ...rest,
        files: attachFileIdArray || [],
        content: editorRef.current.getContent(),
      };

      const response = await handleAnnoucementCreate(body);

      if (response.statusCode === 200 || response.statusCode === 201) {
        dispatch(
          openModal({
            template: "show-info",
            data: {
              info: "작성완료 되었습니다.",
            },
            actionConfirm: () =>
              navigate({
                pathname: "",
                search: createSearchParams({
                  id: response?.data?.id,
                  type: "view",
                }).toString(),
              }),
          })
        );
      }
    }
  };

  const uploadImageHandler = (file: any, progress: any) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("file", file.blob());
        // formData.append("fileName", file.name);
        const res = await fetch(API_LINK + "files", {
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
          },
          body: formData,
        })
          .then((res: any) => res.json())
          .catch((error) => error.json());
        if (res.statusCode > 299) {
          return reject(res);
        }
        return resolve(res?.data?.files[0]?.url);
      } catch (error) {
        return reject("Something went wrong!");
      }
    });

  const handleCallApiDelete = async () => {
    try {
      const res: any = await deleteEvent({
        id: searchParams.get("id"),
      }).unwrap();

      if (res.statusCode === 200 || res.statusCode === 201) {
        kitToastMessage("bottom", "삭제 완료되었습니다.", "success");
        navigate("/event");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRecordDelete = () => {
    dispatch(
      openModal({
        template: "show-confirm",
        data: {
          type: "delete",
          model: "dmc3",
          confirmText: "정말로 삭제하시겠습니까?",
        },
        actionConfirm: handleCallApiDelete,
        actionCancel: handleModalClose,
      })
    );
  };

  return (
    <div className="annoucement-action">
      <div className="annoucement-action-top-wrapper">
        <div className="title-group">
          <p className="title">참여소통</p> <span className="separate">-</span>{" "}
          <p className="sub-title">이벤트</p>
        </div>
        {isCreatePage || isUpdatePage ? (
          <Button className="btn--complete" onClick={() => form.submit()}>
            작성완료
          </Button>
        ) : (
          <div className="action-group">
            <Button
              className="btn--edit"
              onClick={() =>
                navigate({
                  pathname: "",
                  search: createSearchParams({
                    id: searchParams.get("id") as any,
                    type: "update",
                  }).toString(),
                })
              }
            >
              수정하기
            </Button>
            <Button className="btn--delete" onClick={handleRecordDelete}>
              삭제하기
            </Button>
          </div>
        )}
      </div>
      <div className="annoucement-action-body">
        <div className="form-container">
          <Form
            name="create"
            className="annoucement-action__form"
            initialValues={initialValues}
            onFinish={onFinish}
            layout={"vertical"}
            form={form}
          >
            <Form.Item label="제목" name="title" className="title form-input">
              <Input
                disabled={isViewPage}
                placeholder="제목을 입력해 주세요."
              />
            </Form.Item>
            <Form.Item label="첨부파일 (선택)" name="file" className="file">
              <MultipleUpload
                initialValue={filesArr}
                isDownload={isViewPage}
                multiple
                disabled={isViewPage}
                onChange={handleFilUploadChange}
              />
            </Form.Item>
            <Form.Item
              label="신청 링크"
              name="registrationLink"
              className="title form-input"
            >
              <Input
                disabled={isViewPage}
                placeholder="신청 링크 주소를 입력해 주세요."
              />
            </Form.Item>
            <Form.Item label="내용" name="content" className="title form-input">
              <Editor
                disabled={isViewPage}
                scriptLoading={{
                  async: true,
                }}
                value={form.getFieldValue("content")}
                onInit={(_: any, editor: any) => (editorRef.current = editor)}
                apiKey={KEY_EDITOR}
                onEditorChange={(e) => {
                  form.setFieldValue("content", e);
                }}
                plugins="advlist autolink lists link image charmap preview
                            searchreplace visualblocks code fullscreen 
                               paste code help table"
                ref={editorRef}
                init={{
                  height: 500,
                  placeholder: "내용을 입력해 주세요.",
                  toolbar:
                    " formatselect | undo redo bold italic backcolor forecolor| \
                    alignleft aligncenter alignright alignjustify| \
                    bullist numlist outdent indent removeformat| image video",
                  language: "ko_KR",
                  images_upload_handler: uploadImageHandler,
                  branding: false,
                }}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default EventAction;
