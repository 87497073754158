import "./style.scss";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMemo, useState } from "react";
import moment from "moment";
import DetailBlock from "../../../../components/commonBlock";
import { formatPhoneNumber } from "../../../../utils/commonFunc";
import RLRow from "../RLRow";
import {
  useGetCvDetailQuery,
  useGetDetailUserQuery,
} from "../../talentInfoApi";
import CVDetail from "../CV";

function TalentInfoDetail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isCVVisible, setIsCVVisible] = useState(false);

  const user = useSelector((state: any) => state.auth.userInfo);

  const dispatch = useDispatch();

  const { data, isSuccess, refetch } = useGetCvDetailQuery(
    {
      id: searchParams.get("id"),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: detailUser } = useGetDetailUserQuery(data?.data?.userId, {
    skip: !data?.data?.userId,
    refetchOnMountOrArgChange: true,
  });

  console.log(detailUser);

  const handleBlockClick = async (type, value) => {
    if (type === "show-resume") {
      setIsCVVisible(true);
    }
  };

  const isPersonalized =
    data?.data?.userClassify === 1 || data?.data?.userClassify === null;
  const isEnterprises = data?.data?.userClassify === 2;
  const isOrgan = data?.data?.userClassify === 3;
  const userStatus = data?.data?.status;

  const memberInfo = useMemo(() => {
    if (isSuccess) {
      const { userClassify, picName, createdAt, email, phoneNumber } =
        data.data;

      let arr = [
        {
          title: "이름",
          desc: data?.data?.user?.picName,
        },
        {
          title: "연락처",
          desc: formatPhoneNumber(data?.data?.user?.phoneNumber),
        },
        {
          title: "이메일",
          desc: data?.data?.user?.email,
        },
      ];

      if (isPersonalized) {
        arr = [
          ...arr,
          {
            title: "이름",
            desc: picName,
          },
          {
            title: "연락처",
            desc: formatPhoneNumber(phoneNumber),
          },
        ];
      }

      return arr;
    }
    return [];
  }, [isSuccess]);

  return (
    <div className="talent-info-detail">
      <div
        className={`talent-info-detail__header ${
          true ? "is-admin-page" : null
        }`}
      >
        <div className="title-container">
          <p className="title">인재정보 정보 상세</p>
        </div>
      </div>

      <div className="talent-info-detail__body">
        <div className="body-left">
          <DetailBlock
            userStatus={userStatus}
            title="인재 정보"
            content={memberInfo}
            className={{ paddingBottom: "40px" }}
          />
        </div>
        <div className="body-right"></div>
      </div>
      <div className="talent-info-detail__body-2">
        <div className="body-2__title">이력서 정보</div>
        <div className="body-2__wrapper">
          {detailUser?.data?.userCVs?.map((item) => {
            return (
              <RLRow data={item} type="offical" onClick={handleBlockClick} />
            );
          })}
        </div>
      </div>
      <div className="talent-info-detail__footer">
        <Button className="btn--goback" onClick={() => navigate(-1)}>
          목록으로
        </Button>
      </div>
      {isCVVisible && (
        <Modal
          onCancel={() => setIsCVVisible(false)}
          title=""
          width={1200}
          wrapClassName="modal-accept-offer-wrapper"
          open={isCVVisible}
          footer={null}
        >
          <CVDetail />
        </Modal>
      )}
    </div>
  );
}

export default TalentInfoDetail;
