/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const fundServiceApi = createApi({
  reducerPath: "fundService",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    },
  }),
  tagTypes: ["getFundServiceList"],
  endpoints: (builder) => ({
    getFundServiceList: builder.query({
      query: (data) => ({
        url: `services`,
        method: "GET",
        params: data,
      }),
      providesTags: ["getFundServiceList"],
    }),
    getFundServiceDetail: builder.query({
      query: (data) => ({
        url: `services/${data.id}`,
        method: "GET",
      }),
      providesTags: ["getFundServiceList"],
    }),
    createFundService: builder.mutation({
      query: (data) => ({
        url: `services`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getFundServiceList"],
    }),
    updateFundService: builder.mutation({
      query: (data) => ({
        url: `services/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["getFundServiceList"],
    }),
    deleteFundService: builder.mutation({
      query: (data) => ({
        url: `services/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getFundServiceList"],
    }),
  }),
});

export const {
  useGetFundServiceListQuery,
  useGetFundServiceDetailQuery,
  useCreateFundServiceMutation,
  useUpdateFundServiceMutation,
  useDeleteFundServiceMutation,
} = fundServiceApi;
