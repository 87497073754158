import React, { useEffect, useState, useMemo } from "react";
import { Divider, Switch } from "antd";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  ICareer,
  ICertificate,
  ICourse,
  ICv,
  IFile,
  IIntroduceYourself,
  ISkill,
} from "../../../../utils/types/dmc4";

import {
  bytesToMegabytes,
  downloadFileFromURL,
  showDateRange,
  showPrivateEmail,
  showPrivatePhoneNumber,
} from "../../../../utils/commonFunc";

import CVCustomDetailTitle from "./cvCustomDetailTitle";
import CVTable from "./cvTable";
import KitNormalButton from "../../../../components/KitNormalButton";
import { useGetCvDetailQuery } from "../../talentInfoApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.scss";

const highEducationLevelColumns = [
  { title: "학교명", width: "w33", key: "schoolName" },
  { title: "전공", width: "w27", key: "major" },
  { title: "재학기간", width: "w22", key: "poe" },
  { title: "학점 및 등급", width: "w33", key: "cag" },
];

const careerColumns = [
  { title: "회사명", width: "w33", key: "companyName" },
  { title: "부서명", width: "w33", key: "department" },
  { title: "재직기간", width: "w33", key: "poe" },
];

const courseColumns = [
  { title: "교육명", width: "w39", key: "educationName" },
  { title: "교육기간", width: "w24", key: "tp" },
  { title: "교육기관", width: "w23", key: "ei" },
  { title: "수료", width: "w13", key: "completion" },
];

const certificateColumns = [
  { title: "자격증명", width: "w39", key: "credentials" },
  { title: "취득일", width: "w24", key: "ad" },
  { title: "발행처/기관", width: "w23", key: "pord" },
  { title: "추가내용", width: "w13", key: "ai" },
];

interface IFieldValue {
  title: string;
  content: string;
}

interface ICVDetailProps {
  cvID?: string;
  isFromProps?: boolean;
}

function CVDetail({ cvID, isFromProps = false }: ICVDetailProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [cvDetail, setCvDetail] = useState<ICv | null>(null);
  const [careerData, setCareerData] = useState<any>([]);
  const [courses, setCourses] = useState<any>([]);
  const [certificates, setCertificates] = useState<any>([]);
  const [highEducationLevelData, setHighEducationLevelData] = useState<any>([]);
  const [epData, setEpData] = useState<IFieldValue[]>([]);
  const [careerExperience, setCareerExperience] = useState<{
    years: number;
    months: number;
  }>({ years: 0, months: 0 });
  const { data, isLoading } = useGetCvDetailQuery(
    { id: isFromProps ? cvID : searchParams.get("id") },
    {
      skip: !searchParams.get("id"),
      refetchOnMountOrArgChange: true,
    }
  );

  const [isCVPublic, setIsCVPublic] = useState(data?.data?.isPublic);

  useEffect(() => {
    if (data) {
      setIsCVPublic(data?.data?.isPublic);
    }
  }, [data]);

  const isTalentAcceptInterview = useMemo(() => {
    if (cvDetail && "talentAccept" in cvDetail) {
      return cvDetail.talentAccept;
    }
    return true;
  }, [cvDetail]);

  const WarningTalentNotAccept = (
    <div className="warning-talent-not-accept">
      면접 제안을 받은 인재가 제안 수락 후에 열람이 가능합니다.
    </div>
  );

  useEffect(() => {
    if (data?.data) {
      const cv: ICv = data.data;
      setCvDetail(cv);

      if (cv?.career) {
        let totalExperienceYear = 0;
        let totalExperienceMonth = 0;
        const career = cv.career.map((item: ICareer) => {
          totalExperienceYear += item.experienceYear ?? 0;
          totalExperienceMonth += item.experienceMonth ?? 0;
          return {
            companyName: item.companyName,
            department: item.divisionName,
            poe: showDateRange(item.workingPeriodFrom, item.workingPeriodTo),
          };
        });
        setCareerData(career);
        const totalMonths = totalExperienceYear * 12 + totalExperienceMonth;
        const years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;
        setCareerExperience({ years, months });
      }

      if (cv?.course) {
        const courses = cv.course.map((co: ICourse) => ({
          educationName: co.name,
          tp: co.coursePeriod,
          ei: co.trainingAgencyName,
          completion: co.status,
        }));
        setCourses(courses);
      }

      if (cv?.certificate) {
        const certificates = cv.certificate.map((cer: ICertificate) => ({
          credentials: cer.name,
          ad: cer.receivedDate,
          pord: cer.publishedPlace,
          ai: cer.addInfo,
        }));
        setCertificates(certificates);
      }

      setHighEducationLevelData([
        {
          schoolName: cv.schoolName ?? "",
          major: cv.departmentName ?? "",
          poe: showDateRange(
            cv.entrancYear,
            cv.graduationYear,
            "YYYY-MM",
            "재학중"
          ),
          cag: cv.gpaLevel ?? "",
        },
      ]);

      setEpData([
        {
          title: "보훈 대상",
          content: cv?.veteran ?? "",
        },
        {
          title: "병역 대상",
          content: cv?.militaryService ?? "",
        },
        {
          title: "고용지원금 대상",
          content: cv?.employmentAllowance ?? "",
        },
        {
          title: "장애",
          content: cv?.disabilityLevel ?? "",
        },
      ]);
    }
  }, [data]);

  return (
    <>
      {!searchParams.get("id") || !cvDetail ? (
        <></>
      ) : (
        <div id="cv-detail-page">
          <div className="cvd">
            <div className="cvd-block cvd-header">
              <div className="cvd-header-item">
                <span>{cvDetail.title} </span>
              </div>
            </div>
            <div className="cvd-block cvd-resume">
              <CVCustomDetailTitle title="지원자 기본정보" />
              <div className="cvd-resume-inner">
                <div className="r-avatar">
                  <img
                    src={cvDetail?.image?.url}
                    alt={cvDetail?.user?.picName}
                  />
                </div>
                <Divider type="vertical" />
                <div className="r-detail">
                  <div className="r-detail-left">
                    <div className="r-detail-left-item">
                      <span className="title">이름 </span>
                      <span className="content">
                        {cvDetail?.user?.picName.substring(0, 1)}○○{" "}
                        {cvDetail?.user?.dob
                          ? `${dayjs(cvDetail.user.dob).format("YYYY")}년생`
                          : ""}
                      </span>
                    </div>
                    <div className="r-detail-left-item">
                      <span className="title">휴대폰 번호 </span>
                      <span className="content">
                        {cvDetail?.user?.phoneNumber
                          ? `${showPrivatePhoneNumber(
                              cvDetail.user.phoneNumber
                            )}`
                          : "***-***-****"}
                      </span>
                    </div>
                    <div className="r-detail-left-item">
                      <span className="title">이메일 주소 </span>
                      <span className="content">
                        {showPrivateEmail(cvDetail.user.email)}
                      </span>
                    </div>
                  </div>
                  <div className="r-detail-right">
                    <div className="r-detail-right-item">
                      <span className="title">고용형태 </span>
                      <span className="content">
                        {cvDetail.desiredRecruitmentForm}
                      </span>
                    </div>
                    <div className="r-detail-right-item">
                      <span className="title">지역</span>
                      <span className="content">
                        {cvDetail.desiredWorkingPlace}
                      </span>
                    </div>
                    <div className="r-detail-right-item">
                      <span className="title">연봉</span>
                      <span className="content">{cvDetail.desiredSalary}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cvd-block cvd-hel">
              <CVCustomDetailTitle
                title="최종학력"
                subTitle={cvDetail.highestEducationLevel}
              />
              <CVTable
                columns={highEducationLevelColumns}
                data={highEducationLevelData}
              />
            </div>
            <div className="cvd-block cvd-career">
              <CVCustomDetailTitle
                title="경력사항"
                subTitle={`총 ${careerExperience.years}년 ${careerExperience.months}개월`}
              />
              <CVTable columns={careerColumns} data={careerData} />
            </div>
            <div className="cvd-block cvd-pt">
              <CVCustomDetailTitle title="보유기술" />
              <div className="pt">
                {cvDetail?.skills?.map((skill: ISkill) => (
                  <div className="pt-item">{skill.skillName}</div>
                ))}
              </div>
            </div>
            <div className="cvd-block cvd-tch">
              <CVCustomDetailTitle title="교육 이수내역" />
              <CVTable columns={courseColumns} data={courses} />
            </div>
            <div className="cvd-block cvd-certificate">
              <CVCustomDetailTitle title="자격증" />
              <CVTable columns={certificateColumns} data={certificates} />
            </div>
            <div className="cvd-block cvd-ep">
              <CVCustomDetailTitle title="취업우대사항" />
              {!isTalentAcceptInterview ? (
                WarningTalentNotAccept
              ) : (
                <div className="ep">
                  {epData?.map((item) => {
                    return (
                      <div className="ep-item">
                        <div className="ep-item__title">{item.title}</div>
                        <div className="ep-item__content">{item.content}</div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="cvd-block cvd-si">
              <CVCustomDetailTitle title="자기소개서" />
              {!isTalentAcceptInterview ? (
                WarningTalentNotAccept
              ) : (
                <div className="si">
                  {cvDetail?.introduceYourself?.map(
                    (item: IIntroduceYourself) => {
                      return (
                        <div className="si-item">
                          <div className="si-item__title">{item.index}</div>
                          <div className="si-item__content">{item.content}</div>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </div>
            <div className="cvd-block cvd-attachment">
              <CVCustomDetailTitle title="첨부파일" />
              {!isTalentAcceptInterview ? (
                WarningTalentNotAccept
              ) : (
                <div className="attachment-inner">
                  {cvDetail?.attachedFiles?.map((item: IFile) => {
                    return (
                      <div className="attachment">
                        <div className="attachment-item">
                          <span className="title">{item.originalName}</span>
                          <span className="size">
                            {bytesToMegabytes(item.size)}MB
                          </span>
                        </div>
                        <div
                          className="attachment-item"
                          onClick={() =>
                            downloadFileFromURL(item.url, item.originalName)
                          }
                        >
                          {" "}
                          <img
                            className="download-icon"
                            src="/images/download-icon.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CVDetail;
