import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Input, Table } from "antd";
import "./style.scss";
import { DATE_TIME, PAGE_SIZE, tableLocale } from "../../utils/constants";
import { createSearchParams, useNavigate } from "react-router-dom";
import { DefaultFilterOptions } from "../../utils/types/table";
import moment from "moment";
import { useGetTrainingRegisterQuery } from "../TrainingRegister/trainingRegisterApi";
import { WorkingStatus } from "../../utils/types/user";
import { truncateText } from "../../utils/commonBlock";

function IncumbentEducation() {
  const navigate = useNavigate();
  const submitBtnRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const defaultFilterOptions = {
    value: "",
    page: 1,
    limit: PAGE_SIZE,
    trainingType: WorkingStatus.IS_WORKING,
  };
  const [filterOptions, setfilterOptions] =
    useState<DefaultFilterOptions>(defaultFilterOptions);

  const { data, isLoading, isSuccess } = useGetTrainingRegisterQuery(
    filterOptions,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (searchValue.length === 0) {
      setfilterOptions(defaultFilterOptions);
    }
  }, [searchValue]);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      submitBtnRef?.current?.click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
        width: "15%",
        render: (item: any, record: any, index: number) => {
          return index + 1 + (filterOptions?.page - 1) * 10;
        },
      },
      {
        title: "제목",
        dataIndex: "title",
        key: "title",
        width: "45%",
        render: (item: any) => {
          return truncateText("400px", { item, value: filterOptions.value });
        },
      },
      {
        title: "등록일",
        dataIndex: "createdAt",
        key: "createdAt",
        width: "30%",
        render: (item: any) => {
          return moment(item).format(DATE_TIME);
        },
      },
      {
        title: "상세정보",
        width: "15%",
        render: (item: any, record: any) => {
          return (
            <Button
              onClick={() =>
                navigate({
                  pathname: "view",
                  search: createSearchParams({
                    id: record.id,
                  }).toString(),
                })
              }
            >
              조회
            </Button>
          );
        },
      },
    ];
  }, [data, filterOptions]);

  return (
    <div className="incumbent-education">
      <div className="incumbent-education__header">
        <div className="search-area">
          <div className="header">
            <p className="title">교육신청</p> <b>-</b>
            <p className="sub-title">재직자 교육</p>
          </div>
          <div className="search-input">
            <Input
              placeholder="검색어를 입력해 주세요."
              className="input-area"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button
              ref={submitBtnRef}
              className="btn-crud--primary btn-search"
              onClick={() =>
                setfilterOptions({
                  ...filterOptions,
                  value: searchValue,
                  page: 1,
                  limit: 10,
                })
              }
            >
              검색
            </Button>
          </div>
        </div>
        <div className="action-area">
          <Button
            className="btn-crud--primary btn-create"
            onClick={() => navigate("/incumbent-education/create")}
          >
            작성하기
          </Button>
        </div>
      </div>
      <div className="incumbent-education__body">
        {!isLoading && filterOptions.value.length > 0 && (
          <div className="result-area">
            <div className="result-area__title">
              ‘{filterOptions.value}’&nbsp;
              <div className="result-area__sub-title">
                로 검색한 결과입니다.
              </div>
            </div>
            <div className="result-area__counter">
              총 {data?.data?.metaData?.totalRecords}건
            </div>
          </div>
        )}
        <Table
          locale={tableLocale}
          dataSource={data?.data?.result}
          pagination={
            data?.data?.metaData?.totalRecords > 10 && {
              position: ["bottomCenter"],
              defaultPageSize: PAGE_SIZE,
              total: data?.data?.metaData.totalRecords || 0,
            }
          }
          loading={isLoading}
          columns={columns}
          rowKey={(record: any) => record.id}
          onChange={(page: any, pageSize: any) => {
            setfilterOptions({
              ...filterOptions,
              value: searchValue,
              page: page?.current,
              pageSize: PAGE_SIZE,
            });
          }}
        />
      </div>
    </div>
  );
}

export default IncumbentEducation;
