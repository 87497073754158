/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const talentInfoApi = createApi({
  reducerPath: "talentInfo",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    },
  }),
  tagTypes: ["cv"],
  endpoints: (builder) => ({
    getCvDetail: builder.query({
      query: (params) => ({
        url: `/users/cv/${params.id}`,
        method: "GET",
      }),
      providesTags: ["cv"],
    }),
    getCv: builder.query({
      query: (params) => ({
        url: `users/cv/list`,
        method: "GET",
        params,
      }),
      providesTags: ["cv"],
    }),
    getDetailUser: builder.query({
      query: (id) => ({
        url: `users/${id}`,
        method: "GET",
      }),
      providesTags: ["cv"],
    }),
    deleteCV: builder.mutation({
      query: (id) => ({
        url: `users/cv/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["cv"],
    }),
  }),
});

export const {
  useGetCvDetailQuery,
  useGetCvQuery,
  useGetDetailUserQuery,
  useDeleteCVMutation,
} = talentInfoApi;
