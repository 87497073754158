import React from "react";
import { SideBar } from "../../utils/types/sidebar";
import NavItem from "./NavItem";
import "./navItemHeader.scss";

function Sidebar() {
  const menu: SideBar[] | any[] = [
    {
      label: "프로그램 관리",
      level: 1,
      to: "/",
      children: [
        {
          label: "DMC 500캠프",
          level: 2,
          to: "/dmc500-camp",
          children: [
            {
              label: "신청기업 조회",
              level: 3,
              to: "/dmc500-camp",
            },
            {
              label: "공지사항",
              level: 3,
              to: "/dmc500-camp/announcement",
            },
          ],
        },
        {
          label: "일자리 매칭데이",
          level: 2,
          to: "/main",
          children: [
            {
              label: "신청기업 조회",
              level: 3,
              to: "/main",
            },
            {
              label: "공지사항",
              level: 3,
              to: "/main/announcement",
            },
          ],
        },
      ],
    },
    {
      label: "회원 관리",
      level: 1,
      to: "/member-management",
    },
    {
      label: "회원 승인",
      level: 1,
      to: "/member-approved",
    },

    {
      label: "지원정책",
      level: 1,
      to: "/apply-work-service",
    },
    {
      label: "사업참여",
      level: 1,
      to: "/business-participation",
      children: [
        {
          label: "재단 사업",
          level: 2,
          to: "/foundation-business",
        },
        {
          label: "유관 사업",
          level: 2,
          to: "/related-business",
        },
      ],
    },
    {
      label: "교육신청",
      level: 1,
      to: "/educational-application",
      children: [
        {
          label: "구직자 교육",
          level: 2,
          to: "/training-register",
        },
        {
          label: "재직자 교육",
          level: 2,
          to: "/incumbent-education",
        },
      ],
    },
    {
      label: "참여소통",
      level: 1,
      to: "/participation-communication",
      children: [
        {
          label: "공지사항",
          level: 2,
          to: "/announcement",
        },
        {
          label: "이벤트",
          level: 2,
          to: "/event",
        },
        {
          label: "FAQ",
          level: 2,
          to: "/FAQ",
        },
      ],
    },

    {
      label: "인재기관 정보",
      level: 1,
      to: "/talent-agency-information",
    },
    {
      label: "입주기업",
      level: 1,
      to: "/resident-company",
    },
    {
      label: "채용정보",
      level: 1,
      to: "/recruitment-information",
    },
    {
      label: "인재정보",
      level: 1,
      to: "/talent-information",
    },
    {
      label: "비밀번호 변경",
      to: "/change-password",
      level: 1,
      classname: "password-change-area",
    },
  ];

  return (
    <nav className="sidebar">
      {menu.map((item, index) => {
        return <NavItem key={`${item.label}-${index}`} item={item} />;
      })}
    </nav>
  );
}

export default Sidebar;
