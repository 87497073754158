/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const memberManagementApi = createApi({
  reducerPath: "memberManagement",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    },
  }),
  tagTypes: ["getMemberManagementList"],
  endpoints: (builder) => ({
    getMemberManagementList: builder.query({
      query: (data) => ({
        url: `users/search`,
        method: "POST",
        body: data,
      }),
      providesTags: ["getMemberManagementList"],
    }),
    getMemberManagementDetail: builder.query({
      query: (data) => ({
        url: `users/${data.userId}`,
        method: "GET",
      }),
      providesTags: ["getMemberManagementList"],
    }),
    approveMember: builder.mutation({
      query: (data) => ({
        url: `users/approve`,
        method: "POST",
        body: {
          users: data.users,
        },
      }),
      invalidatesTags: ["getMemberManagementList"],
    }),
    unapproveMember: builder.mutation({
      query: (data) => ({
        url: `users/unapprove`,
        method: "POST",
        body: {
          users: data.users,
        },
      }),
      invalidatesTags: ["getMemberManagementList"],
    }),
    updateStatus: builder.mutation({
      query: (data) => ({
        url: `users/${data?.id}/status`,
        method: "PUT",
        body: data?.body,
      }),
      invalidatesTags: ["getMemberManagementList"],
    }),
  }),
});

export const {
  useGetMemberManagementListQuery,
  useGetMemberManagementDetailQuery,
  useApproveMemberMutation,
  useUnapproveMemberMutation,
  useUpdateStatusMutation,
} = memberManagementApi;
