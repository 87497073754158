/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const eventApi = createApi({
  reducerPath: "event",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["GetEvent"],
  endpoints: (builder) => ({
    createEvent: builder.mutation({
      query: (data) => ({
        url: `communication/event`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetEvent"],
    }),
    updateEvent: builder.mutation({
      query: (data) => {
        return {
          url: `communication/event/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["GetEvent"],
    }),
    deleteEvent: builder.mutation({
      query: (data) => ({
        url: `communication/event/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetEvent"],
    }),
    getEvent: builder.query({
      query: (data) => ({
        url: `communication/event`,
        method: "GET",
        params: data,
      }),
      providesTags: ["GetEvent"],
    }),
    getEventDetail: builder.query({
      query: (data) => ({
        url: `communication/event/${data.id}`,
        method: "GET",
      }),
      providesTags: ["GetEvent"],
    }),
  }),
});

export const {
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useGetEventQuery,
  useGetEventDetailQuery,
} = eventApi;
