import "./style.scss";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../components/modal/modalSlice";
import {
  useApproveMemberMutation,
  useGetMemberManagementDetailQuery,
  useUnapproveMemberMutation,
  useUpdateStatusMutation,
} from "../../memberManagementApi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import moment from "moment";
import { DATE_TIME } from "../../../../utils/constants";
import { ApprovedEnum } from "../../../../utils/types/user";
import DetailBlock from "../../../../components/commonBlock";
import { kitToastMessage } from "../../../../components/toastMessage";
import { convertToDots, formatPhoneNumber } from "../../../../utils/commonFunc";
import { useGetFileMutateMutation } from "../../../../components/imageUpload/fileUploadApi";

function MemberManagementDetail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isFromMemberManagementPage =
    location.pathname.includes("member-management");
  const user = useSelector((state: any) => state.auth.userInfo);

  const dispatch = useDispatch();

  const { data, isSuccess, refetch } = useGetMemberManagementDetailQuery(
    {
      userId: searchParams.get("id"),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const isPersonalized =
    data?.data?.userClassify === 1 || data?.data?.userClassify === null;
  const isEnterprises = data?.data?.userClassify === 2;
  const isOrgan = data?.data?.userClassify === 3;
  const userStatus = data?.data?.status;

  const getUserStatus = useMemo(() => {
    if (isSuccess) {
      let mapStatus;
      const status = data?.data?.approved;
      switch (status) {
        case 1:
          mapStatus = "approved";
          break;
        case 2:
          mapStatus = "unapproved";
          break;
        case 3:
          mapStatus = "idle";
          break;
        default:
          mapStatus = "unapproved";
          break;
      }
      return mapStatus;
    }
  }, [isSuccess, data?.data.approved]);

  const mapStatus = (value: number | null) => {
    let status;
    switch (value) {
      case 1:
        status = ApprovedEnum.APPROVE;
        break;
      case 2:
        status = ApprovedEnum.UNAPPROVE;
        break;
      case 3:
        status = ApprovedEnum.WAIT;
        break;
      default:
        status = ApprovedEnum.UNAPPROVE;
        break;
    }
    return status;
  };

  const renderSituation = (status) => {
    let value, className;
    if (status === "ACTIVE") {
      value = "정상";
      className = "ACTIVE";
    } else if (status === "REMOVED") {
      value = "탈퇴";
      className = "REMOVED";
    } else if (status === "SUSPENDED") {
      value = "정지";
      className = "SUSPENDED";
    } else {
      value = "정상";
      className = "ACTIVE";
    }

    return { value, className };
  };

  const memberInfo = useMemo(() => {
    if (isSuccess) {
      const { userClassify, picName, createdAt, email, phoneNumber, status } =
        data.data;
      const isWithdrawed = status === "REMOVED";
      let arr = [
        {
          title: "회원상태",
          desc: (
            <span
              className={`with-draw-status--${
                renderSituation(status).className
              }`}
            >
              {renderSituation(status)?.value}
            </span>
          ),
        },
        {
          title: "회원 분류",
          desc:
            userClassify === 1
              ? "개인회원"
              : userClassify === 2
              ? "기업회원"
              : userClassify === 3
              ? "기관회원"
              : userClassify,
        },
        {
          title: "회원 ID",
          desc: email,
        },
        {
          title: "회원가입일",
          desc: moment(createdAt).format(DATE_TIME),
        },
      ];
      if (isWithdrawed) {
        arr = [
          ...arr,
          {
            title: "회원탈퇴일",
            desc: moment(data?.data?.withdraw_date).format(DATE_TIME),
          },
        ];
      }

      if (isPersonalized) {
        arr = [
          ...arr,
          {
            title: "이름",
            desc: isWithdrawed ? convertToDots(picName) : picName,
          },
          {
            title: "연락처",
            desc: isWithdrawed
              ? convertToDots(formatPhoneNumber(phoneNumber))
              : formatPhoneNumber(phoneNumber),
          },
        ];
      }

      return arr;
    }
    return [];
  }, [isSuccess]);

  const picInfo = useMemo(() => {
    if (isSuccess) {
      const { phoneNumber, picPosition, picName, status } = data.data;
      const isWithdrawed = status === "REMOVED";
      return [
        {
          title: "담당자명",
          desc: isWithdrawed ? convertToDots(picName) : picName,
        },
        {
          title: "담당자 부서/직책",
          desc: isWithdrawed ? convertToDots(picPosition) : picPosition,
        },
        {
          title: "담당자 연락처",
          desc: isWithdrawed ? convertToDots(phoneNumber) : phoneNumber,
        },
      ];
    }
    return [];
  }, [isSuccess]);

  const companyEnterprisesInfo = useMemo(() => {
    if (isSuccess) {
      let pln =
        data?.data?.innovation[0]?.industryClassification1 ||
        data?.data?.business?.industryClassification1 ||
        "";

      if (
        data?.data?.innovation[0]?.industryClassification2 ||
        data?.data?.business?.industryClassification2
      ) {
        let lv2 =
          data?.data?.innovation[0]?.industryClassification2 ||
          data?.data?.business?.industryClassification2 ||
          "";
        if (lv2) {
          pln = pln + ", " + lv2;
        }
      }
      if (
        data?.data?.innovation[0]?.industryClassification3 ||
        data?.data?.business?.industryClassification3
      ) {
        let lv3 =
          data?.data?.innovation[0]?.industryClassification3 ||
          data?.data?.business?.industryClassification3 ||
          "";
        if (lv3) {
          pln = pln + ", " + lv3;
        }
      }

      return [
        {
          title: "기업명",
          desc: data?.data?.business?.businessName,
        },
        {
          title: "사업자등록번호",
          desc: data?.data?.business?.registrationNumber,
        },

        {
          title: "대표자명",
          desc: data?.data?.business?.representativeName,
        },
        {
          title: "기업 주소",
          desc:
            data?.data?.business?.address +
            ` ${
              data?.data?.business?.detailAddress &&
              " - " + data?.data?.business?.detailAddress
            }`,
        },
        {
          title: "업종",
          desc: data?.data?.business?.firstCareer,
        },
        {
          title: "대표번호",
          desc: formatPhoneNumber(data?.data?.business?.phoneNumber),
        },
        {
          title: "근로자 수",
          desc: data?.data?.business?.labourNumber,
        },
        {
          title: "회사 홈페이지",
          desc: data?.data?.business?.homepage,
          type: "link-href",
        },
        {
          title: "사업자등록증",
          desc: data?.data?.business?.files?.filter(
            (it) => it.type === "BUSINESSCODE"
          ),
          type: "downloadFile",
        },
        {
          title: "입주기업 여부",
          desc:
            data?.data?.business?.included === "Included"
              ? "입주기업"
              : data?.data?.business?.included === "Not_Included"
              ? "비입주기업"
              : null,
        },
        {
          title: "산업분류",
          desc: pln,
        },
      ];
    }
    return [];
  }, [isSuccess]);

  const companyOrganInfo = useMemo(() => {
    if (isSuccess) {
      return [
        {
          title: "기관명",
          desc: data?.data?.business?.businessName,
        },
        {
          title: "대표자명",
          desc: data?.data?.business?.representativeName,
        },
        {
          title: "사업자등록번호",
          desc: data?.data?.business?.registrationNumber,
        },
        {
          title: "기관 주소",
          desc:
            data?.data?.business?.address +
            ` ${
              data?.data?.business?.detailAddress &&
              " - " + data?.data?.business?.detailAddress
            }`,
        },
        {
          title: "대표번호",
          desc: formatPhoneNumber(data?.data?.business?.phoneNumber),
        },
        {
          title: "훈련분야",
          desc: data?.data?.business?.firstCareer,
        },
        {
          title: "홈페이지",
          desc: data?.data?.business?.homepage,
          type: "link-href",
        },
        {
          title: "부서 연락처",
          desc: data?.data?.business?.picPhoneNumber,
          type: "link",
        },
        {
          title: "사업자등록증",
          desc: data?.data?.business?.files,
          type: "downloadFile",
        },
      ];
    }
    return [];
  }, [isSuccess]);

  const [approveMember] = useApproveMemberMutation();
  const [unapproveMember] = useUnapproveMemberMutation();
  const [updateStatus] = useUpdateStatusMutation();

  const handleAprroveConfirm = (type: string = "") => {
    if (type === "approve") {
      approveMember({
        users: [searchParams.get("id")],
      })
        .then((res: any) => {
          const status = res.data.statusCode;
          if (status === 200 || status === 201) {
            navigate(-1);
            kitToastMessage("bottom", "승인 완료되었습니다.", "success");
          }
        })
        .catch((error: any) => {});
    }
    if (type === "unapprove") {
      unapproveMember({
        users: [searchParams.get("id")],
      })
        .then((res: any) => {
          const status = res.data.statusCode;
          if (status === 200 || status === 201) {
            navigate(-1);
            kitToastMessage("bottom", "미승인 완료되었습니다. ", "success");
          }
        })
        .catch((error: any) => {});
    }
  };

  const handleChangeUserStatus = async () => {
    try {
      const data = {
        id: searchParams?.get("id"),
        body: {
          status:
            userStatus === "ACTIVE"
              ? "REMOVED"
              : userStatus === "REMOVED"
              ? "ACTIVE"
              : "ACTIVE",
        },
      };

      const res = await updateStatus(data)?.unwrap();

      if (res.statusCode === 200 && userStatus === "ACTIVE") {
        kitToastMessage("bottom", "회원정지 되었습니다.", "success");
      }
      if (res.statusCode === 200 && userStatus === "REMOVED") {
        kitToastMessage("bottom", "회원정지 해제 되었습니다.", "success");
      }
    } catch (error) {}
  };

  return (
    <div className="member-management-detail">
      <div
        className={`member-management-detail__header ${
          true ? "is-admin-page" : null
        }`}
      >
        <div className="title-container">
          <p className="title">회원정보 상세</p>
        </div>
      </div>
      {!isFromMemberManagementPage && (
        <div className="action-area">
          <Button
            className={`btn-crud--${
              getUserStatus === "approved" ? "unapproved" : "primary"
            } btn-create`}
            disabled={getUserStatus === "approved"}
            onClick={() => {
              dispatch(
                openModal({
                  template: "show-member-approved-confirm",
                  data: {
                    confirmText: "가입 승인하시겠습니까? ",
                    isApproved: true,
                  },
                  actionConfirm: () => handleAprroveConfirm("approve"),
                })
              );
            }}
          >
            승인
          </Button>
          <Button
            className={`btn-crud--${
              getUserStatus === "unapproved" || getUserStatus === "approved"
                ? "unapproved"
                : "primary"
            } btn-create`}
            disabled={
              getUserStatus === "unapproved" || getUserStatus === "approved"
            }
            onClick={() => {
              dispatch(
                openModal({
                  template: "show-member-approved-confirm",
                  data: {
                    confirmText: "가입 미승인하시겠습니까?",
                    isApproved: false,
                  },
                  actionConfirm: () => handleAprroveConfirm("unapprove"),
                })
              );
            }}
          >
            미승인
          </Button>
        </div>
      )}

      <div className="member-management-detail__body">
        <div className="body-left">
          {(isPersonalized || isEnterprises || isOrgan) && (
            <DetailBlock
              userStatus={
                isFromMemberManagementPage && user?.role === "ADMIN"
                  ? userStatus
                  : null
              }
              onUserStatusChange={() =>
                dispatch(
                  openModal({
                    template: "show-confirm",
                    data: {
                      type: "delete",
                      subType: "confirm",
                      model: "dmc3",
                      confirmText:
                        userStatus === "ACTIVE"
                          ? "회원 정지 하시겠습니까?"
                          : "회원정지 해제 하시겠습니까?",
                    },
                    actionConfirm: handleChangeUserStatus,
                  })
                )
              }
              title="회원 정보"
              content={memberInfo}
              className={{ paddingBottom: "104px" }}
            />
          )}
          {(isEnterprises || isOrgan) && (
            <DetailBlock title="담당자 정보" content={picInfo} />
          )}
        </div>
        <div className="body-right">
          {isEnterprises && (
            <DetailBlock
              title={isFromMemberManagementPage ? "기업정보" : "기관 정보"}
              content={companyEnterprisesInfo}
            />
          )}
          {isOrgan && (
            <DetailBlock
              title={isFromMemberManagementPage ? "기관 정보" : "기관 정보"}
              content={companyOrganInfo}
            />
          )}
        </div>
      </div>
      <div className="member-management-detail__footer">
        <Button className="btn--goback" onClick={() => navigate(-1)}>
          목록으로
        </Button>
      </div>
    </div>
  );
}

export default MemberManagementDetail;
