import React, { useState, useEffect, useMemo } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import "./navItemHeader.scss";

const resolveLinkPath = (childTo: string, parentTo: string) => `${parentTo}/${childTo}`;

const NavItemHeader = ({ item }: any) => {
  const navigate = useNavigate();
  const { label, to: headerToPath, children, enableToggle = true, level } = item;

  const isChildren = useMemo(() => {
    return !!children;
  }, [children]);

  const location = useLocation();

  const [expanded, setExpand] = useState(location.pathname.includes(headerToPath));

  const onExpandChange = (e: any) => {
    e.preventDefault();
    setExpand((expanded) => !expanded);
  };

  useEffect(() => {
    if (!enableToggle) {
      setExpand(true);
    }
  }, [enableToggle]);
  return (
    <>
      <button
        className={`nav-item nav-item-header-button padding-${level} ${
          isChildren && expanded && "nav-item-header--expand"
        }`}
        onClick={enableToggle && onExpandChange}
      >
        <span className="nav-label">{label}</span>
        {enableToggle &&
          (expanded ? (
            <UpOutlined className="icon-direction icon--up" />
          ) : (
            <DownOutlined className="icon-direction icon--down" />
          ))}
      </button>

      {expanded && (
        <div className="nav-children-block">
          {children.map((el: any, index: number) => {
            const key = `${el.label}-${index}`;
            const { label, children } = el;
            if (children) {
              return (
                <div key={key}>
                  <NavItemHeader
                    item={{
                      ...el,
                      to: resolveLinkPath(el.to, item.to),
                    }}
                  />
                </div>
              );
            }

            return (
              <div
                key={key}
                onClick={() => navigate(el.to)}
                className={`nav-item nav-item__child ${
                  location.pathname.includes(el.to) ? "nav-item-child--active" : ""
                } padding-${el.level}`}
              >
                <span className="nav-label">{label}</span>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default NavItemHeader;
