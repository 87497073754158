import notification, { NotificationPlacement } from "antd/lib/notification";
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import "./style.scss";

export const kitToastMessage = (
  placement: NotificationPlacement,
  message: string,
  className: "success" | "fail" | "idle"
) => {
  const formatClass = () => {
    let mappedClass;
    let mappedMessage;
    switch (className) {
      case "success":
        mappedClass = "kit-notification--success";
        mappedMessage = (
          <div className="message-wrapper message--success">
            <CheckOutlined /> {message}
          </div>
        );
        break;
      case "fail":
        mappedClass = "kit-notification--fail";
        mappedMessage = (
          <div className="message-wrapper message-failed">
            <CloseOutlined /> {message}
          </div>
        );
        break;
      default:
        mappedClass = "kit-notification--idle";
        mappedMessage = (
          <div className="message-wrapper message-idle">
            <ExclamationCircleOutlined /> {message}
          </div>
        );
        break;
    }
    return { class: mappedClass, message: mappedMessage };
  };

  notification.info({
    message: formatClass().message,
    placement,
    className: `kit-notification ${formatClass().class}`,
  });
};
