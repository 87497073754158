import { Button } from "antd";
import React from "react";
import "./style.scss";
export default function ShowConfirm(props: any) {
  const { actionCancel, actionConfirm, data } = props;

  return (
    <div
      className={`show-confirm ${data.model === "dmc3" && "show-confirm-new"} `}
    >
      <div className={`title ${data.model === "dmc3" && "title-new"}`}>
        {data.confirmText}
      </div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        {data.type === "delete" ? (
          <>
            <Button
              className={`bt-cancel btn-confirm--cancel ${
                data.model === "dmc3" && "btn-confirm--cancel-new"
              }`}
              onClick={() => actionCancel()}
            >
              취소
            </Button>
            <Button
              className={`bt-confirm btn-confirm--delete ${
                data.model === "dmc3" && "btn-confirm--delete-new"
              }`}
              onClick={actionConfirm}
            >
              {data?.subType === "confirm" ? "확인" : "삭제하기"}
            </Button>
          </>
        ) : (
          <>
            <Button className="bt-cancel" onClick={actionCancel}>
              취소
            </Button>
            <Button className="bt-confirm" onClick={actionConfirm}>
              확인
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
