import { Button } from "antd";
import React from "react";
import "./style.scss";
export default function ShowApprovedConfirm(props: any) {
  const {
    actionCancel,
    actionConfirm,
    data: { confirmText, isApproved },
  } = props;

  return (
    <div className="show-confirm">
      <div className="title">{confirmText}</div>
      <div className="action-area">
        <Button className="btn-cancel" onClick={actionCancel}>
          취소
        </Button>
        <Button
          className={`btn-confirm ${
            isApproved ? "btn-confirm--primary " : "btn-confirm--error "
          }`}
          onClick={actionConfirm}
        >
          {isApproved ? "승인" : "미승인 "}
        </Button>
      </div>
    </div>
  );
}
