/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const fileUploadApi: any = createApi({
  reducerPath: "fileUploadApi",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["ImageUpload"],
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: (data) => ({
        url: `files`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ImageUpload"],
    }),
    getFile: builder.query({
      query: (data) => ({
        url: `files/${data.id}`,
        method: "GET",
      }),
      providesTags: ["ImageUpload"],
    }),
    getFileMutate: builder.mutation({
      query: (data) => ({
        url: `files/${data.id}`,
        method: "GET",
      }),
    }),
    deleteFile: builder.mutation({
      query: (data) => ({
        url: `files/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ImageUpload"],
    }),
  }),
});

export const { useUploadFileMutation, useGetFileQuery, useGetFileMutateMutation, useDeleteFileMutation } =
  fileUploadApi;
