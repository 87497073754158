/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const trainingRegisterApi = createApi({
  reducerPath: "trainingRegister",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["GetTrainingRegister"],
  endpoints: (builder) => ({
    createTrainingRegister: builder.mutation({
      query: (data) => ({
        url: `service-training`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetTrainingRegister"],
    }),
    updateTrainingRegister: builder.mutation({
      query: (data) => {
        return {
          url: `service-training/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["GetTrainingRegister"],
    }),
    deleteTrainingRegister: builder.mutation({
      query: (data) => ({
        url: `service-training/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetTrainingRegister"],
    }),
    getTrainingRegister: builder.query({
      query: (data) => ({
        url: `service-training/search`,
        method: "POST",
        body: data,
      }),
      providesTags: ["GetTrainingRegister"],
    }),
    getTrainingRegisterDetail: builder.query({
      query: (data) => ({
        url: `service-training/${data.id}`,
        method: "GET",
      }),
      providesTags: ["GetTrainingRegister"],
    }),
  }),
});

export const {
  useGetTrainingRegisterQuery,
  useUpdateTrainingRegisterMutation,
  useDeleteTrainingRegisterMutation,
  useGetTrainingRegisterDetailQuery,
  useCreateTrainingRegisterMutation,
} = trainingRegisterApi;
