import { Button } from "antd";
import React, { useMemo } from "react";
import IconCheckedOutlined from "./IconCheckedOutlined";
import "./style.scss";

interface IKitButtonCustomProps {
  type: "check" | "cancel" | "default";
  btnType?: "default" | "active" | "disabled";
  content: React.ReactNode | string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function KitButtonCustom({
  type = "default",
  content,
  btnType = "default",
  className,
  onClick,
}: IKitButtonCustomProps) {
  const generateIcon = useMemo(() => {
    switch (type) {
      case "check":
        return <IconCheckedOutlined />;
        break;
      case "cancel":
        return <IconCheckedOutlined />;
        break;
      case "default":
        return <IconCheckedOutlined />;
        break;
      default:
        return <IconCheckedOutlined />;
        break;
    }
  }, [type]);

  return (
    <div
      className={`kbc ${className ? className : ""} ${
        btnType === "active" && "kbc--active"
      }  ${btnType === "default" && "kbc--default"}  ${
        btnType === "disabled" && "kbc--disabled"
      }`}
      onClick={(e) => onClick?.(e)}
    >
      {type !== "default" && generateIcon}
      {content}
    </div>
  );
}

export default KitButtonCustom;
