import { useEffect, useState, useMemo, useRef } from "react";
import { Button, Input, Select, Table } from "antd";
import "./style.scss";
import { PAGE_SIZE, tableLocale } from "../../utils/constants";
import { createSearchParams, useNavigate } from "react-router-dom";
import { DefaultFilterOptions } from "../../utils/types/table";
import { truncateText } from "../../utils/commonBlock";
import { highlightKeywords } from "../../utils/commonFunc";
import { useGetCvQuery } from "./talentInfoApi";

function TalentInfo() {
  const submitBtnRef = useRef<any>(null);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");

  const defaultFilterOptions = {
    keyword: searchValue,
    page: 1,
    limit: PAGE_SIZE,
    approved: 1,
    isPublic: true,
  };
  const [filterOptions, setfilterOptions] = useState<any>(defaultFilterOptions);

  const { data, isLoading, isSuccess } = useGetCvQuery(filterOptions, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (searchValue.length === 0) {
      setfilterOptions(defaultFilterOptions);
    }
  }, [searchValue]);

  const mapUserSpecify = (value: number) => {
    let role;
    switch (value) {
      case 1:
        role = "개인";
        break;
      case 2:
        role = "기업";
        break;
      case 3:
        role = "기관";
        break;
      default:
        role = "";
        break;
    }
    return role;
  };

  const columns = useMemo(() => {
    return [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
        width: "10%",
        render: (item: any, record: any, index: number) => {
          return index + 1 + (filterOptions?.page - 1) * 10;
        },
      },
      {
        title: "인재 이름",
        dataIndex: "name",
        key: "name",
        width: "15%",
        render: (item: any, record: any) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: highlightKeywords(record?.user?.picName, searchValue),
              }}
            ></div>
          );
        },
      },
      {
        title: "이메일",
        dataIndex: "email",
        key: "email",
        width: "35%",
        render: (item: any, record: any) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: highlightKeywords(record?.user?.email, searchValue),
              }}
            ></div>
          );
        },
      },
      {
        title: "연락처",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        width: "30%",
        render: (_, record: any) => {
          return record?.user?.phoneNumber;
        },
      },
      {
        title: "상세정보",
        width: 200,
        render: (item: any, record: any) => {
          return (
            <Button
              className="btn-lookup"
              onClick={() =>
                navigate({
                  pathname: "view",
                  search: createSearchParams({
                    id: record.id,
                  }).toString(),
                })
              }
            >
              조회
            </Button>
          );
        },
      },
    ];
  }, [data?.data, filterOptions]);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      submitBtnRef?.current?.click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="talent-info">
      <div className="talent-info__header">
        <div className="search-area">
          <p className="search-area--title">인재 정보</p>
          <div className="search-input">
            <Input
              className="input-area"
              value={searchValue}
              placeholder="검색어를 입력해 주세요."
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button
              ref={submitBtnRef}
              disabled={!searchValue}
              className="btn-crud--primary btn-search"
              onClick={() =>
                setfilterOptions({
                  ...filterOptions,
                  keyword: searchValue,
                  page: 1,
                  limit: 10,
                })
              }
            >
              검색
            </Button>
          </div>
        </div>
      </div>
      <div className="talent-info__body">
        {!isLoading &&
          filterOptions?.title &&
          filterOptions?.title?.length > 0 && (
            <div className="result-area">
              <div className="result-area__title">
                ‘{filterOptions.title}’&nbsp;
                <div className="result-area__sub-title">
                  로 검색한 결과입니다.
                </div>
              </div>
              <div className="result-area__counter">
                총 {data?.data?.metaData?.totalRecords}건
              </div>
            </div>
          )}
        <Table
          locale={tableLocale}
          dataSource={data?.data?.result}
          pagination={
            data?.data?.metaData?.totalRecords > 10 && {
              position: ["bottomCenter"],
              current: filterOptions.page,
              showSizeChanger: false,
              defaultPageSize: PAGE_SIZE,
              total: data?.data?.metaData.totalRecords || 0,
            }
          }
          loading={isLoading}
          columns={columns}
          rowKey={(record: any) => record.id}
          onChange={(page: any) => {
            setfilterOptions({
              ...filterOptions,
              keyword: searchValue,
              page: page?.current,
              limit: PAGE_SIZE,
            });
          }}
        />
      </div>
    </div>
  );
}

export default TalentInfo;
