import { TabPaneProps, Tabs, TabsProps } from "antd";
import React from "react";
import "./style.scss";

export interface Tab extends Omit<TabPaneProps, "tab"> {
  key: string;
  label: React.ReactNode;
}

interface IKitTabCustomProps extends TabsProps {
  items: Tab[];
  onChange: (tab: string) => void;
  activeKey: string;
  setActiveKey: (key: any) => void;
  className?: string;
}

function KitTabCustom({
  items,
  onChange,
  setActiveKey,
  activeKey,
  className,
}: IKitTabCustomProps) {
  if (!items) {
    return <></>;
  }

  return (
    <div
      className={`ktc-wrapper ${className ? className : ""} ${
        items.length === 1 && "ktc-wrapper--one"
      }`}
    >
      <Tabs
        className="ktc-tab"
        activeKey={activeKey}
        items={items}
        onChange={(tabKey) => {
          setActiveKey(tabKey);
          onChange(tabKey);
        }}
      />
    </div>
  );
}

export default KitTabCustom;
