import React, { useState } from "react";
import "./style.scss";
import { Button, Checkbox, Form, Input } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useLoginMutation } from "./authApi";
import { PASSWORD, REMEMBER_ME, USERNAME } from "../../utils/constants";

function UserLogin() {
  const [form] = Form.useForm();
  const userName = Form.useWatch("username", form);
  const password = Form.useWatch("password", form);
  const navigate = useNavigate();
  const [login, { isLoading: loadingLogin }] = useLoginMutation();

  const [errorLogin, seterrorLogin] = useState("");
  const [rememberMe, setrememberMe] = useState(
    localStorage.getItem(REMEMBER_ME) === "1"
  );

  const onFinish = (values: any) => {
    login({
      email: values.username,
      password: values.password,
    })
      .unwrap()
      .then((res) => {
        if (rememberMe) {
          // localStorage.setItem(USERNAME, values.username);
          // localStorage.setItem(PASSWORD, values.password);
          localStorage.setItem(REMEMBER_ME, "1");
        } else {
          // localStorage.removeItem(USERNAME);
          // localStorage.removeItem(PASSWORD);
          localStorage.setItem(REMEMBER_ME, "0");
        }
        navigate("/member-management");
      })
      .catch((error) => {
        seterrorLogin(
          "아이디 또는 비밀번호 입력이 잘못되었습니다. 다시 입력해주세요."
        );
      });
  };

  const onChangeText = (e: any) => {
    if (errorLogin) {
      seterrorLogin("");
    }
  };

  return (
    <Form
      name="normal_login"
      className="login-form login-form--custom"
      initialValues={{
        username: localStorage.getItem(USERNAME) || "",
        password: localStorage.getItem(PASSWORD) || "",
      }}
      onFinish={onFinish}
      layout={"vertical"}
      form={form}
    >
      <img src={"/assets/images/logo_dmc_auth.png"} className="logo-img" />
      <Form.Item
        label="관리자 아이디"
        name="username"
        rules={[
          {
            required: true,
            message: "아이디를 입력해주세요.",
          },
          {
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "이메일 형식이 올바르지 않습니다. ",
          },
        ]}
      >
        <Input placeholder="아이디를 입력해주세요" onChange={onChangeText} />
      </Form.Item>
      <Form.Item
        label="관리자 비밀번호"
        name="password"
        rules={[
          {
            required: true,
            message: "비밀번호를 입력해주세요",
          },
          // ******************** enable to secure password with standard like, at least 8 digit, 1 symbol, 1 uppercase ********************
          // {
          //   pattern:
          //     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()\-+=^]).{8,20}$/,
          //   message: "8자리이상,영어,숫자,특수기호를 포함해주세요.",
          // },
        ]}
      >
        <Input.Password
          placeholder="비밀번호를 입력해주세요"
          onChange={onChangeText}
        />
      </Form.Item>
      {errorLogin && <div className="error-msg">{errorLogin}</div>}
      <Form.Item className="remember-me-group">
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <div
            className="remember-me-child"
            onClick={() => {
              setrememberMe(!rememberMe);
            }}
          >
            <img
              src={
                rememberMe
                  ? "/assets/images/rbSelect.svg"
                  : "/assets/images/rbUnSelect.svg"
              }
            />
            <div className="remember-me">자동 로그인</div>
          </div>
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loadingLogin}
          disabled={!(userName && password)}
        >
          로그인
        </Button>
      </Form.Item>
    </Form>
  );
}
export default UserLogin;
