import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import {
  usePasswordChangeMutation,
  useVerifyPasswordMutation,
} from "./authApi";
import { useSelector } from "react-redux";
import { debounce } from "../../utils/commonFunc";
import { useDispatch } from "react-redux";
import { openModal } from "../../components/modal/modalSlice";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const DEBOUNCE_TIME = 2 * 1000;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = useForm();
  const initialValues = {};
  const [isDisabled, setIsDisabled] = useState(true);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [changePassword, { isLoading: isPasswordChangeLoading }] =
    usePasswordChangeMutation();
  const [time, setTime] = useState(DEBOUNCE_TIME);

  const user: any = useSelector((state: any) => state.auth);
  const [verifyPassword] = useVerifyPasswordMutation();

  const onFinish = async (_: any) => {
    try {
      const body = {
        email: user?.userInfo?.email,
        newPassword: form.getFieldValue("confirmNewPassword"),
        token: user?.userInfo?.token || localStorage.getItem("TOKEN"),
      };

      const res: any = await changePassword(body).unwrap();
      const code = res.statusCode;

      if (code === 200 || code === 201) {
        dispatch(
          openModal({
            template: "show-info",
            data: {
              info: "변경완료 되었습니다.",
              type: "popup--success",
            },
            actionConfirm: () => navigate("/member-management"),
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedValidatePasswordMatch = debounce(
    (rule: any, value: any, callback: any) => {
      const data = {
        body: {
          password: form.getFieldValue("oldPassword"),
        },
        userID: user?.userInfo?.id,
      };
      verifyPassword(data)
        .unwrap()
        .then((res) => {
          const code = res.statusCode;
          if (code === 200 || code === 201) {
            callback();
          } else {
            callback("비밀번호가 일치하지 않습니다.");
            setIsDisabled(true);
          }
        })
        .catch((_) => {
          callback("비밀번호가 일치하지 않습니다.");
          setIsDisabled(true);
        })
        .finally(() => setIsVerifyLoading(false));
    },
    time
  );

  return (
    <div className="pw-change-wrapper">
      <Form
        name="pw-change__form"
        className="pw-change__form"
        initialValues={initialValues}
        onFinish={onFinish}
        layout={"vertical"}
        onFieldsChange={(_) => {
          const isEmpty = Object.values(form.getFieldsValue()).some(
            (item) => !item
          );
          const isError =
            form
              .getFieldsError()
              ?.map((field) => field.errors)
              ?.flat()?.length > 0 || isEmpty;

          if (isError) {
            setIsDisabled(true);
          } else {
            setIsDisabled(false);
          }
        }}
        form={form}
      >
        <img src={"/assets/images/logo_dmc_auth.png"} className="logo-img" />
        <Form.Item
          className="form-item"
          label="현재 비밀번호"
          name="oldPassword"
          rules={[
            { required: true, message: "Required field." },
            {
              validator: (_, value, callback) => {
                setTime(DEBOUNCE_TIME);
                setIsVerifyLoading(true);
                debouncedValidatePasswordMatch(_, value, callback);
              },
            },
          ]}
        >
          <Input.Password placeholder="8자리이상,영어,숫자,특수기호 포함" />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="새 비밀번호"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Required field.",
            },
            {
              pattern:
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=!])[A-Za-z\d@#$%^&+=!]{8,}$/g,
              message: "8자리이상,영어,숫자,특수기호를 포함해주세요.",
            },
          ]}
        >
          <Input.Password placeholder="8자리이상,영어,숫자,특수기호 포함" />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="새 비밀번호 확인"
          name="confirmNewPassword"
          rules={[
            { required: true, message: "Required field." },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("비밀번호가 일치하지 않습니다.")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="비밀번호를 입력해주세요" />
        </Form.Item>
        <Form.Item className="form-item form-item__btn">
          <Button
            className={`btn--changePw ${
              (isDisabled || isVerifyLoading) && "btn-submit--disabled"
            }`}
            type="primary"
            htmlType="submit"
            loading={isPasswordChangeLoading || isVerifyLoading}
            disabled={isDisabled || isVerifyLoading}
            onClick={() => setTime(0)}
          >
            변경하기
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ChangePassword;
