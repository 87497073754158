/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const annoucementApi = createApi({
  reducerPath: "annoucement",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["GetAnnoucement"],
  endpoints: (builder) => ({
    createAnnoucement: builder.mutation({
      query: (data) => ({
        url: `communication/notification`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetAnnoucement"],
    }),
    updateAnnoucement: builder.mutation({
      query: (data) => {
        return {
          url: `communication/notification/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["GetAnnoucement"],
    }),
    deleteAnnoucement: builder.mutation({
      query: (data) => ({
        url: `communication/notification/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetAnnoucement"],
    }),
    getAnnoucement: builder.query({
      query: (data) => ({
        url: `communication/notification`,
        method: "GET",
        params: data,
      }),
      providesTags: ["GetAnnoucement"],
    }),
    getAnnoucementDetail: builder.query({
      query: (data) => ({
        url: `communication/notification/${data.id}`,
        method: "GET",
      }),
      providesTags: ["GetAnnoucement"],
    }),
  }),
});

export const {
  useCreateAnnoucementMutation,
  useUpdateAnnoucementMutation,
  useDeleteAnnoucementMutation,
  useGetAnnoucementQuery,
  useGetAnnoucementDetailQuery,
} = annoucementApi;
