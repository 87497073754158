import { Button, Form, Input } from "antd";
import "./style.scss";
import { ApplyWorkServiceForm } from "../../@type";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  useCreateApplyWorkServiceMutation,
  useDeleteApplyWorkServiceMutation,
  useGetApplyWorkServiceDetailQuery,
  useUpdateApplyWorkServiceMutation,
} from "../../applyWorkServiceApi";
import {
  useGetFileMutateMutation,
  useGetFileQuery,
  useUploadFileMutation,
} from "../../../../components/imageUpload/fileUploadApi";
import AvatarUpload from "../../../../components/imageUpload";
import { API_LINK, KEY_EDITOR, TOKEN } from "../../../../utils/constants";
import MultipleUpload from "../../../../components/multipleUpload";
import { generateCustomLable } from "../../../../utils/commonBlock";
import KitDatepicker from "../../../../components/datePicker";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../components/modal/modalSlice";
import { kitToastMessage } from "../../../../components/toastMessage";
import { Buffer } from "buffer";

interface CreateApplyWorkActionProps {
  type: "create" | "update" | "view";
}

function CreateApplyWorkAction({ type }: CreateApplyWorkActionProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFormClicked, setIsFormClicked] = useState(false);
  const [searchParams] = useSearchParams();
  const [filesArr, setFilesArr] = useState<any>([]);
  const editorRef: any = useRef(null);
  const [form] = Form.useForm();
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const isViewPage = type === "view";

  const initialValues: Partial<ApplyWorkServiceForm> = {
    recruitmentCalendar: "",
    applicableObject: "",
    method: "",
    title: "",
    desctiption: "",
    url: "",
  };

  const { data, isLoading, isSuccess } = useGetApplyWorkServiceDetailQuery(
    {
      id: searchParams.get("id"),
    },
    {
      skip: type === "create",
    }
  );

  const files = useMemo(() => {
    if (isSuccess) {
      return {
        thumbnailId: data.data?.thumnailId,
        attachFileId: data.data?.attachFileId,
      };
    }
  }, [isSuccess, data]);

  const { data: thumbnailData, isSuccess: isUploadThumnailSuccess } =
    useGetFileQuery(
      {
        id: files?.thumbnailId,
      },
      {
        skip: type === "create" || !files?.thumbnailId,
      }
    );
  const { data: fileData, isSuccess: isUploadFileSuccess } = useGetFileQuery(
    {
      id: files?.attachFileId,
    },
    {
      skip: type === "create" || !files?.attachFileId,
    }
  );

  const thumnailLink = useMemo(() => {
    if (isUploadThumnailSuccess && type === "update") {
      const buffer = Buffer.from(thumbnailData.data.Body.data);
      const base64ImageData = buffer.toString("base64");
      const imageSrc = `data:${thumbnailData.data.ContentType};base64,${base64ImageData}`;
      setThumbnailUrl(imageSrc);
      return imageSrc;
    }
  }, [isUploadThumnailSuccess, type]);

  useEffect(() => {
    if (isSuccess && data?.data && type !== "create") {
      const {
        updatedAt,
        id,
        deletedAt,
        createdAt,
        // recruitmentCalendar,
        ...rest
      } = data?.data;
      console.log(data?.data);

      form.setFieldsValue({
        ...rest,
      });
      handleExistFilesList(data?.data?.files);
    }
  }, [data?.data, isSuccess]);

  const [createApplyWorkService] = useCreateApplyWorkServiceMutation();

  const [uploadFile] = useUploadFileMutation();
  const [deleteFile] = useUploadFileMutation();
  const [getFileMutate] = useGetFileMutateMutation();
  const [updateApplyWorkService] = useUpdateApplyWorkServiceMutation();
  const [deleteApplyWorkService] = useDeleteApplyWorkServiceMutation();

  const handleExistFilesList = async (data: any) => {
    try {
      let arr: any = [];
      await Promise.all(
        data.map(async (file: any) => {
          const res = await getFileMutate({ id: file.id });
          const binaryDataBuffer = new Uint8Array(res.data.data.Body.data);
          const formatedFile = new File([binaryDataBuffer], file.originalName, {
            type: res.data.data.ContentType,
          });
          arr.push(formatedFile);
        })
      );
      setFilesArr(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadThumbnail = async () => {
    try {
      const formData = new FormData();
      formData.append("files", thumbnailUrl);
      const data = await uploadFile(formData).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const handleUploadFile = async () => {
    try {
      const formData = new FormData();
      filesArr?.map((file: any) => {
        formData.append("files", file);
      });
      const data = await uploadFile(formData).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateApplyWorkService = async (body: any) => {
    try {
      const data = await createApplyWorkService(body).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values: ApplyWorkServiceForm | any) => {
    const uploadImageRes = await handleUploadThumbnail();
    const thumbnailId = uploadImageRes?.data?.files[0].id;
    const uploadFileRes = await handleUploadFile();
    const attachFileId = uploadFileRes?.data?.files?.map(
      (item: any) => +item?.id
    );

    if (type === "create") {
      if (thumbnailId) {
        const { thumbnail, file, recruitmentCalendar, ...rest } = values;
        const body = {
          ...rest,
          files: attachFileId || undefined,
          thumnailId: thumbnailId || undefined,
          recruitmentCalendarFrom: recruitmentCalendar[0] || undefined,
          recruitmentCalendarTo: recruitmentCalendar[1] || undefined,
          desctiption: editorRef?.current?.getContent(),
        };
        const response = await handleCreateApplyWorkService(body);
        const code = response.statusCode;
        if (code === 200 || code === 201) {
          dispatch(
            openModal({
              template: "show-info",
              data: {
                info: "작성완료 되었습니다. ",
              },
              actionConfirm: () =>
                navigate({
                  pathname: "/apply-work-service/view",
                  search: createSearchParams({
                    id: response?.data.id,
                  }).toString(),
                }),
            })
          );
        }
      }
    } else if (type === "update") {
      const { thumbnail, file, recruitmentCalendar, ...rest } = values;
      const body = {
        ...rest,
        files: attachFileId || undefined,
        thumnailId: thumbnailId || rest.thumnailId,
        recruitmentCalendarFrom: recruitmentCalendar[0] || undefined,
        recruitmentCalendarTo: recruitmentCalendar[1] || undefined,
        desctiption: editorRef?.current?.getContent(),
        id: searchParams.get("id"),
      };
      const response = await updateApplyWorkService(body).unwrap();
      const code = response.statusCode;
      if (code === 200 || code === 201) {
        dispatch(
          openModal({
            template: "show-info",
            data: {
              info: "작성완료 되었습니다. ",
            },
            actionConfirm: () =>
              navigate({
                pathname: "/apply-work-service/view",
                search: createSearchParams({
                  id: searchParams.get("id") as any,
                }).toString(),
              }),
          })
        );
      }
    }
  };

  const handleImageUpload = (file: any) => {
    setThumbnailUrl(file);
  };

  useEffect(() => {
    if (thumnailLink) form.setFieldValue("thumbnail", thumnailLink);
  }, [thumnailLink]);

  const handleDeleteApplyWorkService = async () => {
    try {
      const data = await deleteApplyWorkService({
        id: searchParams.get("id"),
      }).unwrap();
      const code = data.statusCode;
      if (code === 200 || code === 201) {
        kitToastMessage("bottom", "삭제 완료되었습니다.", "success");
        navigate("/apply-work-service");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDatepickerChange = (date: any) => {};

  // functopm to handle call delete api
  const handleImageDelete = async () => {
    try {
      const data = await deleteFile().unwrap();
      const code = data.statusCode;
      if (code === 200 || code === 201) {
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilUploadChange = (files: any[]) => {
    setFilesArr(Array.from(files));
  };

  const uploadImageHandler = (file: any, progress: any) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("file", file.blob());
        // formData.append("fileName", file.name);
        const res = await fetch(API_LINK + "files", {
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
          },
          body: formData,
        })
          .then((res: any) => res.json())
          .catch((error) => error.json());
        if (res.statusCode > 299) {
          return reject(res);
        }
        return resolve(res?.data?.files[0]?.url);
      } catch (error) {
        return reject("Something went wrong!");
      }
    });

  return (
    <div className="create-aws">
      <div className="action-area">
        지원정책
        <div className="btn-group">
          {(type === "create" || type === "update") && (
            <Button
              disabled={!isFormClicked}
              className="btn-crud--error"
              onClick={() => {
                form.submit();
              }}
            >
              작성완료
            </Button>
          )}
          {type === "view" && (
            <>
              <Button
                className="btn-crud--primary"
                onClick={() =>
                  navigate({
                    pathname: "/apply-work-service/update",
                    search: createSearchParams({
                      id: searchParams.get("id") as any,
                    }).toString(),
                  })
                }
              >
                수정하기
              </Button>
              <Button
                className="btn-crud--error"
                onClick={() =>
                  dispatch(
                    openModal({
                      template: "show-confirm",
                      data: {
                        type: "delete",
                        model: "dmc3",
                        confirmText: "정말 삭제 하시겠습니까?",
                      },
                      actionConfirm: handleDeleteApplyWorkService,
                    })
                  )
                }
              >
                삭제하기
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="form-container">
        <Form
          name="create"
          className="create-aws__form"
          initialValues={initialValues}
          onFinish={onFinish}
          onValuesChange={(values) => {
            if (Object.keys(values)?.length > 0) {
              setIsFormClicked(true);
            }
          }}
          layout={"vertical"}
          form={form}
        >
          <Form.Item
            label="제목"
            name="title"
            className="title form-input"
            rules={[{ required: true, message: "Please input your title!" }]}
          >
            <Input placeholder="제목을 입력해 주세요." disabled={isViewPage} />
          </Form.Item>
          <div className="form-header">
            <Form.Item
              name="thumbnail"
              label="썸네일"
              className="date form-input"
              rules={[
                { required: true, message: "Please input your thumnail!" },
              ]}
            >
              <AvatarUpload
                disabled={isViewPage}
                initialValue={data?.data?.thumnail?.url}
                onChange={handleImageUpload}
              />
            </Form.Item>
            <div className="form-header__input">
              <Form.Item
                name="recruitmentCalendar"
                label="모집일정"
                className="date form-input"
                rules={[
                  { required: true, message: "Please input your Title!" },
                ]}
              >
                <KitDatepicker
                  disabled={isViewPage}
                  initialValue={
                    type !== "create" && [
                      data?.data?.recruitmentCalendarFrom || undefined,
                      data?.data?.recruitmentCalendarTo || undefined,
                    ]
                  }
                  type="rangePicker"
                  onChange={(date: any) => handleDatepickerChange(date)}
                />
              </Form.Item>
              <Form.Item
                label="지원대상"
                name="applicableObject"
                className="object form-input"
                rules={[
                  { required: true, message: "Please input your Object!" },
                ]}
              >
                <Input
                  disabled={isViewPage}
                  placeholder="지원대상을 입력해 주세요."
                />
              </Form.Item>
              <Form.Item
                name="method"
                label="키워드"
                className="method form-input"
                rules={[
                  { required: true, message: "Please input your Method!" },
                ]}
              >
                <Input
                  disabled={isViewPage}
                  placeholder="구분자(,)를 이용하여 키워드를 입력해 주세요"
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            label={generateCustomLable(
              "첨부파일",
              "(선택) * 최대 5개까지 첨부 가능합니다."
            )}
            name="file"
            className="file"
          >
            <MultipleUpload
              injectCss={{ width: "1128px" }}
              initialValue={filesArr}
              isDownload={isViewPage}
              multiple
              disabled={isViewPage}
              onChange={handleFilUploadChange}
            />
          </Form.Item>
          <Form.Item
            label={generateCustomLable("홈페이지", "(선택)")}
            name="url"
            className="connect-link form-input"
          >
            <Input
              disabled={isViewPage}
              placeholder="홈페이지 주소를 입력해 주세요."
            />
          </Form.Item>
          <Form.Item
            label="내용"
            name="desctiption"
            className="title form-input"
            rules={[
              { required: true, message: "Please input your desctiption!" },
            ]}
          >
            <Editor
              scriptLoading={{
                async: true,
              }}
              disabled={isViewPage}
              value={form.getFieldValue("desctiption")}
              onInit={(_: any, editor: any) => (editorRef.current = editor)}
              apiKey={KEY_EDITOR}
              onEditorChange={(e) => {
                form.setFieldValue("desctiption", e);
                setIsFormClicked(true);
              }}
              plugins="advlist autolink lists link image charmap preview
                            searchreplace visualblocks code fullscreen 
                               paste code help table"
              ref={editorRef}
              init={{
                height: 500,
                toolbar:
                  " formatselect | undo redo bold italic backcolor forecolor| \
                    alignleft aligncenter alignright alignjustify| \
                    bullist numlist outdent indent removeformat| image video",
                images_upload_handler: uploadImageHandler,
                branding: false,
                language: "ko_KR",
                placeholder: "내용을 입력해 주세요.",
              }}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default CreateApplyWorkAction;
