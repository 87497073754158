import React, { useState, useRef, useEffect, useMemo } from "react";
import "./style.scss";
import { Button, Form, Input, Select, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  useGetTalentAgencyInfoDetailQuery,
  useUpdateTalentAgencyInfoMutation,
} from "../../talentAgencyInfoApi";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TalentAgencyInfoForm } from "../../@type";
import { useUploadFileMutation } from "../../../../components/imageUpload/fileUploadApi";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../components/modal/modalSlice";

interface TalentAgencyInfoUpdateProps {}

function TalentAgencyInfoUpdate(props: TalentAgencyInfoUpdateProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formRef: any = useRef<any>(null);
  const [fileUrl, setFileUrl] = useState<any>([]);
  const uploadRef = useRef<any>(null);
  const [form] = Form.useForm();

  const { data, isLoading, isSuccess } = useGetTalentAgencyInfoDetailQuery(
    { businessId: searchParams.get("id") },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const files = useMemo(() => {
    if (isSuccess) {
      return {
        thumbnailId: data.data.thumnailId,
      };
    }
  }, [isSuccess, data]);

  const [uploadFile] = useUploadFileMutation();
  const [updateTalentAgencyInfo] = useUpdateTalentAgencyInfoMutation();

  const hanldeConfirmEdit = () => {
    form.submit();
  };

  const handleUploadThumbnail = async () => {
    try {
      const formData = new FormData();
      formData.append("files", fileUrl[0]);
      const data = await uploadFile(formData).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    const {
      roles,
      goal,
      subAddress,
      trainingGoals,
      businessTraining,
      thumnailId,
      education,
      trainingHuman,
      ...rest
    } = values;
    const body = {
      ...rest,
      roles: roles,
      trainingGoals,
      businessTraining,
      trainingHuman,
      businessId: searchParams.get("id"),
    };
    const code = await updateTalentAgencyInfo(body).unwrap();
    if (
      (code.statusCode === 200 || code.statusCode === 201) &&
      fileUrl.length > 0
    ) {
      const uploadImageRes = await handleUploadThumbnail();
      if (
        uploadImageRes.statusCode === 200 ||
        uploadImageRes.statusCode === 201
      ) {
        const code = await updateTalentAgencyInfo({
          ...body,
          thumnailId: uploadImageRes.data.files[0].id,
        }).unwrap();
        if (code.statusCode === 200 || code.statusCode === 201) {
          dispatch(
            openModal({
              template: " ",
              data: {
                info: "수정완료 되었습니다.",
              },
              actionConfirm: () =>
                navigate({
                  pathname: "/talent-agency-information/detail",
                  search: createSearchParams({
                    id: searchParams.get("id") as any,
                  }).toString(),
                }),
            })
          );
        }
      }
    }
    if (code.statusCode === 201 || code.statusCode === 200) {
      dispatch(
        openModal({
          template: "show-info",
          data: {
            info: "수정완료 되었습니다.",
          },
          actionConfirm: () =>
            navigate({
              pathname: "/talent-agency-information/detail",
              search: createSearchParams({
                id: searchParams.get("id") as any,
              }).toString(),
            }),
        })
      );
    }
  };

  const initialValues: Partial<TalentAgencyInfoForm> = {
    homepage: "",
    roles: "",
    trainingGoals: "",
    trainingProcess: "",
    businessTraining: {
      title: "",
      description: "",
      trainingPeriod: "",
      trainingTime: "",
      trainingHuman: "",
    },
    businessType: 1,
    picName: "",
    picPosition: "",
    picNumber: "",
    picEmail: "",
  };

  useEffect(() => {
    if (!isLoading && data.data) {
      const {
        roles,
        trainingGoals,
        business,
        thumnail,
        picNumber,
        picName,
        picPosition,
        picEmail,
        businessType,
        trainingProcess,
        businessTraining,
        homepage,
      } = data.data;

      form.setFieldsValue({
        roles: roles,
        trainingGoals: trainingGoals,
        businessName: business.businessName,
        picNumber,
        picName,
        picPosition,
        picEmail,
        thumnailId: thumnail?.originalName,
        trainingProcess,
        homepage: business.homepage,
        zipcode: business.zipcode,
        address: business.address,
        businessType: businessType.toString(),
        subAddress: business.detailAddress,
        businessTraining: businessTraining.map(
          ({
            id,
            title,
            description,
            trainingPeriod,
            trainingTime,
            trainingHuman,
          }: any) => ({
            id,
            title: title,
            description: description,
            trainingPeriod: trainingPeriod,
            trainingTime: trainingTime,
            trainingHuman: trainingHuman,
          })
        ),
      });
    }
  }, [data, isLoading]);

  function execDaumPostcode() {
    const wd: any = window;
    if (wd?.daum)
      new wd.daum.Postcode({
        oncomplete: function (data: any) {
          console.log(data);
          if (data?.zonecode) {
            form.setFieldValue("zipcode", data.zonecode);
          }
          if (data?.address) {
            form.setFieldValue("address", data.address);
          }
        },
      }).open();
  }

  const fileName = useMemo(() => {
    if (data?.data) {
      return data?.data?.thumnail?.originalName;
    }
  }, [data?.data]);

  return (
    <div className="talent-agency-info-update">
      <div
        className={`talent-agency-info-update__header ${
          true ? "is-admin-page" : null
        }`}
      >
        <div className="title-container">
          <p className="title">인재 기관정보 상세 </p>
        </div>
      </div>

      <div className="talent-agency-info-update__body">
        <div className="body-action-area">
          <Button className="btn-crud--primary" onClick={hanldeConfirmEdit}>
            수정하기
          </Button>
        </div>
        {data?.data?.thumbnail?.originName}
        <div className="form-wrapper">
          <Form
            ref={formRef}
            className="form-business-update"
            initialValues={initialValues}
            onFinish={onFinish}
            layout={"vertical"}
            form={form}
          >
            <div className="form-block-zero">
              <div className="block-title">담당자 정보</div>
              <div className="percent-30">
                <div className="double-field">
                  <Form.Item
                    label="담당자명"
                    name="picName"
                    className="form-item"
                    rules={[{ required: true, message: "Field is required!" }]}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="담당자부서"
                    name="picPosition"
                    className="form-item"
                    rules={[{ required: true, message: "Field is required!" }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </div>
                <Form.Item
                  label="담당자연락처"
                  name="picNumber"
                  className="form-item"
                  rules={[{ required: true, message: "Field is required!" }]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="담당자이메일"
                  name="picEmail"
                  className="form-item"
                  rules={[{ required: true, message: "Field is required!" }]}
                >
                  <Input disabled />
                </Form.Item>
              </div>
            </div>
            <div className="form-block-one">
              <div className="block-title">기관 정보</div>
              <div className="form-block-one-wrapper">
                <div className="percent-50">
                  <Form.Item
                    label="기관 이미지 첨부"
                    name="thumnailId"
                    className="form-item"
                  >
                    <div className="input-wrapper">
                      <div className="file-area">
                        <p className="file-name-area">
                          {fileUrl[0] ? fileUrl[0]?.name : fileName}
                        </p>
                        <input
                          multiple={false}
                          ref={uploadRef}
                          style={{ display: "none" }}
                          type="file"
                          onChange={(e) => setFileUrl(e.target.files)}
                        />
                      </div>
                      <Button
                        className="btn-upload-file"
                        onClick={() => uploadRef.current.click()}
                      >
                        파일 첨부{" "}
                      </Button>
                    </div>
                  </Form.Item>
                  <Form.Item
                    label="기관명"
                    name="businessName"
                    className="form-item"
                    rules={[{ required: true, message: "Field is required!" }]}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="대표전화"
                    name="picNumber"
                    className="form-item"
                    rules={[{ required: true, message: "Field is required!" }]}
                  >
                    <Input disabled placeholder="정보를 입력해주세요." />
                  </Form.Item>
                  <Form.Item
                    label="역할"
                    name="roles"
                    className="form-item"
                    rules={[{ required: true, message: "Field is required!" }]}
                  >
                    <Input placeholder="정보를 입력해주세요." />
                  </Form.Item>
                  <Form.Item
                    label="교육훈련 목표"
                    name="trainingGoals"
                    className="form-item"
                    rules={[{ required: true, message: "Field is required!" }]}
                  >
                    <Input placeholder="정보를 입력해주세요." />
                  </Form.Item>
                  <Form.Item
                    label="주요훈련 과정"
                    name="trainingProcess"
                    className="form-item"
                    rules={[{ required: true, message: "Field is required!" }]}
                  >
                    <Input placeholder="정보를 입력해주세요." />
                  </Form.Item>
                </div>
                <div className="percent-50">
                  <Form.Item
                    className="form-item  form-item-zipcode-wrapper"
                    label="기관 주소"
                    rules={[{ required: true, message: "Field is required!" }]}
                  >
                    {/* <div className="input-wrapper">
                      <Form.Item className="form-item form-item-zipcode" name="zipcode">
                        <Input className="ip" placeholder="우편번호" />
                      </Form.Item>
                      <Button className={`verify active btn-upload-file`} onClick={execDaumPostcode}>
                        주소 검색
                      </Button>
                    </div> */}
                    <Form.Item
                      className="form-item form-item-zipcode"
                      name="zipcode"
                    >
                      <Input disabled className="ip" placeholder="우편번호" />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item name="address" className="form-item">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item name="subAddress" className="form-item">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="기관 홈페이지"
                    name="homepage"
                    className="form-item"
                  >
                    <Input disabled placeholder="url을 입력해주세요." />
                  </Form.Item>
                  <Form.Item
                    label="기관 형태"
                    name="businessType"
                    className="form-item form-item-select"
                    rules={[{ required: true, message: "Field is required!" }]}
                  >
                    <Select
                      placeholder="년도"
                      className="select-item-5-7"
                      options={[
                        {
                          value: "1",
                          label: "직업훈련기관",
                        },
                        {
                          value: "2",
                          label: "대학",
                        },
                        {
                          value: "3",
                          label: "특성화고",
                        },
                        {
                          value: "4",
                          label: "기타",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="form-block-two">
              <Form.List name="businessTraining">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <>
                        <div className="block-title">
                          <p>교육 정보</p>
                          {key === 0 ? (
                            <p onClick={() => add()}>추가하기</p>
                          ) : (
                            <p>
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  remove(name);
                                }}
                              >
                                삭제하기 &nbsp;{" "}
                              </span>
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  add();
                                }}
                              >
                                추가하기
                              </span>
                            </p>
                          )}
                        </div>
                        <div className="percent-100">
                          <Form.Item
                            {...restField}
                            label="교육 제목"
                            name={[name, "title"]}
                            className="form-item"
                          >
                            <Input placeholder="교육 제목을 입력해주세요" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="교육 내용"
                            name={[name, "description"]}
                            className="form-item"
                          >
                            <Input placeholder="교육 내용을 입력해주세요" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="훈련기간"
                            name={[name, "trainingPeriod"]}
                            className="form-item"
                          >
                            <Input placeholder="기간 입력" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="훈련 시간"
                            name={[name, "trainingTime"]}
                            className="form-item"
                          >
                            <Input placeholder="시간 입력" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="훈련 인원"
                            name={[name, "trainingHuman"]}
                            className="form-item"
                          >
                            <Input placeholder="시간 입력" />
                          </Form.Item>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default TalentAgencyInfoUpdate;
