/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";
import queryString from "query-string";

export const recruitmentInformationApi = createApi({
  reducerPath: "recruitmentInformation",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    },
  }),
  tagTypes: ["getRecruitmentInformationList"],
  endpoints: (builder) => ({
    getRecruitmentInformationList: builder.query({
      query: (data) => ({
        url: `innovation/v3/program`,
        method: "GET",
        params: data,
      }),
      providesTags: ["getRecruitmentInformationList"],
    }),
    getRecruitmentInformationDetail: builder.query({
      query: (data) => ({
        url: `innovation/v3/program/${data.innovationId}`,
        method: "GET",
      }),
      providesTags: ["getRecruitmentInformationList"],
    }),
    deleteRecruitmentInformation: builder.mutation({
      query: (data) => ({
        url: `innovation/v3/program/${data.innovationId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getRecruitmentInformationList"],
    }),

    getApplyList: builder.query({
      query: (params) => {
        const { status, ...rest } = params;
        const statusQuery = status
          ?.map((s, i) => `${i === 0 ? "?" : "&"}status[]=${s}`)
          ?.join("");
        return {
          url: statusQuery
            ? `users/program/apply${statusQuery}`
            : `users/program/apply`,
          method: "GET",
          params: { ...rest },
        };
      },
    }),
  }),
});

export const {
  useGetRecruitmentInformationListQuery,
  useGetRecruitmentInformationDetailQuery,
  useDeleteRecruitmentInformationMutation,
  useGetApplyListQuery,
} = recruitmentInformationApi;
