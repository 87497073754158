export interface InnovationObj {
  address: string;
  detailAddress: string;
  business?: BusinessObj;
  zipcode: string;
  companyName: string;
  representativeName: string;
  representativeCode: string;
  labourNumber: number;
  career: string;
  detailCareer: string;
  foundationDate: string;
  homepage: string;
  businessCode: string;
  detailCareer2: string;
  companySize: string;
  logoFiles?: { url: string; originalName: string; id: number }[];
  companyInfoFiles?: { url: string; originalName: string; id: number }[];
  businessCodeFiles: { url: string; originalName: string; id: number }[];
  revenue2022: number;
  enterpriseSize: string;
  businessForm: string;
  keyword: string;
  description: string;
  services: string;
  companyInfo: string[];
  dmcType: "v3" | "v2";
  detailService: string;
  product: string;
  detailProduct: string;
  picName: string;
  picPosition: string;
  phoneNumber: string;
  id?: number | null;
}

export enum ResidentForm {
  products = "products",
  thumbnailId = "thumbnailId",
  included = "included",
  address = "address",
  detailAddress = "detailAddress",
  business = "business",
  zipcode = "zipcode",
  companyName = "companyName",
  representativeName = "representativeName",
  representativeCode = "representativeCode",
  labourNumber = "labourNumber",
  career = "career",
  detailCareer = "detailCareer",
  foundationDate = "foundationDate",
  homepage = "homepage",
  businessCode = "businessCode",
  detailCareer2 = "detailCareer2",
  companySize = "companySize",
  logoFiles = "logoFiles",
  companyInfoFiles = "companyInfoFiles",
  businessCodeFiles = "businessCodeFiles",
  revenue2022 = "revenue2022",
  enterpriseSize = "enterpriseSize",
  businessForm = "businessForm",
  keyword = "keyword",
  description = "description",
  services = "services",
  companyInfo = "companyInfo",
  dmcType = "dmcType",
  detailService = "detailService",
  product = "product",
  detailProduct = "detailProduct",
  picName = "picName",
  picPosition = "picPosition",
  phoneNumber = "phoneNumber",
  industryClassification1 = "industryClassification1",
  industryClassification2 = "industryClassification2",
  industryClassification3 = "industryClassification3",
  id = "id",
  year = "year",
  month = "month",
  day = "day",
}

export interface BusinessObj {
  id: number;
  status: string;
  businessName: string;
  registrationNumber: string;
  representativeName: string;
  labourNumber: 563;
  zipcode: string;
  address: string;
  detailAddress: string;
  phoneNumber: number | null;
  homepage: number;
  username: string;
  userPosition: string;
  userPhoneNumber: string;
  userEmail: string;
  firstCareer: string;
  secondCareer: string;
  companyNumber: null;
  foundationDate: string;
  standardDate: string;
  dmcType: string;
  services: string;
  career: string;
  businessClassify: number;
}
