/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const FAQApi = createApi({
  reducerPath: "FAQ",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["GetFAQ"],
  endpoints: (builder) => ({
    createFAQ: builder.mutation({
      query: (data) => ({
        url: `communication/qna`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetFAQ"],
    }),
    updateFAQ: builder.mutation({
      query: (data) => {
        return {
          url: `communication/qna/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["GetFAQ"],
    }),
    deleteFAQ: builder.mutation({
      query: (data) => ({
        url: `communication/qna/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetFAQ"],
    }),
    getQna: builder.query({
      query: (data) => ({
        url: `communication/qna`,
        method: "GET",
        params: data,
      }),
      providesTags: ["GetFAQ"],
    }),
    getFAQDetail: builder.query({
      query: (data) => ({
        url: `communication/qna/${data.id}`,
        method: "GET",
      }),
      providesTags: ["GetFAQ"],
    }),
  }),
});

export const {
  useCreateFAQMutation,
  useUpdateFAQMutation,
  useDeleteFAQMutation,
  useGetQnaQuery,
  useGetFAQDetailQuery,
} = FAQApi;
