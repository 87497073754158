import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState, useRef, useEffect } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  useCreateAnnoucementMutation,
  useDeleteAnnoucementMutation,
  useGetAnnoucementDetailQuery,
  useUpdateAnnoucementMutation,
} from "../../../Announcement/annoucementApi";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../components/modal/modalSlice";
import { kitToastMessage } from "../../../../components/toastMessage";
import { CommunicationType } from "../../../../utils/types/table";
import "./style.scss";
import {
  useCreateFAQMutation,
  useDeleteFAQMutation,
  useGetFAQDetailQuery,
  useUpdateFAQMutation,
} from "../../faqApi";

function FAQAction() {
  const { TextArea } = Input;
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = useForm();
  const initialValues = { question: "", answer: "" };
  const isUpdatePage = searchParams.get("type") === "update";
  const isCreatePage = searchParams.get("type") === "create";
  const isViewPage = searchParams.get("type") === "view";

  const { data, isSuccess } = useGetFAQDetailQuery(
    {
      id: searchParams.get("id"),
    },
    { skip: !searchParams.get("id") || isCreatePage }
  );

  const [createFAQ] = useCreateFAQMutation();
  const [updateFAQ] = useUpdateFAQMutation();
  const [deleteFAQ] = useDeleteFAQMutation();

  useEffect(() => {
    if (isSuccess) {
      form.setFieldsValue(data?.data);
    }
  }, [data?.data, isSuccess]);

  const handleAnnoucementCreate = async (body: any) => {
    try {
      const data = await createFAQ(body).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleModalClose = () => {
    navigate({
      pathname: "",
      search: createSearchParams({
        id: searchParams.get("id") as any,
        type: "view",
      }).toString(),
    });
  };

  const handleAnnoucementEdit = async (body: any) => {
    try {
      const data = await updateFAQ(body).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    if (isUpdatePage) {
      const body = {
        ...values,
        id: searchParams.get("id"),
      };
      const response = await handleAnnoucementEdit(body);
      if (response.statusCode === 200 || response.statusCode === 201) {
        dispatch(
          openModal({
            template: "show-info",
            data: {
              info: "작성완료 되었습니다.",
            },
            actionConfirm: () =>
              navigate({
                pathname: "",
                search: createSearchParams({
                  id: response?.data?.id,
                  type: "view",
                }).toString(),
              }),
          })
        );
      }
    }
    if (isCreatePage) {
      const response = await handleAnnoucementCreate(values);

      if (response.statusCode === 200 || response.statusCode === 201) {
        dispatch(
          openModal({
            template: "show-info",
            data: {
              info: "작성완료 되었습니다.",
            },
            actionConfirm: () =>
              navigate({
                pathname: "",
                search: createSearchParams({
                  id: response?.data?.id,
                  type: "view",
                }).toString(),
              }),
          })
        );
      }
    }
  };

  const handleCallApiDelete = async () => {
    try {
      const res: any = await deleteFAQ({
        id: searchParams.get("id"),
      }).unwrap();
      console.log(res);

      if (res.statusCode === 200 || res.statusCode === 201) {
        kitToastMessage("bottom", "삭제 완료되었습니다.", "success");
        navigate("/event");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRecordDelete = () => {
    dispatch(
      openModal({
        template: "show-confirm",
        data: {
          type: "delete",
          model: "dmc3",
          confirmText: "정말로 삭제하시겠습니까?",
        },
        actionConfirm: handleCallApiDelete,
        actionCancel: handleModalClose,
      })
    );
  };

  return (
    <div className="annoucement-action">
      <div className="annoucement-action-top-wrapper">
        <div className="title-group">
          <p className="title">참여소통</p> <span className="separate">-</span>{" "}
          <p className="sub-title">FAQ</p>
        </div>
        {isCreatePage || isUpdatePage ? (
          <Button className="btn--complete" onClick={() => form.submit()}>
            작성완료
          </Button>
        ) : (
          <div className="action-group">
            <Button
              className="btn--edit"
              onClick={() =>
                navigate({
                  pathname: "",
                  search: createSearchParams({
                    id: searchParams.get("id") as any,
                    type: "update",
                  }).toString(),
                })
              }
            >
              수정하기
            </Button>
            <Button className="btn--delete" onClick={handleRecordDelete}>
              삭제하기
            </Button>
          </div>
        )}
      </div>
      <div className="annoucement-action-body">
        <div className="form-container">
          <Form
            name="create"
            className="annoucement-action__form"
            initialValues={initialValues}
            onFinish={onFinish}
            layout={"vertical"}
            form={form}
          >
            <Form.Item
              label="질문"
              name="question"
              className="title form-input"
            >
              <Input
                placeholder="질문을 입력해 주세요."
                disabled={isViewPage}
              />
            </Form.Item>
            <Form.Item label="답변" name="answer" className="title form-input">
              <TextArea
                disabled={isViewPage}
                className="textarea-custom"
                placeholder="답변내용을 입력해 주세요."
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default FAQAction;
