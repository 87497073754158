import { useEffect, useState, useMemo, useRef } from "react";
import { Button, Input, Select, Table } from "antd";
import "./style.scss";
import { PAGE_SIZE, tableLocale } from "../../utils/constants";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useGetMemberManagementListQuery } from "./memberManagementApi";
import { DefaultFilterOptions } from "../../utils/types/table";
import { truncateText } from "../../utils/commonBlock";
import { highlightKeywords } from "../../utils/commonFunc";

function MemberManagement() {
  const submitBtnRef = useRef<any>(null);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [optionValue, setOptionValue] = useState<string>("0");

  const defaultFilterOptions = {
    value: searchValue,
    page: 1,
    limit: PAGE_SIZE,
    approved: 1,
  };
  const [filterOptions, setfilterOptions] =
    useState<DefaultFilterOptions>(defaultFilterOptions);

  const { data, isLoading, isSuccess } = useGetMemberManagementListQuery(
    filterOptions,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (searchValue.length === 0) {
      setfilterOptions(defaultFilterOptions);
    }
  }, [searchValue]);

  const mapUserSpecify = (value: number) => {
    let role;
    switch (value) {
      case 1:
        role = "개인";
        break;
      case 2:
        role = "기업";
        break;
      case 3:
        role = "기관";
        break;
      default:
        role = "";
        break;
    }
    return role;
  };

  const renderSituation = (status) => {
    let value, className;
    if (status === "ACTIVE") {
      value = "정상";
      className = "ACTIVE";
    } else if (status === "REMOVED") {
      value = "탈퇴";
      className = "REMOVED";
    } else if (status === "SUSPENDED") {
      value = "정지";
      className = "SUSPENDED";
    } else {
      value = "정상";
      className = "ACTIVE";
    }

    return { value, className };
  };

  const columns = useMemo(() => {
    return [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
        width: "10%",
        render: (item: any, record: any, index: number) => {
          return index + 1 + (filterOptions?.page - 1) * 10;
        },
      },
      {
        title: "분류",
        dataIndex: "userClassify",
        key: "userClassify",
        width: "15%",
        render: (item: number) => {
          return mapUserSpecify(item);
        },
      },
      {
        title: "ID",
        dataIndex: "email",
        key: "email",
        width: "25%",
        render: (item: any) => {
          return truncateText("400px", { item, value: filterOptions.value });
        },
      },
      {
        title: "연락처",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        width: "25%",
        render: (item: string) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: highlightKeywords(item, searchValue),
              }}
            ></div>
          );
        },
      },
      {
        title: "상태",
        dataIndex: "status",
        key: "status",
        width: "15%",
        render: (item: any, record: any) => {
          return (
            <span
              className={`with-draw-status--${renderSituation(item).className}`}
            >
              {renderSituation(item).value}
            </span>
          );
        },
      },
      {
        title: "상세정보",
        width: 200,
        render: (item: any, record: any) => {
          return (
            <Button
              className="btn-lookup"
              onClick={() =>
                navigate({
                  pathname: "view",
                  search: createSearchParams({
                    id: record.id,
                  }).toString(),
                })
              }
            >
              조회
            </Button>
          );
        },
      },
    ];
  }, [data?.data, filterOptions]);

  const handleChangeOption = (value: string) => {
    setOptionValue(value);
    if (value === "0") {
      const { userClassify, ...rest } = filterOptions;
      setfilterOptions(rest);
    } else {
      setfilterOptions({ ...filterOptions, userClassify: [value] });
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      submitBtnRef?.current?.click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="member-management">
      <div className="member-management__header">
        <div className="search-area">
          <p className="search-area--title">회원관리</p>
          <div className="search-input">
            <Select
              className="select-area"
              defaultValue={"0"}
              value={optionValue}
              options={[
                { value: "0", label: "전체" },
                { value: "2", label: "기업" },
                { value: "3", label: "기관" },
                { value: "1", label: "개인" },
              ]}
              onChange={handleChangeOption}
            />
            <Input
              className="input-area"
              value={searchValue}
              placeholder="검색어를 입력해 주세요."
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button
              ref={submitBtnRef}
              disabled={!searchValue}
              className="btn-crud--primary btn-search"
              onClick={() =>
                setfilterOptions({
                  ...filterOptions,
                  value: searchValue,
                  page: 1,
                  limit: 10,
                })
              }
            >
              검색
            </Button>
          </div>
        </div>
      </div>
      <div className="member-management__body">
        {!isLoading && filterOptions.value.length > 0 && (
          <div className="result-area">
            <div className="result-area__title">
              ‘{filterOptions.value}’&nbsp;
              <div className="result-area__sub-title">
                로 검색한 결과입니다.
              </div>
            </div>
            <div className="result-area__counter">
              총 {data?.data?.metaData?.totalRecords}건
            </div>
          </div>
        )}
        <Table
          locale={tableLocale}
          dataSource={data?.data?.result}
          pagination={
            data?.data?.metaData?.totalRecords > 10 && {
              position: ["bottomCenter"],
              current: filterOptions.page,
              showSizeChanger: false,
              defaultPageSize: PAGE_SIZE,
              total: data?.data?.metaData.totalRecords || 0,
            }
          }
          loading={isLoading}
          columns={columns}
          rowKey={(record: any) => record.id}
          onChange={(page: any) => {
            setfilterOptions({
              ...filterOptions,
              value: searchValue,
              page: page?.current,
              limit: PAGE_SIZE,
            });
          }}
        />
      </div>
    </div>
  );
}

export default MemberManagement;
