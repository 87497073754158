import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Input, Select, Table } from "antd";
import { useSelector } from "react-redux";
import { getUserLogin } from "../../store/selector/RootSelector";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../../components/modal/modalSlice";
import { Excel } from "antd-table-saveas-excel";
import { useGetInnovationV3PlatformQuery } from "./innovationV3PlatformApi";
import moment from "moment";
import { IDMCType, MainProps } from "./@type";
const PAGE_SIZE = 10;

export default function Main({ dmcType }: MainProps) {
  const [selectedOption, setselectedOption] = useState("기업명");
  const [selectedRadio, setselectedRadio] = useState("ACTIVE");
  const [sort, setsort] = useState("DESC");
  const [filterOptions, setfilterOptions] = useState({
    page: 1,
    limit: PAGE_SIZE,
    status: selectedRadio,
    order: sort,
  });
  const { data, isLoading, isSuccess } = useGetInnovationV3PlatformQuery(
    { ...filterOptions, dmcType },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: listAllInnovation } = useGetInnovationV3PlatformQuery(
    {
      ...filterOptions,
      limit: PAGE_SIZE,
      dmcType,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !isSuccess,
    }
  );

  const dispatch = useDispatch<any>();
  const [pageNum, setpageNum] = useState(1);
  const loginStatus = useSelector(getUserLogin);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);

  useEffect(() => {
    if (isSuccess) {
      console.log("data", data);
    }
    if (!loginStatus) {
      navigate("/auth");
    }

    return () => {};
  }, [loginStatus]);

  const columns = [
    {
      title: "기업명",
      dataIndex: "businessName",
      key: "businessName",
      render: (_value: any, record: any) => {
        return record.business?.businessName;
      },
    },
    {
      title: "대표자명",
      dataIndex: "representativeName",
      key: "representativeName",
    },
    {
      title: "사업자 등록번호",
      dataIndex: "registrationNumber",
      key: "registrationNumber",
      width: 100,
      render: (_value: any, record: any) => {
        return (
          <div
            style={{
              width: 50,
            }}
          >
            {record.business?.registrationNumber}
          </div>
        );
      },
    },
    {
      title: "담당자 성명",
      dataIndex: "username",
      key: "username",
      render: (_value: any, record: any) => {
        return (
          <div
            style={{
              width: 50,
            }}
          >
            {record.business?.username}
          </div>
        );
      },
    },
    {
      title: "담당자 연락처",
      dataIndex: "userPhoneNumber",
      key: "userPhoneNumber",
      render: (_value: any, record: any) => {
        return record.business?.userPhoneNumber;
      },
    },
    {
      title: "담당자 이메일",
      dataIndex: "userEmail",
      key: "userEmail",
      render: (_value: any, record: any) => {
        return record.business?.userEmail;
      },
    },
    {
      title: "등록일시",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_value: any, record: any) => {
        return (
          <div
            style={{
              width: 80,
            }}
          >
            {moment(record?.createdAt).format("YY.MM.DD. HH:ss")}
          </div>
        );
      },
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: any) => {
        return (
          <div>
            {record?.status === "ACTIVE" ? (
              <div>상태</div>
            ) : record?.status === "CHOSEN" ? (
              <div
                style={{
                  color: "#4584FF",
                }}
              >
                선정
              </div>
            ) : (
              <div
                style={{
                  color: "#BF0403",
                }}
              >
                미선정
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "상세정보",
      dataIndex: "business",
      key: "business",
      render: (item: any, object: any) => {
        return (
          <div
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(
                openModal({
                  template: "show-company-modal",
                  data: {
                    company: {
                      ...object.business,
                      ...object,
                      files: object?.files
                        ? object?.files
                        : [
                            ...object?.companyInfoFiles,
                            ...object?.documentFiles,
                          ],
                    },
                  },
                  // actionConfirm: onLoadData,
                })
              );
            }}
          >
            상세정보 조회
          </div>
        );
      },
    },
  ];

  const columnsExcel = [
    {
      title: "기업명",
      dataIndex: "businessName",
      key: "businessName",
      render: (_value: any, record: any) => {
        return record.business?.businessName;
      },
    },
    {
      title: "대표자명",
      dataIndex: "representativeName",
      key: "representativeName",
    },
    {
      title: "사업자 등록번호",
      dataIndex: "registrationNumber",
      key: "registrationNumber",
      render: (_value: any, record: any) => {
        return record.business?.registrationNumber;
      },
    },
    {
      title: "담당자 성명",
      dataIndex: "username",
      key: "username",
      render: (_value: any, record: any) => {
        return record.business?.username;
      },
    },
    {
      title: "담당자 연락처",
      dataIndex: "userPhoneNumber",
      key: "userPhoneNumber",
      render: (_value: any, record: any) => {
        return record.business?.userPhoneNumber;
      },
    },
    {
      title: "담당자 이메일",
      dataIndex: "userEmail",
      key: "userEmail",
      render: (_value: any, record: any) => {
        return record.business?.userEmail;
      },
    },
    {
      title: "등록일시",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_value: any, record: any) => {
        return moment(record?.createdAt).format("YY.MM.DD. HH:ss");
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRow(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleExportExcel = () => {
    console.log("selectedRow", selectedRow);
    console.log("columns", columns);

    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columnsExcel)
      .addDataSource(selectedRow, {
        str2Percent: true,
      })
      .saveAs("ListBusiness.xlsx");
  };

  const handleExportExcelAll = () => {
    console.log("selectedRow", listAllInnovation?.data?.result);
    console.log("columns", columns);

    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columnsExcel)
      .addDataSource(listAllInnovation?.data?.result, {
        str2Percent: true,
      })
      .saveAs("ListBusiness.xlsx");
  };

  const handleCopyPhone = () => {
    if (selectedRow.length > 0) {
      let textCopy = "";
      for (let index = 0; index < selectedRow.length; index++) {
        const element = selectedRow[index];
        if (element.business?.userPhoneNumber) {
          textCopy = textCopy + "" + element.business?.userPhoneNumber + ",";
        }
      }
      navigator.clipboard.writeText(textCopy);
      dispatch(
        openModal({
          template: "show-info",
          data: {
            info: "연락처 복사가 완료 되었습니다.",
          },
          // actionConfirm: onLoadData,
        })
      );
    }
  };

  const handleCopyEmail = () => {
    if (selectedRow.length > 0) {
      let textCopy = "";
      for (let index = 0; index < selectedRow.length; index++) {
        const element = selectedRow[index];
        if (element.business?.userEmail) {
          textCopy = textCopy + "" + element.business?.userEmail + ",";
        }
      }
      navigator.clipboard.writeText(textCopy);
      dispatch(
        openModal({
          template: "show-info",
          data: {
            info: "이메일 복사가 완료 되었습니다.",
          },
          // actionConfirm: onLoadData,
        })
      );
    }
  };

  const handleChangeOption = (value: string) => {
    // console.log(`selected ${value}`);
    if (value !== selectedOption) {
      setselectedOption(value);
    }
  };

  return (
    <div className="main">
      <div className="header">
        <p className="title">신청기업 조회</p> -{" "}
        <p className="sub-title">
          {dmcType === IDMCType.MATCHING_DAY
            ? "일자리 매칭데이"
            : "DMC 500캠프"}
        </p>
      </div>
      <div className="search-area">
        <Select
          className="option"
          defaultValue={"기업명"}
          options={[
            { value: "기업명", label: "기업명" },
            { value: "대표자", label: "대표자" },
            { value: "담당자 성명", label: "담당자 성명" },
          ]}
          onChange={handleChangeOption}
        ></Select>
        <Input className="input-area" id="searchBox" />
        <Button
          className="btn-search"
          onClick={() => {
            setfilterOptions({
              page: 1,
              limit: PAGE_SIZE,
              status: selectedRadio,
              //@ts-ignore
              businessName:
                selectedOption == "기업명" //@ts-ignore
                  ? document.getElementById("searchBox").value
                  : "",
              representativeName:
                selectedOption == "대표자" //@ts-ignore
                  ? document.getElementById("searchBox").value
                  : "",
              username:
                selectedOption == "담당자 성명" //@ts-ignore
                  ? document.getElementById("searchBox").value
                  : "",
            });
          }}
        >
          검색
        </Button>
      </div>
      <div className="filter-area">
        <div className="radio-group-filter ">
          <div
            className="radio-item"
            onClick={() => {
              setselectedRadio("ACTIVE");
              setfilterOptions({
                page: 1,
                limit: PAGE_SIZE,
                status: "ACTIVE",
                order: sort,
              });
            }}
          >
            <img
              src={
                selectedRadio === "ACTIVE"
                  ? "/assets/images/radioSelect.svg"
                  : "/assets/images/radioUnSelect.svg"
              }
            />
            <div className="radio-content">대기</div>
          </div>
          <div
            className="radio-item"
            onClick={() => {
              setselectedRadio("CHOSEN");
              setfilterOptions({
                page: 1,
                limit: PAGE_SIZE,
                status: "CHOSEN",
                order: sort,
              });
            }}
          >
            <img
              src={
                selectedRadio === "CHOSEN"
                  ? "/assets/images/radioSelect.svg"
                  : "/assets/images/radioUnSelect.svg"
              }
            />
            <div className="radio-content">선정</div>
          </div>
          <div
            className="radio-item"
            onClick={() => {
              setselectedRadio("NOT_CHOSEN");
              setfilterOptions({
                page: 1,
                limit: PAGE_SIZE,
                status: "NOT_CHOSEN",
                order: sort,
              });
            }}
          >
            <img
              src={
                selectedRadio === "NOT_CHOSEN"
                  ? "/assets/images/radioSelect.svg"
                  : "/assets/images/radioUnSelect.svg"
              }
            />
            <div className="radio-content">미선정</div>
          </div>
          <div
            style={{
              height: 20,
              fontSize: 20,
              lineHeight: "20px",
              color: "#EEEEEE",
              marginRight: "16px",
            }}
          >
            |
          </div>
          <div
            style={{
              fontSize: 14,
              fontWeight: 600,
              cursor: "pointer",
            }}
            onClick={() => {
              if (sort === "ASC") {
                setsort("DESC");
                setfilterOptions({
                  ...filterOptions,
                  order: "DESC",
                });
              }
              if (sort === "DESC") {
                setsort("ASC");
                setfilterOptions({
                  ...filterOptions,
                  order: "ASC",
                });
              }
            }}
          >
            등록일시 오름차순{" "}
            <img src="/assets/images/sort.png" width={24} height={24} />
          </div>
        </div>
        <div className="btn-group-task">
          <Button
            className="btn-export"
            onClick={handleExportExcelAll}
            style={{
              marginRight: 13,
            }}
          >
            전체 다운로드
          </Button>
          <Button className="btn-export" onClick={handleExportExcel}>
            Excel 다운로드
          </Button>
          <Button className="btn-contact" onClick={handleCopyPhone}>
            연락처 복사
          </Button>
          <Button className="btn-email" onClick={handleCopyEmail}>
            이메일 복사
          </Button>
        </div>
      </div>
      <div></div>
      <Table
        dataSource={isSuccess ? data.data.result : []}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: PAGE_SIZE,
          total: data?.data?.metaData.totalRecords || 0,
        }}
        loading={isLoading}
        columns={columns}
        style={{
          width: 1148,
        }}
        rowKey={(record: any) => record.id}
        rowSelection={rowSelection}
        onChange={(page: any, pageSize: any) => {
          if (page?.current !== pageNum) {
            setpageNum(page.current);
            setfilterOptions({
              page: page?.current,
              limit: PAGE_SIZE,
              status: selectedRadio,
              order: sort,
            });
          }
        }}
      />
    </div>
  );
}
