/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const talentAgencyInfoApi = createApi({
  reducerPath: "TalentAgencyInfo",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["GetTalentAgencyInfo"],
  endpoints: (builder) => ({
    updateTalentAgencyInfo: builder.mutation({
      query: (data) => {
        return {
          url: `business/${data.businessId}/business-detail`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["GetTalentAgencyInfo"],
    }),
    deleteTalentAgencyInfo: builder.mutation({
      query: (data) => ({
        url: `business/${data.businessId}/business-detail`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetTalentAgencyInfo"],
    }),
    getTalentAgencyInfo: builder.query({
      query: (data) => ({
        url: `business/business-detail/search`,
        method: "POST",
        body: data,
      }),
      providesTags: ["GetTalentAgencyInfo"],
    }),
    getTalentAgencyInfoDetail: builder.query({
      query: (data) => ({
        url: `business/${data.businessId}/business-detail`,
        method: "GET",
      }),
      providesTags: ["GetTalentAgencyInfo"],
    }),
  }),
});

export const {
  useGetTalentAgencyInfoQuery,
  useUpdateTalentAgencyInfoMutation,
  useDeleteTalentAgencyInfoMutation,
  useGetTalentAgencyInfoDetailQuery,
} = talentAgencyInfoApi;
