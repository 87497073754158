import { Routes, Route } from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "./routers";
import Layout from "./components/layout";
import CustomModal from "./components/modal";
import ChangePassword from "./pages/Login/ChangePassword";

function App() {
  return (
    <div className="app-wrapper">
      <Routes>
        <Route path="/" element={<Layout />}>
          {PrivateRoutes.map((item, index) => (
            <Route key={index} path={item.path} element={item.element}>
              {item.children &&
                item.children.map((child, index) => (
                  <Route
                    key={index}
                    path={child.path}
                    element={child.element}
                  >
                    {child.children && child.children.map((c, idx) => {
                      return <Route
                        key={idx}
                        path={c.path}
                        element={c.element}
                      />
                    })}
                  </Route>
                ))}
            </Route>
          ))}

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Route>
        <Route path="auth">
          {PublicRoutes.map((item, index) => (
            <Route key={index} path={item.path} element={item.element} />
          ))}
        </Route>
        <Route path="/">
          <Route
            key="change-password"
            path="change-password"
            element={<ChangePassword />}
          />
        </Route>

      </Routes>
      <CustomModal />
    </div>
  );
}

export default App;
