import React from "react";
import "./style.scss";
import { Table, TableProps } from "antd";

interface IKItTableCustomProps extends TableProps<any> {
  onTableChange?: (type: string, value) => void;
  onPaginationChange?: (type: string, value) => void;
}

function KItTableCustom({
  columns,
  dataSource,
  onTableChange,
  onPaginationChange,
}: IKItTableCustomProps) {
  return (
    <div className="ktable--custom">
      <Table
        className="ktable"
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onTableChange?.("click-row", record);
            }, // click row
          };
        }}
        dataSource={dataSource}
        pagination={{
          position: ["bottomCenter"],
          onChange(page, pageSize) {
            onPaginationChange?.("pagination", {
              page,
              pageSize,
            });
          },
        }}
      />
    </div>
  );
}

export default KItTableCustom;
