import React, { useRef, useState } from "react";
import { RightOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Divider, Popover } from "antd";
import moment from "moment";
import { DATE_TIME_HYPHEN } from "../../../../utils/constants";
import { useNavigate } from "react-router";
import "./style.scss";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../components/modal/modalSlice";
import { useDeleteCVMutation } from "../../talentInfoApi";
import { kitToastMessage } from "../../../../components/toastMessage";
import { createSearchParams } from "react-router-dom";

interface IRLRowProps {
  type: "tempory" | "offical";
  onClick: (type: "show-resume", value: any) => void;
  data: any;
}

function RLRow({ onClick, type, data }: IRLRowProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const popoverRef = useRef<React.ReactNode | null>(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [deleteCV] = useDeleteCVMutation();

  const handleCvDelete = () => {
    dispatch(
      openModal({
        template: "show-confirm",
        data: {
          type: "delete",
          model: "dmc3",
          confirmText: "정말 삭제 하시겠습니까?",
        },
        actionConfirm: () => {
          deleteCV(data?.id).then((res) =>
            kitToastMessage("bottom", "이력서를 삭제하시겠습니까", "success")
          );
        },
      })
    );
  };
  return (
    <div className="rlrow">
      <div className="rlrow-row">
        <div className="rlrow-row-item">
          {data?.isDefault && (
            <span className="rlrow-item-btn--basic">기본</span>
          )}

          <span
            className={`rlrow-item-status ${
              !data?.isPublic && "rlrow-item-status--disabled"
            } `}
          >
            {data?.isPublic ? "공개" : "비공개"}
          </span>
          <span className="rlrow-item-name">{data?.title}</span>
        </div>
        <div className="rlrow-row-item">
          <div className="rlrow-item-time">
            {moment(data?.createdAt).format(DATE_TIME_HYPHEN)}{" "}
            {moment(data?.createdAt).format("hh:ss")} 저장
          </div>
          <RightOutlined
            onClick={() => onClick && (onClick("show-resume", data) as any)}
          />
        </div>
      </div>
      <div className="rlrow-row">
        <div className="rlrow-info">
          <span>
            {data?.desiredRecruitmentForm === "CONTRACT_WORKER"
              ? "계약직"
              : data?.desiredRecruitmentForm === "FULLTIME"
              ? "정규직"
              : data?.desiredRecruitmentForm === "FREELANCER"
              ? "프리랜서"
              : ""}
          </span>
          <Divider type="vertical" />
          <span>{data?.desiredWorkingPlace}</span>
          {} <Divider type="vertical" />
          <span>희망연봉 {data?.desiredSalary}만원</span>
        </div>
      </div>
      <div className="rlrow-row">
        <div className="rlrow-info">
          <span>보유기술</span>
          <Divider type="vertical" />
          <span>{data?.skills?.map((item) => item.skillName)?.join(" ,")}</span>
        </div>
        <div className="row-popover--custom" key={Math.random()}>
          <Button className="rlrow-btn--delete" onClick={handleCvDelete}>
            삭제
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RLRow;
