import React from "react";
import "./style.scss";
import Logo from "../../assets/images/logo_dmcv3.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogout } from "../../pages/Login/authSlice";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(userLogout());
    window.location.href = "/auth";
  };
  return (
    <div className="header-wrapper">
      <div className="logo-wrapper">
        <img
          className="img-logo"
          src={Logo}
          alt="dmc logo"
          onClick={() => navigate("/main")}
        />
      </div>
      <div className="header-action-area">
        {!localStorage.getItem("token") ? (
          <div className="auth-area">
            <span>안녕하세요 관리자님</span>{" "}
            <div className="separate-space"></div>{" "}
            <span onClick={handleLogout}>로그아웃</span>
          </div>
        ) : (
          <div className="auth-area">
            <span>로그인</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
