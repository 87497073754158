import {
  configureStore,
  isRejectedWithValue,
  MiddlewareAPI,
  Middleware,
  combineReducers,
} from "@reduxjs/toolkit";
import authReducer, { userLogout } from "../pages/Login/authSlice";
import { authApi } from "../pages/Login/authApi";
import modalReducer from "../components/modal/modalSlice";
import { memberManagementApi } from "../pages/MemberManagement/memberManagementApi";
import { applyWorkServiceApi } from "../pages/ApplyWorkService/applyWorkServiceApi";
import { fileUploadApi } from "../components/imageUpload/fileUploadApi";
import { fundServiceApi } from "../pages/FoundationBusiness/fundServiceApi";
import { residentCompanyApi } from "../pages/ResidentCompany/residentCompanyApi";
import { trainingRegisterApi } from "../pages/TrainingRegister/trainingRegisterApi";
import { talentAgencyInfoApi } from "../pages/TalentAgencyInfo/talentAgencyInfoApi";
import { recruitmentInformationApi } from "../pages/RecruitmentInformation/recruitmentInfoApi";
import { annoucementApi } from "../pages/Announcement/annoucementApi";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { eventApi } from "../pages/Event/eventApi";
import { FAQApi } from "../pages/FAQ/faqApi";
import { innovationV3PlatformApi } from "../pages/Main/innovationV3PlatformApi";
import { noticeApi } from "../pages/Main/Announcement/annoucementApi";
import { talentInfoApi } from "../pages/TalentInfo/talentInfoApi";

const rootReducer: any = combineReducers({
  auth: authReducer,
  [authApi.reducerPath]: authApi.reducer,
  [memberManagementApi.reducerPath]: memberManagementApi.reducer,
  [talentInfoApi.reducerPath]: talentInfoApi.reducer,
  [applyWorkServiceApi.reducerPath]: applyWorkServiceApi.reducer,
  [fundServiceApi.reducerPath]: fundServiceApi.reducer,
  [trainingRegisterApi.reducerPath]: trainingRegisterApi.reducer,
  [fileUploadApi.reducerPath]: fileUploadApi.reducer,
  [residentCompanyApi.reducerPath]: residentCompanyApi.reducer,
  [talentAgencyInfoApi.reducerPath]: talentAgencyInfoApi.reducer,
  [recruitmentInformationApi.reducerPath]: recruitmentInformationApi.reducer,
  [annoucementApi.reducerPath]: annoucementApi.reducer,
  [noticeApi.reducerPath]: noticeApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  [FAQApi.reducerPath]: FAQApi.reducer,
  [innovationV3PlatformApi.reducerPath]: innovationV3PlatformApi.reducer,
  modal: modalReducer,
});

// Redux-persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      console.log("rtkQueryErrorLogger", action);
      if (action.payload.status === 401 || action.payload.status === 403) {
        api.dispatch(userLogout());
        window.location.href = "/auth";
      }
    }
    return next(action);
  };

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      rtkQueryErrorLogger,
      authApi.middleware,
      memberManagementApi.middleware,
      applyWorkServiceApi.middleware,
      fundServiceApi.middleware,
      fileUploadApi.middleware,
      residentCompanyApi.middleware,
      trainingRegisterApi.middleware,
      talentAgencyInfoApi.middleware,
      recruitmentInformationApi.middleware,
      annoucementApi.middleware,
      eventApi.middleware,
      FAQApi.middleware,
      innovationV3PlatformApi.middleware,
      noticeApi.middleware,
      talentInfoApi.middleware,
    ]),
});
export const storePersist = persistStore(store);
