import React from "react";
import "./style.scss";

interface ICVCustomDetailTitleProps {
  subTitle?: string;
  title: string;
  className?: string;
}

function CVCustomDetailTitle({
  title,
  subTitle = "",
  className,
}: ICVCustomDetailTitleProps) {
  return (
    <div className={`cv-custom-detail-title ${className && className}`}>
      <span className="title">{title}</span>
      <span className="subTitle">{subTitle}</span>
    </div>
  );
}

export default CVCustomDetailTitle;
