import { useState, useMemo, useRef, useEffect } from "react";
import "./style.scss";
import { DatePicker } from "antd";
import CalendarIcon from "../../assets/images/DatepickerIcon";
import { DATE_TIME } from "../../utils/constants";
import moment from "moment";
import { formatToIOSDateTime } from "../../utils/commonFunc";

interface KitDatepickerProps {
  initialValue?: string[] | string | any;
  type: "datePicker" | "rangePicker";
  onChange: (date: any) => void;
  allowClear?: boolean;
  disabled?: boolean;
}

function KitDatepicker({
  initialValue,
  type,
  allowClear = false,
  onChange,
  disabled,
}: KitDatepickerProps) {
  const dpFromRef = useRef<any>(null);
  const dpToRef = useRef<any>(null);
  const [isDpToVisible, setIsDpToVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedRangeDate, setSelectedRangeDate] = useState<any>({
    from: initialValue && initialValue[0] ? moment(initialValue[0]) : undefined,
    to: initialValue && initialValue[1] ? moment(initialValue[1]) : undefined,
  });

  useEffect(() => {
    if (initialValue) {
      setSelectedRangeDate({
        ...selectedRangeDate,
        from: initialValue[0] ? moment(initialValue[0]) : undefined,
        to: initialValue[1] ? moment(initialValue[1]) : undefined,
      });
    }
  }, [initialValue]);

  const disabledDate = (current: any) => {
    if (selectedRangeDate?.from) {
      return current && current < selectedRangeDate?.from;
    }
  };

  useEffect(() => {
    if (selectedRangeDate?.from && selectedRangeDate?.to) {
      onChange([
        selectedRangeDate.from
          ? formatToIOSDateTime(selectedRangeDate.from)
          : undefined,
        selectedRangeDate?.to
          ? formatToIOSDateTime(selectedRangeDate?.to)
          : undefined,
      ]);
    }
  }, [selectedRangeDate?.from, selectedRangeDate?.to]);

  return (
    <div className="kit-dp-wrapper">
      {type === "datePicker" && (
        <DatePicker
          defaultValue={initialValue}
          allowClear={allowClear}
          placeholder="yyyy.mm.dd"
          format={DATE_TIME}
          suffixIcon={<CalendarIcon />}
          onChange={(date: any) => formatToIOSDateTime(date)}
          className="kit-dp kit-dp-single"
        />
      )}
      {type === "rangePicker" && (
        <div className="kit-dp__range-picker">
          <DatePicker
            disabled={disabled}
            allowClear={allowClear}
            ref={dpFromRef}
            className="kit-dp kit-dp-from"
            placeholder="yyyy.mm.dd"
            format={DATE_TIME}
            value={selectedRangeDate.from}
            onClick={() => setIsDpToVisible(false)}
            onBlur={() => setIsDpToVisible(false)}
            onChange={(date: any) => {
              setSelectedRangeDate({
                ...selectedRangeDate,
                from: date,
              });

              dpToRef.current && dpToRef.current.focus();
              setIsDpToVisible(true);
            }}
            suffixIcon={<CalendarIcon />}
          />
          <div className="kit-dp-separate">-</div>
          <DatePicker
            disabled={disabled}
            allowClear={allowClear}
            ref={dpToRef}
            value={selectedRangeDate.to}
            disabledDate={disabledDate}
            className="kit-dp kit-dp-to"
            placeholder="yyyy.mm.dd"
            format={DATE_TIME}
            open={isDpToVisible}
            onClick={() => setIsDpToVisible(true)}
            onBlur={() => setIsDpToVisible(false)}
            onChange={(date: any) => {
              setSelectedRangeDate({
                ...selectedRangeDate,
                to: date,
              });

              setIsDpToVisible(false);
            }}
            suffixIcon={<CalendarIcon />}
          />
        </div>
      )}
    </div>
  );
}

export default KitDatepicker;
