import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  modalCancel,
  modalConfirm,
  modalData,
  modalHeight,
  modalStatus,
  modalTemplate,
  modalWidth,
} from "../../store/selector/RootSelector";
import { closeModal } from "./modalSlice";
import "./style.scss";
import ShowCompany from "./template/showCompany/ShowCompany";
import ShowInfo from "./template/showInfo/ShowInfo";
import ShowConfirm from "./template/showConfirm/showConfirm";
import ShowApprovedConfirm from "./template/showAprrovedConfirm";

export default function CustomModal() {
  const isOpen = useSelector(modalStatus);
  const template = useSelector(modalTemplate);
  const data = useSelector(modalData);
  const width = useSelector(modalWidth);
  const actionConfirm = useSelector(modalConfirm);
  const actionCancel = useSelector(modalCancel);

  const dispatch = useDispatch<any>();

  const handleConfirm = (params: any) => {
    actionConfirm(params);
    dispatch(closeModal());
  };
  const handleClose = () => {
    actionCancel();
    dispatch(closeModal());
  };

  const generateContent = (template: string) => {
    switch (template) {
      case "show-company-modal":
        return <ShowCompany data={data} actionConfirm={handleConfirm} actionCancel={handleClose} />;
      case "show-info":
        return <ShowInfo data={data} actionConfirm={handleConfirm} actionCancel={handleClose} />;
      case "show-confirm":
        return <ShowConfirm data={data} actionConfirm={handleConfirm} actionCancel={handleClose} />;
      case "show-member-approved-confirm":
        return <ShowApprovedConfirm data={data} actionConfirm={handleConfirm} actionCancel={handleClose} />;
      default:
        return "";
    }
  };

  return (
    <Modal
      title=""
      open={isOpen}
      onOk={handleConfirm}
      onCancel={handleClose}
      closable={false}
      okText="Confirm"
      cancelText="Cancel"
      footer={null}
      centered={true}
      width={width}
      className="custom-modal"
    >
      {generateContent(template)}
    </Modal>
  );
}
