// api link
// export const API_LINK = "http://43.201.255.169:3000/api/"; //stg
// export const API_LINK = "https://api.dmcbiz.org/api/"; //prod

// api link
export const API_LINK = process.env.REACT_APP_BASE_API_URL;

//constant key local storage
export const REMEMBER_ME = "REMEMBER_ME";
export const TOKEN = "TOKEN";
export const USERNAME = "USERNAME";
export const PASSWORD = "PASSWORD";

// constant for table
export const PAGE_SIZE = 10;
export const KEY_EDITOR = "5odwcnrw1ftkmnj4ctngqwn1d9emduhm7ftn9wpdxoqm0zix";

// const form date time
export const DATE_TIME = "YYYY.MM.DD";
export const DATE_TIME_HYPHEN = "YYYY-MM-DD";
export const DATE_PICKER_REGEX = /^\d{4}-\d{2}-\d{2}$/;
export const tableLocale = {
  emptyText: "검색 결과가 없습니다.",
};
