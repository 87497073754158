import React, { useEffect, useMemo, useRef, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PAGE_SIZE, tableLocale } from "../../utils/constants";
import { Button, Input, Select, Table } from "antd";
import {
  CompanyFilterOptions,
  DefaultFilterOptions,
} from "../../utils/types/table";
import "./style.scss";
import { useGetResidentCompanyListQuery } from "./residentCompanyApi";
import { truncateText } from "../../utils/commonBlock";

export default function ResidentCompany() {
  const navigate = useNavigate();
  const submitBtnRef = useRef<any>(null);
  const [pageNum, setpageNum] = useState(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [optionValue, setOptionValue] = useState<string>("");
  const defaultFilterOptions = {
    value: searchValue,
    page: 1,
    limit: PAGE_SIZE,
    dmcType: "v3",
  };

  const [filterOptions, setfilterOptions] =
    useState<CompanyFilterOptions>(defaultFilterOptions);

  const { data, isLoading, isSuccess } = useGetResidentCompanyListQuery(
    filterOptions,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleChangeOption = (value: string) => {
    setOptionValue(value);
    if (value === "") {
      const { included, ...rest } = filterOptions;
      setfilterOptions(rest);
    } else {
      setfilterOptions({ ...filterOptions, included: value });
    }
  };

  useEffect(() => {
    if (searchValue.length === 0) {
      setfilterOptions(defaultFilterOptions);
    }
  }, [searchValue]);

  const columns = useMemo(() => {
    return [
      {
        title: "번호",
        dataIndex: "currentId",
        key: "currentId",
        width: "10%",
      },
      {
        title: "분류",
        dataIndex: "included",
        key: "included",
        width: "10%",
        render: (item: any) => {
          return item === "Included"
            ? "입주기업"
            : item === "Not_Included"
            ? "비입주기업"
            : null;
        },
      },
      {
        title: "기업 이름",
        dataIndex: "companyName",
        key: "companyName",
        width: "30%",
        render: (item: any) => {
          return truncateText("400px", { item, value: filterOptions.value });
        },
      },
      {
        title: "사업자등록번호",
        dataIndex: "business",
        key: "business",
        width: "30%",
        render: (item: any) => {
          return item?.registrationNumber;
        },
      },
      {
        title: "담당자 연락처",
        dataIndex: "companyNumber",
        key: "companyNumber",
        width: "15%",
        render: (_: any, record: any) => {
          return record.business.phoneNumber;
        },
      },
      {
        title: "상세정보",
        width: 200,
        render: (item: any, record: any) => {
          return (
            <Button
              className="btn-lookup"
              onClick={(e) => {
                e.stopPropagation();
                navigate({
                  pathname: "view",
                  search: createSearchParams({
                    id: record.id,
                    code: record.businessCode,
                  }).toString(),
                });
              }}
            >
              조회
            </Button>
          );
        },
      },
    ];
  }, [data, filterOptions.value]);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      submitBtnRef?.current?.click();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="resident-company-wrapper">
      <div className="resident-company-wrapper__header">
        <div className="search-area">
          <p className="search-area-title">입주기업</p>
          <div className="search-input">
            <Select
              className="select-area"
              defaultValue={""}
              value={optionValue}
              options={[
                { value: "", label: "전체" },
                { value: "Included", label: "입주기업" },
                { value: "Not_Included", label: "비입주기업" },
              ]}
              onChange={handleChangeOption}
            />
            <Input
              placeholder="검색어를 입력해 주세요."
              className="input-area"
              value={searchValue}
              onChange={(e: any) => setSearchValue(e.target.value)}
            />
            <Button
              ref={submitBtnRef}
              disabled={!searchValue}
              className="btn-crud--primary btn-search"
              onClick={() =>
                setfilterOptions({
                  ...filterOptions,
                  value: searchValue,
                })
              }
            >
              검색
            </Button>
          </div>
        </div>

        <div className="action-area">
          <Button
            className="btn-crud--primary btn-create"
            onClick={() => navigate("/resident-company/create")}
          >
            등록하기
          </Button>
        </div>
      </div>
      <div className="resident-company-wrapper__body">
        {!isLoading && filterOptions.value.length > 0 && (
          <div className="result-area">
            <div className="result-area__title">
              ‘{filterOptions.value}’&nbsp;
              <div className="result-area__sub-title">
                로 검색한 결과입니다.
              </div>
            </div>
            <div className="result-area__counter">
              총 {data?.data?.metaData?.totalRecords}건
            </div>
          </div>
        )}
        <Table
          locale={tableLocale}
          dataSource={
            isSuccess
              ? data.data.result.map((item: any, keyId: number) => ({
                  ...item,
                  currentId: keyId + 1 + (pageNum - 1) * 10,
                }))
              : []
          }
          pagination={
            data?.data?.metaData?.totalRecords > 10 && {
              position: ["bottomCenter"],
              showSizeChanger: false,
              defaultPageSize: PAGE_SIZE,
              total: data?.data?.metaData.totalRecords || 0,
            }
          }
          loading={isLoading}
          columns={columns}
          rowKey={(record: any) => record.id}
          //   onRow={(record, rowIndex) => {
          //     return {
          //       onClick: (event) =>
          //         navigate({
          //           pathname: "view",
          //           search: createSearchParams({
          //             id: record.id,
          //           }).toString(),
          //         }), // click row
          //     };
          //   }}
          onChange={(page: any) => {
            if (page?.current !== pageNum) {
              setpageNum(page.current);
              setfilterOptions({
                ...filterOptions,
                value: searchValue,
                page: page?.current,
                limit: PAGE_SIZE,
              });
            }
          }}
        />
      </div>
    </div>
  );
}
