import React from "react";
import "./style.scss";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate } from "react-router-dom";

export default function LNB() {
  return (
    <div className="lnb-wrapper">
      <div className="lnb-head">
        <div className="lnb-logo"></div>
        <Sidebar />
      </div>
      <div></div>
    </div>
  );
}
