import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authApi } from "./authApi";
import { TOKEN } from "../../utils/constants";

interface Auth {
  isUserLogin: boolean;
  accessToken: string;
  userInfo: any;
}

const initialState: Auth = {
  isUserLogin: !!localStorage.getItem(TOKEN),
  accessToken: localStorage.getItem(TOKEN) || "",
  userInfo: {},
};

const userInfo = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    userLogin: (state: Auth, action: any) => {
      state.isUserLogin = true;
    },
    userLogout: (state: Auth, action: PayloadAction) => {
      state.isUserLogin = false;
      localStorage.clear();
      state.isUserLogin = false;
      state.userInfo = {};
      state.accessToken = "";
    },
  },
  extraReducers: (builder: any) => {
    // login with RTK query
    builder
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state: Auth, action: PayloadAction<any>) => {
          state.isUserLogin = true;
          localStorage.setItem(TOKEN, action.payload.data.tokens.accessToken);
          state.userInfo = action.payload.data.user;
          state.accessToken = action.payload.data.tokens.accessToken;
        }
      )
      .addMatcher(
        authApi.endpoints.login.matchRejected,
        (state: Auth, action: PayloadAction<any>) => {
          // window.location.href = "/auth";
        }
      );
    // logout
  },
});

const { reducer, actions } = userInfo;
export const { userLogin, userLogout } = actions;
export default reducer;
