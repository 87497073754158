import moment from "moment";

export function bytesToMegabytes(bytes) {
  return (bytes / (1024 * 1024)).toFixed(3);
}

export const showDateRange = (
  startDate,
  endDate,
  format = "YYYY-MM",
  currentTimeStr = ""
) => {
  const startDateStr =
    startDate && moment(startDate).isValid()
      ? `${moment(startDate).format(format)} - `
      : "";

  const endDateStr =
    endDate && moment(endDate).isValid()
      ? moment().isSame(moment(endDate), "month") && currentTimeStr
        ? currentTimeStr
        : moment(endDate).format(format)
      : "";

  return startDateStr + endDateStr;
};

// fetch data
export const downloadFileFromURL = async (url, name) => {
  const data = await fetch(url);
  const blob = await data.blob();
  const objectUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = objectUrl;
  link.target = "_blank";
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const showPrivatePhoneNumber = (phone: string | number) => {
  if (!phone) {
    return "***-***-****";
  }
  return `${(phone + "").substring(0, 3)}-***-****`;
};

export const showPrivateEmail = (email: string, showCharacters = 2) => {
  const reg = new RegExp(`^(.{${showCharacters}})(.*)(?=@)`);
  return email.replace(reg, (match, firstPart, middlePart) => {
    const asterisks = "*".repeat(middlePart.length);
    return firstPart + asterisks;
  });
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber?.length > 0 && /^\d+$/.test(phoneNumber)) {
    const cleanNumber = phoneNumber.replace(/\D/g, "");
    const firstThreeDigits = cleanNumber.slice(0, 3);
    const remainingDigits = cleanNumber.slice(3);
    const chunks = remainingDigits.match(/.{1,4}/g);
    const formattedNumber =
      firstThreeDigits + "-" + (chunks ? chunks.join("-") : "");

    return formattedNumber;
  } else {
    return phoneNumber;
  }
};

export const highlightKeywords = (text: string, searchInput: string) => {
  // debugger;
  if (!searchInput) {
    return text;
  }

  const regex = new RegExp(searchInput, "gi");
  const newText = text?.replace(
    regex,
    (match) => `<span class="result-text--highlight">${match}</span>`
  );
  // const parser = new DOMParser();
  // const parsedDocument = parser.parseFromString(newText, "text/html");
  return newText;
};

export const formatToIOSDateTime = (date: any) => {
  if (date) {
    const isoFormattedDate = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    return isoFormattedDate;
  }
  return null;
};

export const debounce = <F extends (...args: any[]) => void>(
  func: any,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: Parameters<F>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const isObjectWithFullValues = (obj: any) => {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (!isObjectWithFullValues(obj[key])) {
        return false;
      }
    } else {
      // match with all values such as : null | undefined | empty string....
      if (!String(obj[key]).trim()) {
        return false;
      }
    }
  }
  return true;
};

export function convertToDots(text) {
  const length = text?.length;
  return "●".repeat(length);
}

export const generateYearsInRange = (startDate, endDate = new Date()) => {
  const startYear = parseInt(startDate);
  const endYear = endDate?.getFullYear();
  const years: any = [];

  for (let year = endYear; year >= startYear; year--) {
    years.push({
      value: year?.toString(),
      label: year?.toString(),
    });
  }

  return years;
};
