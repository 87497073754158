export interface loginData {
  username: string;
  password: string;
}

export enum UserClassify {
  Personalized = 1,
  Enterprises = 2,
  Organ = 3,
}

export enum WorkingStatus {
  SEEKING_FOR = 1,
  IS_WORKING = 2,
}

export enum ApprovedEnum {
  APPROVE = "승인",
  UNAPPROVE = "미승인",
  WAIT = "승인대기",
}

export interface UserData {
  approved: number;
  createdAt: string;
  deletedAt: string;
  diNo: string;
  dob: string;
  email: string;
  gender: number;
  id: number;
  password: string;
  phoneNumber: string;
  picName: string;
  picPosition: string;
  role: string;
  status: string;
  type: string;
  updatedAt: string;
  userClassify: string;
}
