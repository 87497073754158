import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";

import { useNavigate, useSearchParams } from "react-router-dom";
import DetailBlock from "../../../components/commonBlock";
import { Button } from "antd";
import { openModal } from "../../../components/modal/modalSlice";
import { useDispatch } from "react-redux";
import { formatPhoneNumber } from "../../../utils/commonFunc";
import { kitToastMessage } from "../../../components/toastMessage";

import {
  useDeleteResidentCompanyMutation,
  useGetResidentCompanyDetailQuery,
} from "../residentCompanyApi";
import { useGetFileMutateMutation } from "../../../components/imageUpload/fileUploadApi";

interface ResidentCompanyProps {}

function ResidentCompany(props: ResidentCompanyProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");

  const { data, isLoading, isSuccess } = useGetResidentCompanyDetailQuery(
    {
      id: searchParams.get("id"),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [deleteResidentCompany] = useDeleteResidentCompanyMutation();
  const [getFileMutate] = useGetFileMutateMutation();

  const handleFilesDownload = async (data: any) => {
    try {
      await Promise.all(
        data?.map(async (file: any) => {
          const res = await getFileMutate({ id: file.id });
          const binaryDataBuffer = new Uint8Array(res.data.data.Body.data);
          const formatedFile = new File([binaryDataBuffer], file.originalName, {
            type: res.data.data.ContentType,
          });
          const blob =
            formatedFile instanceof FileList
              ? formatedFile[0].slice(
                  0,
                  formatedFile[0].size,
                  formatedFile[0].type
                )
              : formatedFile.slice(0, formatedFile.size, formatedFile.type);
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = formatedFile.name;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          URL.revokeObjectURL(downloadLink.href);
          document.body.removeChild(downloadLink);
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const blockInfo = useMemo(() => {
    let blockPic: any = [];
    let blockBusiness: any = [];
    console.log("blockInfo", data);
    if (!isLoading && data?.data) {
      const res = data.data;
      console.log("blockInfo", res);

      blockPic = [
        {
          title: "담당자 명",
          desc: res?.user?.picName,
        },
        {
          title: "담당자 부서/직책",
          desc: res?.user?.picPosition,
        },
        {
          title: "담당자 연락처",
          desc: res?.user?.phoneNumber,
        },
      ];
      blockBusiness = [
        {
          title: "기업명",
          desc: res?.companyName,
        },
        {
          title: "사업자등록번호",
          desc: res?.business?.registrationNumber,
        },
        {
          title: "대표자명",
          desc: res?.representativeName,
        },
        {
          title: "기업 주소",
          desc: res?.detailAddress,
        },
        {
          title: "업종",
          desc: res?.career
            ? res?.career + "/" + res?.detailCareer + "/" + res?.detailCareer2
            : "",
        },
        {
          title: "대표번호",
          desc: res?.representativeCode,
        },
        {
          title: "2022년도 매출액(억원)",
          desc: res?.revenue2022,
        },
        {
          title: "근로자수",
          desc: res?.labourNumber,
        },
        {
          title: "기업 규모",
          desc: res?.companySize,
        },
        {
          title: "회사 홈페이지",
          desc: res?.homepage,
        },
        {
          title: "사업자등록증",
          desc: res?.businessCodeFiles,
          type: "downloadFile",
        },
        {
          title: "회사로고",
          desc: res?.logoFiles && res?.logoFiles[0]?.originalName,
        },
        {
          title: "입주기업 여부",
          desc:
            res?.included === "Included"
              ? "입주기업"
              : res?.included === "Not_Included"
              ? "비입주기업"
              : null,
        },
        {
          title: "산업분류",
          desc:
            res?.industryClassification1 ||
            "" +
              `${
                res?.industryClassification2
                  ? `/${res?.industryClassification2}`
                  : ""
              }` +
              `${
                res?.industryClassification3
                  ? `/${res?.industryClassification3}`
                  : ""
              }`,
        },
      ];
    }

    return { blockPic, blockBusiness };
  }, [isLoading, data]);

  const handleDeleteRecord = async () => {
    try {
      const res: any = await deleteResidentCompany({
        id: searchParams.get("id"),
      }).unwrap();

      if (res.statusCode === 200) {
        kitToastMessage("bottom", "삭제 완료되었습니다.", "success");
        navigate("/resident-company");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   console.log(data?.data?.products);
  //   if (data?.data) {
  //     handleGetFile()?.then((res) => console.log(res));
  //   }
  // }, [data?.data]);

  // const handleGetFile = async () => {
  //   const convertFile = async (item: any) => {
  //     const res = await getFileMutate({ id: item?.thumbnail.id }).unwrap();

  //     const binaryDataBuffer = new Uint8Array(res.data.data.Body.data);
  //     const formatedFile = new File([binaryDataBuffer], item.originalName, {
  //       type: res.data.data.ContentType,
  //     });
  //     return formatedFile;
  //   };
  //   try {
  //     return await Promise.all(
  //       data?.data?.products.map(async (item: any) => ({
  //         ...item,
  //         thumbnail: await convertFile(item),
  //       }))
  //     );
  //   } catch (error) {}
  // };

  return (
    <div className="resident-company-detail">
      <div
        className={`resident-company-detail__header ${
          true ? "is-admin-page" : null
        }`}
      >
        <div className="title-container">
          <p className="title">채용정보 상세</p>
        </div>
      </div>

      <div className="resident-company-detail__body">
        <div className="block-one">
          <div className="block-one-action">
            <Button
              className="btn-crud--primary"
              onClick={() =>
                navigate({
                  pathname: `/resident-company/update`,
                  search: `id=${searchParams.get("id")}&code=${searchParams.get(
                    "code"
                  )}`,
                })
              }
            >
              수정하기
            </Button>
            <Button
              className="btn-crud--error"
              onClick={() => {
                dispatch(
                  openModal({
                    template: "show-confirm",
                    data: {
                      type: "delete",
                      confirmText: "정말로 삭제하시겠습니까?",
                    },
                    actionConfirm: handleDeleteRecord,
                  })
                );
              }}
            >
              삭제하기
            </Button>
          </div>
        </div>
        <div className="block-two">
          <DetailBlock
            title="담당자 정보"
            content={blockInfo.blockPic}
            className={{ flex: "1" }}
          />
          <DetailBlock
            title="기업 정보"
            content={blockInfo.blockBusiness}
            className={{ flex: "1" }}
          />
        </div>
        <div className="block-three">
          <div className="block-three-title">추가정보</div>
          <div className="block-three-keyword">
            <div className="keyword-item">
              <div className="keyword-item-title">주요키워드</div>
              <div className="keyword-item-content">{data?.data?.keyword}</div>
            </div>
            <div className="keyword-item">
              <div className="keyword-item-title">회사소개자료</div>
              <div className="keyword-item-content">
                <span
                  className="download"
                  onClick={() =>
                    handleFilesDownload(data?.data?.companyInfoFiles)
                  }
                >
                  다운로드
                </span>
              </div>
            </div>
          </div>
          <div className="block-three-content">
            <div className="content-title">회사소개</div>
            <div className="content-text">{data?.data?.description}</div>
          </div>
          <div className="block-three-product">
            <div className="product-title">주요 제품명</div>
            {data?.data?.products?.map((item: any) => {
              return (
                <div className="product-detail">
                  <div className="product-detail-left">
                    <img
                      className="product-detail-left-image"
                      src={
                        (item?.thumbnail && item?.thumbnail?.url) ||
                        "/assets/images/default_company.png"
                      }
                      alt=""
                    />
                    <span className="image-desc">{item?.product}</span>
                  </div>
                  <div className="product-detail-right">
                    {item?.detailProduct}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="member-management-detail__footer">
          <Button className="btn--goback" onClick={() => navigate(-1)}>
            목록으로
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ResidentCompany;
