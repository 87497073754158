import { Button, Form, Input } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  useGetFileMutateMutation,
  useGetFileQuery,
  useUploadFileMutation,
} from "../../../../components/imageUpload/fileUploadApi";
import AvatarUpload from "../../../../components/imageUpload";
import { TrainingRegisterForm } from "../../@type";
import "./style.scss";
import { API_LINK, KEY_EDITOR, TOKEN } from "../../../../utils/constants";
import {
  useCreateTrainingRegisterMutation,
  useDeleteTrainingRegisterMutation,
  useGetTrainingRegisterDetailQuery,
  useUpdateTrainingRegisterMutation,
} from "../../trainingRegisterApi";
import { WorkingStatus } from "../../../../utils/types/user";
import MultipleUpload from "../../../../components/multipleUpload";
import { generateCustomLable } from "../../../../utils/commonBlock";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../components/modal/modalSlice";
import { kitToastMessage } from "../../../../components/toastMessage";
import { isObjectWithFullValues } from "../../../../utils/commonFunc";
import { Buffer } from "buffer";

interface TrainingRegisterActionProps {
  type: "create" | "update" | "view";
}

function TrainingRegisterAction({ type }: TrainingRegisterActionProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const editorRef: any = useRef(null);
  const [form]: any = Form.useForm();
  const [isClicked, setIsClicked] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [fileUrl, setFileUrl] = useState<any>([]);
  const [filesArr, setFilesArr] = useState<any>([]);
  const [isFormClicked, setIsFormClicked] = useState(false);
  const initialValues: Partial<TrainingRegisterForm> = {
    title: "",
    description: "",
    content: "",
    url: "",
  };

  const isViewPage = type === "view";

  const { data, isLoading, isSuccess } = useGetTrainingRegisterDetailQuery(
    {
      id: searchParams.get("id"),
    },
    {
      skip: type === "create",
    }
  );

  const files = useMemo(() => {
    if (isSuccess) {
      return {
        thumbnailId: data.data.thumnailId,
        attachFileId: data.data.attachFileId,
      };
    }
  }, [isSuccess, data]);

  const { data: thumbnailData, isSuccess: isUploadThumnailSuccess } =
    useGetFileQuery(
      {
        id: files?.thumbnailId,
      },
      {
        skip: type === "create" || !files?.thumbnailId,
      }
    );
  const { data: fileData, isSuccess: isUploadFileSuccess } = useGetFileQuery(
    {
      id: files?.attachFileId,
    },
    {
      skip: type === "create" || !files?.attachFileId,
    }
  );

  const thumnailLink = useMemo(() => {
    if (isUploadThumnailSuccess && type === "update") {
      const buffer = Buffer.from(thumbnailData.data.Body.data);
      const base64ImageData = buffer.toString("base64");
      const imageSrc = `data:${thumbnailData.data.ContentType};base64,${base64ImageData}`;
      setThumbnailUrl(imageSrc);
      return imageSrc;
    }
  }, [isUploadThumnailSuccess, type]);

  useEffect(() => {
    if (isSuccess) {
      const { updatedAt, id, deletedAt, createdAt, ...rest } = data.data;
      form.setFieldsValue({ ...rest });
      handleExistFilesList(data?.data?.files);
    }
  }, [data?.data, isSuccess]);

  const [createTrainingRegister] = useCreateTrainingRegisterMutation();

  const [uploadFile] = useUploadFileMutation();
  const [getFileMutate] = useGetFileMutateMutation();
  const [updateTrainingRegister] = useUpdateTrainingRegisterMutation();
  const [deleteTrainingRegister] = useDeleteTrainingRegisterMutation();

  const handleUploadThumbnail = async () => {
    try {
      const formData = new FormData();
      formData.append("files", thumbnailUrl);
      const data = await uploadFile(formData).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadFile = async () => {
    try {
      const formData = new FormData();
      fileUrl?.map((file: any) => {
        formData.append("files", file);
      });
      const data = await uploadFile(formData).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateTrainingRegister = async (body: any) => {
    try {
      const data = await createTrainingRegister(body).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values: TrainingRegisterForm | any) => {
    const uploadImageRes = await handleUploadThumbnail();
    const thumbnailId = uploadImageRes?.data?.files[0].id;
    const uploadFileRes = await handleUploadFile();
    const attachFileId = uploadFileRes?.data?.files?.map(
      (item: any) => item.id
    );

    if (type === "create") {
      if (thumbnailId) {
        const { thumbnail, file, ...rest } = values;
        const body = {
          ...rest,
          files: attachFileId || undefined,
          thumnailId: thumbnailId || undefined,
          description: editorRef.current.getContent(),
          trainingType: WorkingStatus.SEEKING_FOR,
        };
        const response = await handleCreateTrainingRegister(body);
        const code = response.statusCode;
        if (code === 200 || code === 201) {
          dispatch(
            openModal({
              template: "show-info",
              data: {
                info: "작성완료 되었습니다.",
              },
              actionConfirm: () =>
                navigate({
                  pathname: "/training-register/view",
                  search: createSearchParams({
                    id: response.data.id,
                  }).toString(),
                }),
            })
          );
        }
      }
    } else if (type === "update") {
      const { thumbnail, file, ...rest } = values;
      const body = {
        ...rest,
        files: attachFileId || rest.attachFileId,
        thumnailId: thumbnailId || rest.thumnailId,
        description: editorRef.current.getContent(),
        trainingType: WorkingStatus.SEEKING_FOR,
        id: searchParams.get("id"),
      };
      const response = await updateTrainingRegister(body).unwrap();
      const code = response.statusCode;
      if (code === 200 || code === 201) {
        dispatch(
          openModal({
            template: "show-info",
            data: {
              info: "작성완료 되었습니다.",
            },
            actionConfirm: () =>
              navigate({
                pathname: "/training-register/view",
                search: createSearchParams({
                  id: searchParams.get("id") as any,
                }).toString(),
              }),
          })
        );
      }
    }
  };

  const handleImageUpload = (file: any) => {
    setThumbnailUrl(file);
  };

  useEffect(() => {
    if (thumnailLink) form.setFieldValue("thumbnail", thumnailLink);
  }, [thumnailLink]);

  useEffect(() => {
    const { title, subDescription, thumbnail, description, url } =
      form.getFieldsValue();
    if (
      !!(title && subDescription && thumbnail && description && url) === true
    ) {
      setIsFormClicked(true);
    } else {
      setIsFormClicked(false);
    }
  }, [isClicked]);

  const handleDeleteTrainingRegister = async () => {
    try {
      const data = await deleteTrainingRegister({
        id: searchParams.get("id"),
      }).unwrap();
      const code = data.statusCode;
      if (code === 200 || code === 201) {
        kitToastMessage("bottom", "삭제 완료되었습니다.", "success");
        navigate("/training-register");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleExistFilesList = async (data: any) => {
    try {
      let arr: any = [];
      await Promise.all(
        data.map(async (file: any) => {
          const res = await getFileMutate({ id: file.id });
          const binaryDataBuffer = new Uint8Array(res.data.data.Body.data);
          const formatedFile = new File([binaryDataBuffer], file.originalName, {
            type: res.data.data.ContentType,
          });
          arr.push(formatedFile);
        })
      );

      setFilesArr(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilUploadChange = (files: any[]) => {
    setFileUrl(Array.from(files));
  };

  const uploadImageHandler = (file: any, progress: any) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("file", file.blob());
        const res = await fetch(API_LINK + "files", {
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
          },
          body: formData,
        })
          .then((res: any) => res.json())
          .catch((error) => error.json());
        if (res.statusCode > 299) {
          return reject(res);
        }
        return resolve(res?.data?.files[0]?.url);
      } catch (error) {
        return reject("Something went wrong!");
      }
    });

  return (
    <div className="training-register-action">
      <div className="action-area">
        <div className="header">
          <p className="title">교육신청</p> <b>-</b>
          <p className="sub-title">구직자 교육</p>
        </div>
        {type === "create" || type === "update" ? (
          <Button
            disabled={!isFormClicked}
            className="btn-crud--error"
            onClick={() => form.submit()}
          >
            작성완료
          </Button>
        ) : (
          <>
            <Button
              className="btn-crud--primary"
              onClick={() =>
                navigate({
                  pathname: "/training-register/update",
                  search: createSearchParams({
                    id: searchParams.get("id") as any,
                  }).toString(),
                })
              }
            >
              수정하기
            </Button>
            <Button
              className="btn-crud--error"
              onClick={() =>
                dispatch(
                  openModal({
                    template: "show-confirm",
                    data: {
                      type: "delete",
                      model: "dmc3",
                      confirmText: "정말 삭제 하시겠습니까?",
                    },
                    actionConfirm: handleDeleteTrainingRegister,
                  })
                )
              }
            >
              삭제하기
            </Button>
          </>
        )}
      </div>
      <div className="form-container">
        <Form
          name="create"
          className="training-register-action__form"
          initialValues={initialValues}
          onFinish={onFinish}
          onValuesChange={(a, b) => {
            setIsClicked(!isClicked);
          }}
          layout={"vertical"}
          form={form}
        >
          <Form.Item
            label="제목"
            name="title"
            className="title form-input"
            rules={[{ required: true, message: "Required field." }]}
          >
            <Input disabled={isViewPage} placeholder="제목을 입력해 주세요." />
          </Form.Item>
          <div className="form-header">
            <Form.Item
              name="thumbnail"
              className="date form-input"
              label="썸네일 등록"
              rules={[{ required: true, message: "Required field." }]}
            >
              <AvatarUpload
                disabled={isViewPage}
                className="training-register-thumbnail"
                initialValue={data?.data?.thumnail?.url}
                onChange={handleImageUpload}
              />
            </Form.Item>
            <div className="form-header__input">
              <Form.Item
                label="교육내용"
                name="subDescription"
                className="title form-input"
                rules={[{ required: true, message: "Required field." }]}
              >
                <Input
                  disabled={isViewPage}
                  placeholder="교육 내용을 입력해 주세요."
                />
              </Form.Item>
              <Form.Item
                label="이동 링크"
                name="url"
                className="title form-input"
                rules={[{ required: true, message: "Required field." }]}
              >
                <Input
                  disabled={isViewPage}
                  placeholder="이동링크를 입력해 주세요."
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item
            label={generateCustomLable(
              "첨부파일",
              "(선택) * 최대 5개까지 첨부 가능합니다."
            )}
            name="file"
            className="file"
          >
            <MultipleUpload
              injectCss={{ width: "1128px" }}
              initialValue={filesArr}
              isDownload={isViewPage}
              multiple
              disabled={isViewPage}
              onChange={handleFilUploadChange}
            />
          </Form.Item>
          <Form.Item
            label="내용"
            name="description"
            className="title form-input"
            rules={[{ required: true, message: "Required field." }]}
          >
            <Editor
              scriptLoading={{
                async: true,
              }}
              value={form.getFieldValue("description")}
              onInit={(_: any, editor: any) => (editorRef.current = editor)}
              apiKey={KEY_EDITOR}
              onEditorChange={(e) => {
                form.setFieldValue("description", e);
                setIsClicked(!isClicked);
              }}
              plugins="advlist autolink lists link image charmap preview
                            searchreplace visualblocks code fullscreen 
                               paste code help table"
              ref={editorRef}
              disabled={isViewPage}
              init={{
                placeholder: "내용을 입력해 주세요.",
                height: 500,
                toolbar:
                  " formatselect | undo redo bold italic backcolor forecolor| \
                    alignleft aligncenter alignright alignjustify| \
                    bullist numlist outdent indent removeformat| image video",
                language: "ko_KR",
                images_upload_handler: uploadImageHandler,
                branding: false,
              }}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default TrainingRegisterAction;
