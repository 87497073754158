import React, { useMemo } from "react";
import NavItemHeader from "./NavItemHeader";
import "./navItemHeader.scss";
import { useLocation, useNavigate } from "react-router-dom";

const NavItem = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { label, to, children, level, classname } = props.item;

  const isChildren = useMemo(() => {
    return !!children;
  }, [children]);

  if (children) {
    return <NavItemHeader item={props.item} />;
  }

  return (
    <button
      className={`nav-item nav-item-header-button ${
        location.pathname === to ? "nav-item--active" : ""
      } padding-${level} ${classname}`}
      onClick={() => {
        navigate(to);
      }}
    >
      <span className={`nav-label`}>{label}</span>
    </button>
  );
};

export default NavItem;
