import React, { useRef, useState, useEffect, memo } from "react";
import { PlusCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import "./style.scss";

interface MultipleUploadProps {
  initialValue: any;
  onChange: (value: any) => void;
  multiple: boolean;
  isDownload?: boolean;
  disabled?: boolean;
  truncateText?: number;
  injectCss?: any;
}
function MultipleUpload({
  initialValue,
  onChange,
  multiple = true,
  isDownload = false,
  disabled = false,
  truncateText = 5,
  injectCss,
}: MultipleUploadProps) {
  const uploadRef = useRef<any>(null);
  const [selectedFiles, setSelectedFiles] = useState<any>(initialValue || []);

  useEffect(() => {
    setSelectedFiles(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (selectedFiles.length >= 5) {
      setSelectedFiles(selectedFiles.slice(0, 5));
    }
  }, [selectedFiles.length]);

  const onUploadFile = (files: any) => {
    setSelectedFiles(selectedFiles && [...selectedFiles, ...Array.from(files)]);
    onChange(selectedFiles && [...selectedFiles, ...Array.from(files)]);
  };
  const fileUploader = document.getElementById("fileUploader") as any;

  const handleFileRemove = (file: any, index: number) => {
    const afterFileRemoved = selectedFiles.filter(
      (item: any, curIndex: number) => curIndex !== index
    );

    if (afterFileRemoved?.length === 0) {
      fileUploader.value = "";
    }
    setSelectedFiles(afterFileRemoved);
    onChange(afterFileRemoved);
  };

  const handleFileDownloadAll = () => {
    selectedFiles.map((item: any) => {
      handleFileDownload(item);
    });
  };

  function handleFileDownload(file: any) {
    const blob =
      file instanceof FileList
        ? file[0].slice(0, file[0].size, file[0].type)
        : file.slice(0, file.size, file.type);
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = file.name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
    document.body.removeChild(downloadLink);
  }
  console.log('selectedFiles', selectedFiles);


  return (
    <div className="m-upload-wrapper" style={injectCss}>
      <div className="m-upload-content">
        <div className="m-upload-content-file">
          {selectedFiles?.map((file: any, index: number) => {
            const maxFileNameLength = truncateText;
            const fileName = file.name;
            const extension = fileName.split(".").pop()?.toLowerCase();
            const truncatedName =
              fileName.substring(0, maxFileNameLength) + "..." + extension;

            return (
              <div className="file-item">
                <p className="file-item-name">{truncatedName}</p>{" "}
                {isDownload ? (
                  <DownloadOutlined
                    className="icon-download"
                    onClick={() => handleFileDownload(file)}
                  />
                ) : (
                  <img
                    src={"/assets/images/iconRemove.svg"}
                    className="file-item-icon--remove"
                    onClick={() => !disabled && handleFileRemove(file, index)}
                  />
                )}
              </div>
            );
          })}
        </div>
        <input
          id="fileUploader"
          multiple={multiple}
          ref={uploadRef}
          style={{ display: "none" }}
          type="file"
          disabled={(!multiple && selectedFiles?.length === 1) || disabled}
          onChange={(e) => {
            onUploadFile(e.target.files);
          }}
        />
        {/* max 5files */}
        {(!disabled && selectedFiles.length < 5) && (
          <div
            className={`m-upload-btn ${!selectedFiles || selectedFiles?.length === 0
              ? "btn-upload--center"
              : "btn-upload--right"
              }`}
            onClick={() => uploadRef.current.click()}
          >
            파일 첨부하기 <PlusCircleOutlined className="icon-plus" />
          </div>
        )}
        {disabled && (
          <div
            className={` m-upload-btn m-upload-btn--downloadAll ${!selectedFiles || selectedFiles?.length === 0
              ? "btn-upload--center"
              : "btn-upload--right"
              }`}
            onClick={handleFileDownloadAll}
          >
            한번에 내려받기
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(MultipleUpload);
