/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const authApi = createApi({
  reducerPath: "authApis",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["GetListBusiness"],
  endpoints: (builder) => ({
    verifyPassword: builder.mutation({
      query: (data) => ({
        url: `users/${data?.userID}/verifyPassword`,
        method: "POST",
        body: data?.body,
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: `auth/login`,
        method: "POST",
        body: data,
      }),
    }),
    passwordChange: builder.mutation({
      query: (data) => ({
        url: `users/chagne-password`,
        method: "POST",
        body: data,
      }),
    }),
    getCurrenUserInfo: builder.query({
      query: () => ({
        url: `/users`,
        method: "GET",
      }),
    }),
    getListBusiness: builder.query({
      query: (data) => ({
        url: `admins/business`,
        method: "GET",
        params: data,
      }),
      providesTags: ["GetListBusiness"],
    }),
    updateStatusBusiness: builder.mutation({
      query: (data) => ({
        url: `admins/business/status/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["GetListBusiness"],
    }),
  }),
});

export const {
  useVerifyPasswordMutation,
  useLoginMutation,
  usePasswordChangeMutation,
  useGetListBusinessQuery,
  useUpdateStatusBusinessMutation,
  useGetCurrenUserInfoQuery,
} = authApi;
