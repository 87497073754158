import React, { useEffect, useState } from "react";
import KitTabCustom from "../../../../components/kitTabCustom";
import { TabsProps } from "antd/lib/tabs";
import { ERDetailTab } from "../../@type";
import AppReceived from "../AppReceived";
import CvPass from "../CvPass";
import InterviewPass from "../InterviewPass";
import InterviewFailed from "../InterviewFailed";

interface IRCNotifyProps {
  onCallback: (value: number) => void;
}

function RCNotify({ onCallback }: IRCNotifyProps) {
  const [t1, setT1] = useState(0);
  const [t2, setT2] = useState(0);
  const [t3, setT3] = useState(0);
  const [t4, setT4] = useState(0);
  const [activeKey, setActiveKey] = useState(ERDetailTab.APPLICATION_RECEIVED);

  useEffect(() => {
    onCallback(
      activeKey === ERDetailTab.APPLICATION_RECEIVED
        ? t1
        : activeKey === ERDetailTab.CV_PASS
        ? t2
        : activeKey === ERDetailTab.INTERVIEW_PASS
        ? t3
        : activeKey === ERDetailTab.FAILED
        ? t4
        : t1
    );
  }, [activeKey, t1, t2, t3, t4]);

  const items: TabsProps["items"] = [
    {
      key: ERDetailTab.APPLICATION_RECEIVED,
      label: (
        <div className="tab-title">
          {ERDetailTab.APPLICATION_RECEIVED}{" "}
          <span className="tab-total">{t1}</span>{" "}
        </div>
      ),
      forceRender: true,
      children: <AppReceived onCallback={(v) => setT1(v)} />,
    },
    {
      key: ERDetailTab.CV_PASS,
      label: (
        <div className="tab-title">
          {ERDetailTab.CV_PASS} <span className="tab-total">{t2}</span>{" "}
        </div>
      ),
      forceRender: true,
      children: <CvPass onCallback={(v) => setT2(v)} />,
    },
    {
      key: ERDetailTab.INTERVIEW_PASS,
      label: (
        <div className="tab-title">
          {ERDetailTab.INTERVIEW_PASS} <span className="tab-total">{t3}</span>{" "}
        </div>
      ),
      forceRender: true,
      children: <InterviewPass onCallback={(v) => setT3(v)} />,
    },
    {
      key: ERDetailTab.FAILED,
      label: (
        <div className="tab-title">
          {ERDetailTab.FAILED} <span className="tab-total">{t4}</span>{" "}
        </div>
      ),
      forceRender: true,
      children: <InterviewFailed onCallback={(v) => setT4(v)} />,
    },
  ];
  return (
    <div className="rcn">
      <KitTabCustom
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        items={items}
        onChange={(tab) => console.log(tab)}
      />
    </div>
  );
}

export default RCNotify;
