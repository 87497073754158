/* eslint-disable no-restricted-globals */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_LINK } from "../../utils/constants";

export const applyWorkServiceApi = createApi({
  reducerPath: "applyWorkService",

  baseQuery: fetchBaseQuery({
    baseUrl: API_LINK,
    prepareHeaders: (headers, { getState }) => {
      // Get token from store (userSlice)
      // @ts-ignore
      const token = getState().auth?.accessToken;

      // Add token to headers
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["GetApplyWorkService"],
  endpoints: (builder) => ({
    createApplyWorkService: builder.mutation({
      query: (data) => ({
        url: `service-apply-jobs`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["GetApplyWorkService"],
    }),
    updateApplyWorkService: builder.mutation({
      query: (data) => {
        return {
          url: `service-apply-jobs/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["GetApplyWorkService"],
    }),
    deleteApplyWorkService: builder.mutation({
      query: (data) => ({
        url: `service-apply-jobs/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetApplyWorkService"],
    }),
    getApplyWorkService: builder.query({
      query: (data) => ({
        url: `service-apply-jobs/search`,
        method: "POST",
        body: data,
      }),
      providesTags: ["GetApplyWorkService"],
    }),
    getApplyWorkServiceDetail: builder.query({
      query: (data) => ({
        url: `service-apply-jobs/${data.id}`,
        method: "GET",
      }),
      providesTags: ["GetApplyWorkService"],
    }),
  }),
});

export const {
  useGetApplyWorkServiceQuery,
  useUpdateApplyWorkServiceMutation,
  useDeleteApplyWorkServiceMutation,
  useGetApplyWorkServiceDetailQuery,
  useCreateApplyWorkServiceMutation,
} = applyWorkServiceApi;
