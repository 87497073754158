import User from "../pages/User";
import Login from "../pages/Login";
import Main from "../pages/Main";
import UserRegister from "../pages/Login/Register";
import ApplyWorkService from "../pages/ApplyWorkService";
import MemberManagement from "../pages/MemberManagement";
import ApplyWorkServiceAction from "../pages/ApplyWorkService/components/ApplyWorkServiceAction";
import MemberManagementDetail from "../pages/MemberManagement/components/MemberManagementDetail";
import MemberApproved from "../pages/MemberApproved";
import RelatedBusinessAction from "../pages/RelatedBusiness/components/RelatedBusinessAction";
import FoundationBusiness from "../pages/FoundationBusiness";
import FoundationBusinessAction from "../pages/FoundationBusiness/components/FoundationBusinessAction";
import TrainingRegister from "../pages/TrainingRegister";
import TrainingRegisterAction from "../pages/TrainingRegister/components/TrainingRegisterAction";
import RelatedBusiness from "../pages/RelatedBusiness";
import ResidentCompany from "../pages/ResidentCompany";
import DetailResidentCompany from "../pages/ResidentCompany/detail";
import ActionResidentCompany from "../pages/ResidentCompany/action";
import IncumbentEducation from "../pages/IncumbentEducation";
import IncumbentEducationAction from "../pages/IncumbentEducation/components/IncumbentEducationAction";
import TalentAgencyInfo from "../pages/TalentAgencyInfo";
import TalentAgencyInfoDetail from "../pages/TalentAgencyInfo/components/TalentAgencyInfoDetail";
import TalentAgencyInfoUpdate from "../pages/TalentAgencyInfo/components/TalentAgencyInfoUpdate";
import RecruitmentInfo from "../pages/RecruitmentInformation";
import RecruitmentInfoDetail from "../pages/RecruitmentInformation/components/RecruitmentInformationAction/RecruitmentInfoDetail";
import Announcement from "../pages/Announcement";
import AnnouncementAction from "../pages/Announcement/components/AnnoucementAction";
import EventAction from "../pages/Event/components/AnnoucementAction";
import Event from "../pages/Event";
import FAQ from "../pages/FAQ";
import FAQAction from "../pages/FAQ/components/FAQAction";
import ChangePassword from "../pages/Login/ChangePassword";
import ResidentCompanyUpdate from "../pages/ResidentCompany/ResidentCompanyUpdate";
import ResidentCompanyDetail from "../pages/ResidentCompany/ResidentCompanyDetail";
import { IDMCType } from "../pages/Main/@type";
import NoticeMain from "../pages/Main/Announcement";
import NoticeAction from "../pages/Main/Announcement/components/AnnoucementAction";
import TalentInfo from "../pages/TalentInfo";
import TalentInfoDetail from "../pages/TalentInfo/components/TalentInfoDetail";
import CVDetail from "../pages/TalentInfo/components/CV";
export const PrivateRoutes = [
  {
    path: "/",
    name: "Home",
    children: [
      {
        path: "/main",
        // element: <Main dmcType={IDMCType.MATCHING_DAY} />,
        children: [
          {
            path: "",
            element: <Main dmcType={IDMCType.MATCHING_DAY} />,
          },
          {
            path: "announcement",
            element: <NoticeMain dmcType={IDMCType.MATCHING_DAY} />,
          },
          {
            path: "announcement/action",
            element: <NoticeAction />,
          },
        ],
      },
      {
        path: "/dmc500-camp",
        // element: <Main dmcType={IDMCType.V2} />,
        children: [
          {
            path: "",
            element: <Main dmcType={IDMCType.V2} />,
          },
          {
            path: "announcement",
            element: <NoticeMain dmcType={IDMCType.V2} />,
          },
          {
            path: "announcement/action",
            element: <NoticeAction />,
          },
        ],
      },
    ],
  },
  {
    name: "Member",
    path: "/training-working-person",
    element: <MemberManagement />,
  },
  {
    name: "Service",
    path: "/apply-work-service",
    children: [
      { path: "", element: <ApplyWorkService /> },
      {
        path: "create",
        element: <ApplyWorkServiceAction type="create" />,
      },
      {
        path: "view",
        element: <ApplyWorkServiceAction type="view" />,
      },
      {
        path: "update",
        element: <ApplyWorkServiceAction type="update" />,
      },
    ],
  },
  {
    name: "Foundation Bussiness",
    path: "/foundation-business",
    children: [
      { path: "", element: <FoundationBusiness /> },
      {
        path: "create",
        element: <FoundationBusinessAction type="create" />,
      },
      {
        path: "update",
        element: <FoundationBusinessAction type="update" />,
      },
      {
        path: "view",
        element: <FoundationBusinessAction type="view" />,
      },
    ],
  },
  {
    name: "Related Bussiness",
    path: "/related-business",
    children: [
      { path: "", element: <RelatedBusiness /> },
      {
        path: "create",
        element: <RelatedBusinessAction type="create" />,
      },
      {
        path: "update",
        element: <RelatedBusinessAction type="update" />,
      },
      {
        path: "view",
        element: <RelatedBusinessAction type="view" />,
      },
    ],
  },
  {
    name: "Related Bussiness",
    path: "/training-register",
    children: [
      { path: "", element: <TrainingRegister /> },
      {
        path: "create",
        element: <TrainingRegisterAction type="create" />,
      },
      {
        path: "update",
        element: <TrainingRegisterAction type="update" />,
      },
      {
        path: "view",
        element: <TrainingRegisterAction type="view" />,
      },
    ],
  },
  {
    name: "Incumbent Education",
    path: "/incumbent-education",
    children: [
      { path: "", element: <IncumbentEducation /> },
      {
        path: "create",
        element: <IncumbentEducationAction type="create" />,
      },
      {
        path: "update",
        element: <IncumbentEducationAction type="update" />,
      },
      {
        path: "view",
        element: <IncumbentEducationAction type="view" />,
      },
    ],
  },
  {
    name: "Talent Agency Information",
    path: "/talent-agency-information",
    children: [
      { path: "", element: <TalentAgencyInfo /> },
      {
        path: "detail",
        element: <TalentAgencyInfoDetail />,
      },
      {
        path: "update",
        element: <TalentAgencyInfoUpdate />,
      },
    ],
  },
  {
    name: "Member Management",
    path: "/member-management",
    children: [
      { path: "", element: <MemberManagement /> },
      {
        path: "view",
        element: <MemberManagementDetail />,
      },
    ],
  },
  {
    name: "Recruitment Information",
    path: "/recruitment-information",
    children: [
      { path: "", element: <RecruitmentInfo /> },
      {
        path: "view",
        element: <RecruitmentInfoDetail />,
      },
    ],
  },
  {
    name: "Talent Information",
    path: "/talent-information",
    children: [
      { path: "", element: <TalentInfo /> },
      {
        path: "view",
        element: <TalentInfoDetail />,
      },
    ],
  },
  {
    name: "Member Approved",
    path: "/member-approved",
    children: [
      { path: "", element: <MemberApproved /> },
      {
        path: "view",
        element: <MemberManagementDetail />,
      },
    ],
  },
  {
    name: "FAQ",
    path: "/faq",
    children: [
      { path: "", element: <FAQ /> },
      {
        path: "action",
        element: <FAQAction />,
      },
    ],
  },
  {
    name: "Announcement",
    path: "/announcement",
    children: [
      { path: "", element: <Announcement /> },
      {
        path: "action",
        element: <AnnouncementAction />,
      },
    ],
  },
  {
    name: "Event",
    path: "/event",
    children: [
      { path: "", element: <Event /> },
      {
        path: "action",
        element: <EventAction />,
      },
    ],
  },
  {
    name: "Resident Company",
    path: "/resident-company",
    children: [
      { path: "", element: <ResidentCompany /> },
      {
        path: "view",
        element: <ResidentCompanyDetail />,
      },
      {
        path: "update",
        element: <ResidentCompanyUpdate type="update" />,
      },
      {
        path: "create",
        element: <ResidentCompanyUpdate type="create" />,
      },
    ],
  },
];

export const PublicRoutes = [
  {
    path: "register",
    element: <UserRegister />,
  },
  {
    path: "",
    element: <Login />,
  },
];
