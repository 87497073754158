import React, { CSSProperties, useEffect, useState } from "react";
import "./style.scss";
import { useGetFileMutateMutation } from "../imageUpload/fileUploadApi";

interface DetailBlockProps {
  title: string;
  content: { title: string; desc: string; type?: string }[];
  className?: CSSProperties;
  userStatus?: "ACTIVE" | "REMOVED";
  onUserStatusChange?: () => void;
}

function DetailBlock({
  title,
  content,
  className,
  onUserStatusChange,
  userStatus,
}: DetailBlockProps) {
  const [getFileMutate] = useGetFileMutateMutation();
  const handleDownloadFile = async (data: any) => {
    try {
      await Promise.all(
        data.map(async (file: any) => {
          const res = await getFileMutate({ id: file.id });
          const binaryDataBuffer = new Uint8Array(res.data.data.Body.data);
          const formatedFile = new File([binaryDataBuffer], file.originalName, {
            type: res.data.data.ContentType,
          });
          const blob =
            formatedFile instanceof FileList
              ? formatedFile[0].slice(
                  0,
                  formatedFile[0].size,
                  formatedFile[0].type
                )
              : formatedFile.slice(0, formatedFile.size, formatedFile.type);
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = formatedFile.name;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          URL.revokeObjectURL(downloadLink.href);
          document.body.removeChild(downloadLink);
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="detail-block" style={className}>
      <div className="title-wrapper">
        <p className="title">{title}</p>
        {userStatus && userStatus === "ACTIVE" && (
          <p onClick={onUserStatusChange} className="btn--user-status">
            회원정지
          </p>
        )}
        {userStatus && userStatus === "REMOVED" && (
          <p onClick={onUserStatusChange} className="btn--user-status">
            회원정지 해제
          </p>
        )}
      </div>

      <hr className="separate" />
      <div className="content">
        {content.map((item: any) => {
          return (
            <div className="content-item" key={item.title}>
              <div className="content-item-text">{item.title}</div>
              <div
                className={`content-item-text ${
                  (item.type === "link" || item.type === "link-href") &&
                  "content-item-text-link"
                }`}
              >
                {item.type === "link" ? (
                  item.desc
                ) : item.type === "link-href" ? (
                  <a href={item.desc} target="_blank">
                    {item.desc}
                  </a>
                ) : item.type === "downloadFile" ? (
                  item.desc?.map((file: any) => (
                    <span onClick={() => handleDownloadFile(item.desc)}>
                      {file.originalName}
                    </span>
                  ))
                ) : (
                  item.desc
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DetailBlock;
