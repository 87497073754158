import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useUpdateStatusBusinessMutation } from "../../../../pages/Login/authApi";
import "./style.scss";
import { useUpdateStatusInnovMutation } from "../../../../pages/Main/innovationV3PlatformApi";
const defaultCom = {
  id: 0,
  createdAt: "",
  updatedAt: "",
  deletedAt: null,
  status: "",
  businessName: null,
  registrationNumber: "",
  representativeName: null,
  labourNumber: null,
  zipcode: null,
  address: null,
  detailAddress: null,
  revenue2022: null,
  phoneNumber: null,
  homepage: null,
  username: null,
  userPosition: null,
  userPhoneNumber: null,
  userEmail: null,
  firstCareer: null,
  secondCareer: null,
  companyNumber: null,
  classificationCodeTen: null,
  classificationCodeEleven: null,
  mainProduct: null,
  creditLevel: null,
  revenue2021: null,
  totalAssets2021: null,
  totalCapital2021: null,
  totalDebt2021: null,
  netProfit2021: null,
  totalAssets2022: null,
  totalCapital2022: null,
  totalDebt2022: null,
  netProfit2022: null,
  foundationDate: null,
  standardDate: null,
  userId: null,
  internships: [],
  files: [],
};

const downloadEmployeeData = (url: any, fileName: string) => {
  fetch(url, { mode: "no-cors" }).then((response) => {
    response.blob().then((blob) => {
      console.log("file res", blob);
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
    });
  });
};

export default function ShowCompany(props: any) {
  const { data, actionCancel, actionConfirm } = props;
  console.log("company info", data);
  const [updateStatusInnov, { isLoading, data: dataUpdated }] =
    useUpdateStatusInnovMutation();

  const [localCom, setlocalCom] = useState(defaultCom);

  const getInternship = (internArray: any) => {
    let intern = "";
    if (internArray) {
      for (let index = 0; index < internArray.length; index++) {
        const element = internArray[index];
        intern = intern + element.name;
        if (index < internArray.length - 1) {
          intern = intern + ",";
        }
      }
    }
    return intern;
  };

  useEffect(() => {
    if (
      (localCom.id === 0 && data?.company?.id > 0) ||
      JSON.stringify(data.company) !== JSON.stringify(localCom)
    ) {
      let files = [...data?.company?.documentFiles];
      setlocalCom({ ...data.company, files: files });
    }

    return () => {};
  }, [data.company]);

  const handleShowInfo = (msg: any) => {
    setTimeout(() => {
      setIsModalOpen(true);
      setmodalType("INFO");
      setmodalContent(msg);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      console.log("reset data");
      setlocalCom(defaultCom);
    };
  }, []);

  const formatFileSize = function (bytes: number) {
    const sufixes = ["B", "kB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState("CHOSEN"); //UNCHOSEN, INFO
  const [modalContent, setmodalContent] = useState("");

  const handleChosen = () => {
    updateStatusInnov({
      id: data?.company?.id,
      status: "CHOSEN",
    })
      .unwrap()
      .then((res) => {
        console.log("chosen", res.data);
        setlocalCom(res?.data);
        setIsModalOpen(false);
        handleShowInfo("선정 완료되었습니다.");
      })
      .catch((error) => {});
  };

  const handleUnChosen = () => {
    updateStatusInnov({
      id: data?.company?.id,
      status: "NOT_CHOSEN",
    })
      .unwrap()
      .then((res) => {
        console.log("unchosen", res.data);
        setlocalCom(res?.data);
        setIsModalOpen(false);
        handleShowInfo("미선정 처리되었습니다.");
      })
      .catch((error) => {});
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="show-com-wrapper">
      <div className="title-wrapper">
        <div className="title">기업정보 상세</div>
        <img
          src="/assets/images/close.svg"
          className="close-ic"
          onClick={actionCancel}
        />
      </div>
      <div className="company-content">
        <div className="block1">
          <div className="title1">신청기업 정보</div>
          <hr className="main-hr" />
          <div className="item">
            <div className="title-item">기업명</div>
            <div className="title-content">{localCom?.businessName}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">사업자 등록번호</div>
            <div className="title-content">{localCom?.registrationNumber}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">대표자명</div>
            <div className="title-content">{localCom?.representativeName}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">상태</div>
            <div className="title-content">
              {localCom?.status === "ACTIVE" ? (
                <div>상태</div>
              ) : localCom?.status === "CHOSEN" ? (
                <div
                  style={{
                    color: "#4584FF",
                  }}
                >
                  선정
                </div>
              ) : (
                <div
                  style={{
                    color: "#BF0403",
                  }}
                >
                  미선정
                </div>
              )}
            </div>
          </div>
          <hr className="item-hr" />
        </div>

        <div className="block2">
          <div className="title1">담당자 정보</div>
          <hr className="main-hr" />
          <div className="item">
            <div className="title-item">담당자 성명</div>
            <div className="title-content">{localCom?.username}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">부서/직책(직급)</div>
            <div className="title-content">{localCom?.userPosition}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">담당자 연락처</div>
            <div className="title-content">{localCom?.userPhoneNumber}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">담당자 이메일</div>
            <div className="title-content">{localCom?.userEmail}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">회사 직통번호</div>
            <div className="title-content">{localCom?.phoneNumber}</div>
          </div>
          <hr className="item-hr" />
        </div>

        <div className="block3">
          <div className="title1">추가 정보</div>
          <hr className="main-hr" />
          <div className="item">
            <div className="title-item">설립일</div>
            <div className="title-content">{localCom?.foundationDate}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">우편번호</div>
            <div className="title-content">{localCom?.zipcode}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">사업장 주소</div>
            <div className="title-content">{localCom?.address}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">상세 주소</div>
            <div className="title-content">{localCom?.detailAddress}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">근로자수</div>
            <div className="title-content">{localCom?.labourNumber}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">2022년도 매출액(억원)</div>
            <div className="title-content">{localCom?.revenue2022}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">홈페이지</div>
            <div className="title-content">{localCom?.homepage}</div>
          </div>
          <hr className="item-hr" />

          <div className="item">
            <div className="title-item">1차 업종</div>
            <div className="title-content">{localCom?.firstCareer}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">2차 업종</div>
            <div className="title-content">{localCom?.secondCareer}</div>
          </div>
          <hr className="item-hr" />
          <div className="item">
            <div className="title-item">직무</div>
            <div className="title-content">
              {getInternship(localCom?.internships)}
            </div>
          </div>
          <hr className="item-hr" />
        </div>

        <div className="block4">
          <div className="title1">제출 서류</div>
          <hr className="main-hr" />
          {localCom?.files?.map((file: any) => {
            return (
              <div className="file-item">
                <div className="g1">
                  {file?.url?.split("test/")[1].includes(".pdf") && (
                    <img
                      src={"/assets/images/pdf-ic.svg"}
                      className="icon-img"
                    />
                  )}
                  {file?.url?.split("test/")[1].includes(".doc") && (
                    <img src={"/assets/images/docx.svg"} className="icon-img" />
                  )}
                  {file?.url?.split("test/")[1].includes(".hwp") && (
                    <img src={"/assets/images/hwpw.svg"} className="icon-img" />
                  )}
                  <a
                    className="file-name"
                    download
                    target={"_blank"}
                    href={file?.url}
                  >
                    {file?.originalName}
                  </a>
                </div>
                <div className="file-size">{formatFileSize(file?.size)}</div>
              </div>
            );
          })}{" "}
          {localCom?.files?.length > 0 && (
            <Button
              className="bt-action1"
              onClick={() => {
                for (let index = 0; index < localCom?.files.length; index++) {
                  const element = localCom?.files[index];
                  //@ts-ignore
                  window.open(element?.url, "_blank").focus();
                }
              }}
            >
              전체 다운로드
            </Button>
          )}
          <div className="footer-bt">
            {localCom?.status === "ACTIVE" && (
              <>
                <Button
                  className="bt-action2"
                  onClick={() => {
                    setIsModalOpen(true);
                    setmodalType("CHOSEN");
                    setmodalContent("해당기업을 선정 하시겠습니까?");
                  }}
                >
                  선정
                </Button>
                <Button
                  className="bt-action3"
                  onClick={() => {
                    setIsModalOpen(true);
                    setmodalType("UNCHOSEN");
                    setmodalContent("해당기업을 미선정 하시겠습니까?");
                  }}
                >
                  미선정
                </Button>
              </>
            )}
            {localCom?.status === "CHOSEN" && (
              <>
                <Button
                  className="bt-action-warn"
                  onClick={() => {
                    setIsModalOpen(true);
                    setmodalType("UNCHOSEN");
                    setmodalContent("해당기업을 미선정 하시겠습니까?");
                  }}
                >
                  선정취소
                </Button>
              </>
            )}
            {localCom?.status === "NOT_CHOSEN" && (
              <>
                <Button
                  className="bt-action-warn"
                  onClick={() => {
                    setIsModalOpen(true);
                    setmodalType("CHOSEN");
                    setmodalContent("해당기업을 선정 하시겠습니까?");
                  }}
                >
                  미선정취소
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="submodal"
        centered
      >
        <div className="modal-wrapper">
          <div className="submodal-title">{modalContent}</div>
          <div className="submodal-footer">
            {modalType === "CHOSEN" && (
              <>
                <Button className="bt-cancel" onClick={handleCancel}>
                  취소
                </Button>
                <Button
                  className="bt-confirm"
                  loading={isLoading}
                  onClick={handleChosen}
                >
                  확인
                </Button>
              </>
            )}
            {modalType === "UNCHOSEN" && (
              <>
                <Button className="bt-cancel" onClick={handleCancel}>
                  취소
                </Button>
                <Button
                  className="bt-confirm"
                  loading={isLoading}
                  onClick={handleUnChosen}
                >
                  확인
                </Button>
              </>
            )}
            {modalType === "INFO" && (
              <>
                <Button className="bt-info" onClick={handleCancel}>
                  취소
                </Button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
