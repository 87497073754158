import { Button, Form, Input } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  useGetFileMutateMutation,
  useGetFileQuery,
  useUploadFileMutation,
} from "../../../../components/imageUpload/fileUploadApi";
import AvatarUpload from "../../../../components/imageUpload";
import { RelatedBusinessForm } from "../../@type";
import "./style.scss";
import {
  useCreateFundServiceMutation,
  useDeleteFundServiceMutation,
  useGetFundServiceDetailQuery,
  useUpdateFundServiceMutation,
} from "../../../FoundationBusiness/fundServiceApi";
import { API_LINK, KEY_EDITOR, TOKEN } from "../../../../utils/constants";
import { formatDateTime } from "../../../../utils/formatting";
import KitDatepicker from "../../../../components/datePicker";
import MultipleUpload from "../../../../components/multipleUpload";
import { generateCustomLable } from "../../../../utils/commonBlock";
import { openModal } from "../../../../components/modal/modalSlice";
import { useDispatch } from "react-redux";
import { kitToastMessage } from "../../../../components/toastMessage";

interface RelatedBusinessActionProps {
  type: "create" | "update" | "view";
}

function RelatedBusinessAction({ type }: RelatedBusinessActionProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const editorRef: any = useRef(null);
  const [form] = Form.useForm();
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [fileUrl, setFileUrl] = useState<any>([]);
  const [filesArr, setFilesArr] = useState<any>([]);
  const initialValues: Partial<RelatedBusinessForm> = {
    target: "",
    method: "",
    title: "",
    time: "",
    registrationDate: "",
    qna: "",
    content: "",
  };

  const isViewPage = type === "view";
  const isUpdatePage = type === "update";

  const [getFileMutate] = useGetFileMutateMutation();

  const { data, isLoading, isSuccess } = useGetFundServiceDetailQuery(
    {
      id: searchParams.get("id"),
    },
    {
      skip: type === "create",
    }
  );
  const files = useMemo(() => {
    if (isSuccess) {
      return {
        thumbnailId: data.data.thumbnailId,
        attachFileId: data.data.files[data.data.files.length - 1]?.id,
      };
    }
  }, [isSuccess, data]);

  const { data: thumbnailData, isSuccess: isUploadThumnailSuccess } =
    useGetFileQuery(
      {
        id: files?.thumbnailId,
      },
      {
        skip: type === "create" || !files?.thumbnailId,
      }
    );
  const { data: fileData, isSuccess: isUploadFileSuccess } = useGetFileQuery(
    {
      id: files?.attachFileId,
    },
    {
      skip: type === "create" || !files?.attachFileId,
    }
  );

  const handleExistFilesList = async (data: any) => {
    try {
      let arr: any = [];
      await Promise.all(
        data.map(async (file: any) => {
          const res = await getFileMutate({ id: file.id });
          const binaryDataBuffer = new Uint8Array(res.data.data.Body.data);
          const formatedFile = new File([binaryDataBuffer], file.originalName, {
            type: res.data.data.ContentType,
          });
          arr.push(formatedFile);
        })
      );
      setFilesArr(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const { updatedAt, id, deletedAt, createdAt, registrationDate, ...rest } =
        data.data;
      console.log(data.data);

      form.setFieldsValue({
        ...rest,
        registrationDate: formatDateTime(registrationDate),
      });
      if (isViewPage || isUpdatePage) {
        handleExistFilesList(data.data.files);
      }
    }
  }, [data?.data, isSuccess]);

  const [createFundService] = useCreateFundServiceMutation();
  const [uploadFile] = useUploadFileMutation();
  const [deleteFile] = useUploadFileMutation();
  const [isFormClicked, setIsFormClicked] = useState(false);
  const [updateFundService] = useUpdateFundServiceMutation();
  const [deleteFundService] = useDeleteFundServiceMutation();

  const handleUploadThumbnail = async () => {
    try {
      const formData = new FormData();
      formData.append("files", thumbnailUrl);
      const data = await uploadFile(formData).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadFile = async () => {
    try {
      const formData = new FormData();
      fileUrl?.map((file: any) => {
        formData.append("files", file);
      });
      const data = await uploadFile(formData).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateFundService = async (body: any) => {
    try {
      const data = await createFundService(body).unwrap();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values: RelatedBusinessForm | any) => {
    const uploadImageRes = await handleUploadThumbnail();
    const thumbnailId = uploadImageRes?.data?.files[0].id;
    const uploadFileRes = await handleUploadFile();
    const attachFileIdArray = uploadFileRes?.data?.files.map(
      (item: any) => item.id
    );
    if (type === "create") {
      if (thumbnailId) {
        const { thumbnail, file, registrationDate, ...rest } = values;
        const body = {
          ...rest,
          attachFiles: attachFileIdArray || [],
          startRegistrationDate: registrationDate[0] || undefined,
          endRegistrationDate: registrationDate[1] || undefined,
          thumbnailId: thumbnailId || undefined,
          content: editorRef.current.getContent(),
          type: "RELATED",
        };
        const response = await handleCreateFundService(body);

        const code = response?.statusCode;
        if (code === 200 || code === 201) {
          dispatch(
            openModal({
              template: "show-info",
              data: {
                info: "작성완료 되었습니다.",
              },
              actionConfirm: () =>
                navigate({
                  pathname: "/related-business/view",
                  search: createSearchParams({
                    id: response?.data?.id,
                  }).toString(),
                }),
            })
          );
        }
      }
    } else if (type === "update") {
      const { thumbnail, file, registrationDate, ...rest } = values;
      const body = {
        ...rest,
        attachFiles: attachFileIdArray || [],
        thumbnailId: thumbnailId || rest.thumnailId,
        startRegistrationDate: registrationDate[0] || undefined,
        endRegistrationDate: registrationDate[1] || undefined,
        content: editorRef.current.getContent(),
        type: "RELATED",
        id: searchParams.get("id"),
      };
      const response = await updateFundService(body).unwrap();
      const code = response.statusCode;
      if (code === 200 || code === 201) {
        dispatch(
          openModal({
            template: "show-info",
            data: {
              info: "작성완료 되었습니다.",
            },
            actionConfirm: () =>
              navigate({
                pathname: "/related-business/view",
                search: createSearchParams({
                  id: searchParams.get("id") as any,
                }).toString(),
              }),
          })
        );
      }
    }
  };

  const handleImageUpload = (file: any) => {
    setThumbnailUrl(file);
  };

  const handleDeleteFunService = async () => {
    try {
      const data = await deleteFundService({
        id: searchParams.get("id"),
      }).unwrap();
      const code = data.statusCode;
      if (code === 200 || code === 201) {
        kitToastMessage("bottom", "삭제 완료되었습니다.", "success");
        navigate("/related-business");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // functopm to handle call delete api
  const handleImageDelete = async () => {
    try {
      const data = await deleteFile().unwrap();
      const code = data.statusCode;
      if (code === 200 || code === 201) {
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadImageHandler = (file: any, progress: any) =>
    new Promise<string>(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("file", file.blob());
        const res = await fetch(API_LINK + "files", {
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
          },
          body: formData,
        })
          .then((res: any) => res.json())
          .catch((error) => error.json());
        if (res.statusCode > 299) {
          return reject(res);
        }
        return resolve(res?.data?.files[0]?.url);
      } catch (error) {
        return reject("Something went wrong!");
      }
    });

  const handleDatepickerChange = (date: any) => {};

  const handleFilUploadChange = (files: any[]) => {
    setFileUrl(Array.from(files));
  };

  return (
    <div className="foundation-business-action">
      <div className="action-area">
        <div className="header">
          <p className="title">사업참여</p> <b>-</b>
          <p className="sub-title">타 기관 사업</p>
        </div>
        {(type === "create" || isUpdatePage) && (
          <Button
            className="btn-crud--error"
            disabled={!isFormClicked}
            onClick={() => form.submit()}
          >
            작성완료
          </Button>
        )}
        {isViewPage && (
          <>
            <Button
              className="btn-crud--primary"
              onClick={() =>
                navigate({
                  pathname: "/related-business/update",
                  search: createSearchParams({
                    id: searchParams.get("id") as any,
                  }).toString(),
                })
              }
            >
              수정하기
            </Button>
            <Button
              className="btn-crud--error"
              onClick={() =>
                dispatch(
                  openModal({
                    template: "show-confirm",
                    data: {
                      type: "delete",
                      model: "dmc3",
                      confirmText: "정말 삭제 하시겠습니까?",
                    },
                    actionConfirm: handleDeleteFunService,
                  })
                )
              }
            >
              삭제하기
            </Button>
          </>
        )}
      </div>
      <div className="form-container">
        <Form
          name="create"
          className="foundation-business-action__form"
          initialValues={initialValues}
          onFinish={onFinish}
          onValuesChange={(values) => {
            if (Object.keys(values)?.length > 0) {
              setIsFormClicked(true);
            }
          }}
          layout={"vertical"}
          form={form}
        >
          <Form.Item label="제목" name="title" className="title form-input">
            <Input disabled={isViewPage} placeholder="제목을 입력해 주세요." />
          </Form.Item>
          <Form.Item
            label="대상"
            name="target"
            className="title form-input form-item-width--524"
          >
            <Input
              disabled={isViewPage}
              placeholder="참여대상을 입력해 주세요."
            />
          </Form.Item>
          <div className="form-header">
            <Form.Item
              name="thumbnail"
              label="썸네일"
              className="date form-input"
              rules={[
                { required: true, message: "Please input your thumnail!" },
              ]}
            >
              <AvatarUpload
                disabled={isViewPage}
                initialValue={data?.data?.thumbnail.url}
                onChange={handleImageUpload}
              />
            </Form.Item>
            <div className="form-header__input">
              <Form.Item
                label="접수기간"
                name="registrationDate"
                className="title form-input"
              >
                <KitDatepicker
                  disabled={isViewPage}
                  initialValue={
                    data?.data?.startRegistrationDate &&
                    data?.data?.endRegistrationDate && [
                      data?.data?.startRegistrationDate,
                      data?.data?.endRegistrationDate,
                    ]
                  }
                  type="rangePicker"
                  onChange={(date: any) => handleDatepickerChange(date)}
                />
              </Form.Item>
              <Form.Item
                label="접수방법"
                name="method"
                className="title form-input"
              >
                <Input
                  disabled={isViewPage}
                  placeholder="접수방법을 입력해 주세요."
                />
              </Form.Item>
              <Form.Item label="문의" name="qna" className="title form-input">
                <Input
                  disabled={isViewPage}
                  placeholder="문의받으실 곳 정보를 입력해 주세요."
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item
            label={generateCustomLable(
              "첨부파일",
              "(선택) * 최대 5개까지 첨부 가능합니다."
            )}
            name="file"
            className="file"
          >
            <MultipleUpload
              injectCss={{ width: "1128px" }}
              initialValue={filesArr}
              isDownload={isViewPage}
              multiple
              disabled={isViewPage}
              onChange={handleFilUploadChange}
            />
          </Form.Item>
          <Form.Item label="내용" name="content" className="title form-input">
            <Editor
              scriptLoading={{
                async: true,
              }}
              value={form.getFieldValue("content")}
              onInit={(_: any, editor: any) => (editorRef.current = editor)}
              apiKey={KEY_EDITOR}
              onEditorChange={(e) => {
                form.setFieldValue("content", e);
                setIsFormClicked(true);
              }}
              plugins="advlist autolink lists link image charmap preview
                            searchreplace visualblocks code fullscreen 
                               paste code help table"
              ref={editorRef}
              disabled={isViewPage}
              init={{
                height: 500,
                toolbar:
                  " formatselect | undo redo bold italic backcolor forecolor| \
                    alignleft aligncenter alignright alignjustify| \
                    bullist numlist outdent indent removeformat| image video",
                language: "ko_KR",
                images_upload_handler: uploadImageHandler,
                branding: false,
                placeholder: "내용을 입력해 주세요.",
              }}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default RelatedBusinessAction;
